import { useState, useEffect, useCallback, useContext } from "react";
import StepWizardContext from "../../../../context/StepWizardContext";
import { navigate } from "gatsby";

const useHandleMountedForm = (formValidator) => {
  const { currentStep, checkStepsCompleted } = useContext(StepWizardContext);
  const [mountStatus, setMountStatus] = useState("mounting");

  const checkIfCurrentStepHasPermission = useCallback(() => {
    const isValid = checkStepsCompleted(currentStep, formValidator);

    if (currentStep > 0 && !isValid) {
      navigate(window.location.pathname);
    } else {
      setMountStatus("mounted");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, checkStepsCompleted]);

  useEffect(() => {
    if (mountStatus === "mounting") {
      checkIfCurrentStepHasPermission();
    }
  }, [mountStatus, checkIfCurrentStepHasPermission]);

  return {
    mountStatus,
  };
};

export default useHandleMountedForm;
