import { useStaticQuery, graphql } from "gatsby";

const useGetSiteMetadata = () => {
  const {
    site: { siteMetadata: siteDataOriginal },
  } = useQuery();

  return siteDataOriginal;
};

export default useGetSiteMetadata;

const useQuery = () =>
  useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          web_fontFamily
          application_form_svg
          application_form_svg_es
          application_form_bold_svg
          application_form_bold_svg_es
          formType_passport
          formType_passport_es
          formType_passport_es_capitalize
          ds11_svg
          ds82_svg
          ds64_svg
          passport_svg
          passport_svg_es
          application_online_svg_es
          passport_small_svg
          passport_small_svg_es
          benefits_online
          benefits_online_es
          benefits_online_bold
          benefits_online_bold_es
          benefits_online_es
          benefits_form
          benefits_form_es
          benefits_tools
          benefits_tools_es
          covid_goverment
          covid_goverment_es
          covid_passport_application
          covid_passport_application_es
          footer_goverment
          footer_goverment_es
          footer_goverment_small
          footer_goverment_small_es
          footer_online
          footer_online_es
          footer_passport
          footer_passport_es
          footer_form
          footer_form_es
          footer_passport_application
          footer_passport_application_es
          disclaimer_goverment
          disclaimer_goverment_es
          disclaimer_goverment_es_regular
          disclaimer_passport_application_1
          disclaimer_passport_application_1_es
          disclaimer_passport_application_1_es_regular
          disclaimer_passport_application_2
          disclaimer_passport_application_2_es
          disclaimer_passport_application_2_es_regular
          disclaimer_govt
          disclaimer_govt_es
          form_big_bold
          form_big_bold_es
          online_big_bold
          online_big_bold_es
          government_svg
          government_svg_es
        }
      }
    }
  `);
