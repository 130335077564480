import { setFormDataInStorage } from "./formDataInStorage";
import { changeFieldProperties, scrollToRef } from "./form";

export const stepValidator = (stepIndex, formValidator) => {
  const { isValid, invalidField } = formValidator(stepIndex);

  if (invalidField) {
    scrollToRef(invalidField.name);
  }

  return isValid;
};

export const fieldValidator = (fieldName, form) => {
  let formToValidate = { ...form };
  let field = form[fieldName];
  let isValid = true;

  if (typeof field !== "undefined") {
    const fieldValidations = Array.isArray(field.validations) ? field.validations : [];

    fieldValidations.push(
      {
        type: "withoutEmojis",
      },
      { type: "stringLength" }
    );

    for (let index = 0; index < fieldValidations.length; index++) {
      const { type, ...options } = fieldValidations[index];

      const validation = validations[type];

      if (typeof validation === "function") {
        const fieldValidated = validation({ field, form: formToValidate, options });

        if (typeof fieldValidated.field !== "undefined")
          formToValidate[field.name] = fieldValidated.field;
        if (typeof fieldValidated.form !== "undefined") formToValidate = fieldValidated.form;

        if (!fieldValidated.isValid) {
          isValid = fieldValidated.isValid;
          return {
            isValid: fieldValidated.isValid,
            form: formToValidate,
          };
        }
      }
    }

    setFormDataInStorage(formToValidate);
  }

  return { isValid, form: formToValidate };
};

const getIsValidLength = (field) => {
  const minlength = +field.minlength;
  const maxlength = +field.maxlength;
  let isValid = field.value.length > 0;

  if (!isNaN(minlength)) isValid = field.value.length >= minlength;

  if (!isNaN(maxlength)) isValid = isValid && field.value.length <= maxlength;

  return isValid;
};

const validationEmail = ({ field }) => {
  const rgxEmail = /^(?=.{1,33}$)\w+([-+._']\w+)*@\w+([-._]\w+)*\.\w+([-.]\w+)*$/;

  let fieldToValidate = { ...field };
  let isValid = rgxEmail.test(fieldToValidate.value);

  return {
    isValid,
    field: {
      ...fieldToValidate,
      validField: isValid,
      validate: isValid,
    },
  };
};

export const validationStringLength = ({ field }) => {
  if (!field.required && field.value.length === 0) {
    return {
      isValid: true,
      field: {
        ...field,
        validField: false,
        validate: true,
      },
    };
  }

  let isValidLength = getIsValidLength(field);
  const isSingleDateInputs = field.component === "DateInputs";

  if (isSingleDateInputs) {
    isValidLength = getIsValidDate(field);
  }

  return {
    isValid: isValidLength,
    field: {
      ...field,
      validField: isValidLength,
      validate: isValidLength,
    },
  };
};

const getIsValidDate = (field) => {
  const dateValue = field.value;
  let isValid = dateValue === "Invalid Date" ? false : true;

  const minElegibleDate = field.minElegibleDate;
  const maxElegibleDate = field.maxElegibleDate;
  let dateValueToCompare = new Date(dateValue);
  dateValueToCompare.setHours(0, 0, 0, 0);

  let maxDateToCompare = "";
  let minDateToCompare = "";

  if (minElegibleDate !== "") {
    switch (minElegibleDate) {
      case "today":
        minDateToCompare = new Date();
        break;
      default:
        minDateToCompare = new Date(minElegibleDate);
    }

    minDateToCompare.setHours(0, 0, 0, 0);
    isValid = dateValueToCompare >= minDateToCompare;
  }

  if (maxElegibleDate !== "") {
    switch (maxElegibleDate) {
      case "today":
        maxDateToCompare = new Date();
        break;
      default:
        maxDateToCompare = new Date(maxElegibleDate);
    }

    maxDateToCompare.setHours(0, 0, 0, 0);
    isValid = dateValueToCompare <= maxDateToCompare;
  }

  return isValid;
};

const validationEmojis = ({ field }) => {
  const regexEmoji =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/;

  if (typeof field.value === "string") {
    const isValid = !regexEmoji.test(field.value);

    return {
      isValid,
      field: {
        ...field,
        validField: isValid,
        validate: isValid,
      },
    };
  } else {
    return {
      isValid: true,
      field,
    };
  }
};

const validationEqualTo = ({ field, form, options }) => {
  const isValid = field.value === form[options.field].value;
  const fields = [field.name];

  const properties = {
    validField: isValid,
    validate: isValid,
    errorMessageExtraFirst: "",
  };

  let newForm = {
    ...form,
    ...changeFieldProperties(fields, properties, form),
  };

  return {
    isValid,
    form: newForm,
  };
};

const validationValidateOtherField = ({ form, options }) => {
  if (form[options.field].value.length > 0) {
    const { isValid, form: newForm } = fieldValidator(options.field, form);

    return { isValid, form: newForm };
  } else {
    return {
      isValid: true,
      form,
    };
  }
};

const validationSsn = ({ field }) => {
  const isValid = field.value.length === 11;

  return {
    isValid,
    field: {
      ...field,
      validField: isValid,
      validate: isValid,
    },
  };
};

export const validations = {
  email: validationEmail,
  stringLength: validationStringLength,
  withoutEmojis: validationEmojis,
  equalTo: validationEqualTo,
  validateOtherField: validationValidateOtherField,
  ssn: validationSsn,
};
