export const cardsPassportData = (template, language) => {
  const cardsEn = [
    // DS11 English
    {
      id: "6bc785f3-7b63-506f-aa47-45191ac91a44",
      show: true,
      name: "DS-11",
      headerTitle: "New Passport <span style='white-space: nowrap;'>DS-11</span>",
      formButtonText: "Complete DS-11 online",
      slug: "new-passport",
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-3",
                content: [
                  {
                    nodeType: "text",
                    value: "Start now if the following applies: ",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "unordered-list",
                content: [
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "You are applying OR renewing a passport for someone under 16",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "It is your first U.S. passport.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "Your previous U.S. passport was issued over 15 years ago.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "If you are over 16 and were aged 15 or under when you were issued your last passport.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
      },
      order: 2,
      orderByTemplate: {
        default: 2,
        template_1: 1,
      },
    },
    // DS82 English
    {
      id: "fbe3d439-9806-5b6f-96d3-3e0d48332492",
      show: true,
      name: "DS-82",
      headerTitle: "Passport Renewal <span style='white-space: nowrap;'>DS-82</span>",
      formButtonText: "Complete DS-82 online",
      slug: "passport-renewal",
      formImage: {
        id: "7ab7d93c-a65a-5a10-be6b-a53944a7605e",
        url: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start a DS-82 now if your previous passport:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Is in your possession and is undamaged.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Was issued when you were aged 16 or older.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Has few blank passport pages remaining.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Will expire soon or has expired in the last 5 years.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Was issued 1+ years ago and you have since legally changed your name.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 1,
      orderByTemplate: {
        default: 1,
        template_1: 2,
      },
    },
    // DS64 English
    {
      id: "fc8e81b7-09c9-5cb0-bcd3-da26d380677c",
      name: "DS-64",
      headerTitle: "Lost or Stolen Passport <span style='white-space: nowrap;'>DS-64</span>",
      formButtonText: "Complete DS-64 online",
      slug: "lost-stolen-passport",
      show: true,
      formImage: {
        id: "e8a3adb2-ccec-51d8-8274-72c3ae9e1b27",
        url: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png?w=95&h=122&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start a DS-64 now if your passport: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Was lost or stolen and you wish to report the loss.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Was lost or stolen and you wish to report the loss AND apply for a new passport.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 3,
      orderByTemplate: {
        default: 3,
        template_1: 3,
      },
    },
    // DS5504 English
    {
      id: "e83c32e8-9e8d-4286-bdd4-c3bf72902000",
      show: true,
      name: "DS-5504",
      headerTitle: "Passport Changes and Corrections DS-5504",
      formButtonText: "Apply now",
      slug: "passport-corrections",
      newLabel: true,
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "Start a DS-5504 if the following applies: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Your passport has a printing error that occured within a year of its issuing date.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Your passport is in good condition.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "You have documentation to support the change or correction you are requesting.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "You have a “Limited Validity Passport”.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 5,
      orderByTemplate: {
        default: 5,
        template_1: 5,
      },
    },
  ];

  const cardsEs = [
    // DS11 Spanish
    {
      id: "7f5b5efa-b18c-5b93-a0a5-abec15ca48e1",
      show: true,
      name: "DS-11",
      headerTitle: "Nuevo pasaporte <span style='white-space: nowrap;'>DS-11</span>",
      formButtonText: "Completar el DS-11 en línea",
      slug: "new-passport",
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-3",
                content: [
                  {
                    nodeType: "text",
                    value: "Empiece ahora si se cumple lo siguiente: ",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "unordered-list",
                content: [
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "Está solicitando o renovando el pasaporte para alguien menor de 16 años",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "Es su primer pasaporte estadounidense.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value: "Su anterior pasaporte estadounidense se emitió hace más de 15 años.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "list-item",
                    content: [
                      {
                        nodeType: "paragraph",
                        content: [
                          {
                            nodeType: "text",
                            value:
                              "Si es mayor de 16 años y tenía 15 o era menor de 15 cuando se emitió su último pasaporte.",
                            marks: [],
                            data: {},
                          },
                        ],
                        data: {},
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
      },
      order: 2,
      orderByTemplate: {
        default: 2,
        template_1: 1,
      },
    },
    // DS82 Spanish
    {
      id: "d42a5440-05d4-5b71-8d50-0bd2bbebb69f",
      show: true,
      name: "DS-82",
      headerTitle: "Renovación de pasaporte <span style='white-space: nowrap;'>DS-82</span>",
      formButtonText: "Completar el DS-82 en línea",
      slug: "passport-renewal",
      formImage: {
        id: "7ab7d93c-a65a-5a10-be6b-a53944a7605e",
        url: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Empiece el DS-82 ahora si su pasaporte anterior:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Está en su poder y no está dañado.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Se emitió cuando tenía 16 años o posteriormente.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Quedan pocas hojas en blanco en el pasaporte.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Vencerá próximamente o ha vencido en los últimos 5 años.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Se emitió hace más de un año y desde entonces ha cambiado legalmente su nombre.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 1,
      orderByTemplate: {
        default: 1,
        template_1: 2,
      },
    },
    // DS64 Spanish
    {
      id: "6c26d744-76e2-573d-8da9-30ad678737c3",
      name: "DS-64",
      headerTitle: "Pasaporte perdido o robado <span style='white-space: nowrap;'>DS-64</span>",
      formButtonText: "Completar el DS-64 en línea",
      slug: "lost-stolen-passport",
      show: true,
      formImage: {
        id: "e8a3adb2-ccec-51d8-8274-72c3ae9e1b27",
        url: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png?w=95&h=122&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Empiece el DS-64 ahora si su pasaporte: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Lo ha perdido o se lo han robado y desea denunciar la pérdida.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Lo ha perdido o se lo han robado y desea denunciar la pérdida Y solicitar un nuevo pasaporte.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 3,
      orderByTemplate: {
        default: 3,
        template_1: 3,
      },
    },
    // DS5504 Español
    {
      id: "e83c32e8-9e8d-4286-bdd4-c3bf72902000",
      show: true,
      name: "DS-5504",
      headerTitle: "Cambios y correcciones de pasaporte DS-5504",
      formButtonText: "Solicitar ahora",
      slug: "passport-corrections",
      newLabel: true,
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requisitos:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "Empiece el DS-5504 si se cumple lo siguiente: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Su pasaporte tiene un error de impresión que se ha producido en un plazo de un año desde su fecha de emisión.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Su pasaporte está en buen estado.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Dispone de documentación que respalde el cambio o la corrección solicitada.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Tiene un «Pasaporte con validez limitada».",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 5,
      orderByTemplate: {
        default: 5,
        template_1: 5,
      },
    },
  ];

  let cards = language === "en-US" ? cardsEn : cardsEs;

  // TODO: Sort array cards by defined attributes
  return cards.sort((item, prevItem) => {
    if (typeof item.orderByTemplate === "object") {
      return item.orderByTemplate[template] - prevItem.orderByTemplate[template];
    } else {
      return item.order - prevItem.order;
    }
  });
};
