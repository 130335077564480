/**
 *
 * @param {Array} passportCards
 * @param {Array} contentfullData
 */
export const buildPassportCardsData = (passportCards, contentfullData) => {
  if (
    passportCards &&
    Array.isArray(passportCards) &&
    contentfullData &&
    Array.isArray(contentfullData)
  ) {
    return passportCards.map((passportCard) =>
      buildPassportCardData(passportCard, contentfullData)
    );
  }

  return passportCards;
};

/**
 *
 * @param {Object} passportCard
 * @param {Array} contentfullData
 */
export const buildPassportCardData = (passportCard, contentfullData) => {
  if (typeof passportCard === "object" && contentfullData && Array.isArray(contentfullData)) {
    const data = contentfullData.find((item) =>
      [item.slug, `/${item.slug}`].includes(passportCard.slug)
    );

    if (typeof data === "object" && data.governmentPdf) {
      return {
        ...passportCard,
        governmentPdf: data.governmentPdf,
      };
    } else {
      return passportCard;
    }
  }

  return passportCard;
};
