export const stepsHowItWorks = [
  {
    icon: "/images/how-it-works/illustration-form.svg",
    title: "Select your form",
    text: "Choose the passport form that best meets your needs!",
  },
  {
    icon: "/images/how-it-works/illustration-online.svg",
    title: "Fill it out online",
    text: "Complete your form using our pro online wizard!",
  },
  {
    icon: "/images/how-it-works/illustration-revision.svg",
    title: "Professional revision",
    text: "Your document will be revised by our experienced experts to ensure maximum optimization.",
  },
  {
    icon: "/images/how-it-works/illustration-print.svg",
    title: "Receive and Print",
    text: "Once you get your expertly reviewed document, you can simply print it and mail it in/deliver it to a passport office depending on your needs.",
  },
];
