import React, { useEffect, useMemo } from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../../components/Layout/layout";
import { graphql } from "gatsby";
import {
  amplitudeTrackEvent,
  amplitudeTrackSession,
  amplitudeTrackUser,
} from "../../components/utils/Amplitude";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Loading from "../../components/atoms/Loading";
import PaymentCertificates from "../../components/Form/PaymentCertificates";
import Nav from "../../components/atoms/Nav";
import Pay from "../../components/Form/Pay";
import Alert from "../../components/atoms/Alert";
import Timer from "../../components/atoms/Timer";
import { SideSummary } from "../../components/organisms/SideSummary";
import { Title } from "../../components/atoms/Title/title";
import useGetApplication from "../../hooks/useGetApplication";
import useMediaQuery from "../../hooks/useMediaQuery";
import { getUrlParams, windowIsExist } from "../../components/utils/index";
import "./styles.scss";

const PaymentPage = () => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useI18next();
  const { session: amplitudeSessionId } = getUrlParams();
  const { lang: applicationLanguage } = getUrlParams();

  const paymentSteps = [t("Form Complete"), t("Review"), t("Payment"), t("Form Received")];
  const isMobile = useMediaQuery("mobile");
  const { application, getApplicationData, loading, iframeLoaderContainer } = useGetApplication({
    redirect: { allow: true, to: "/" },
  });

  const hasDifferentLanguageFromBrowser = !language.includes(applicationLanguage);

  useEffect(() => {
    if (loading === true && hasDifferentLanguageFromBrowser && applicationLanguage) {
      const currentLanguage = applicationLanguage === "en" ? "en-US" : applicationLanguage;
      changeLanguage(currentLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const applicationTitle = useMemo(() => {
    if (application.applicationNumber && application.applicationName) {
      return t(`${application.applicationNumber} - ${application.applicationName} Form`);
    }

    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application.applicationNumber, application.applicationName, language]);

  useEffect(() => {
    getApplicationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (application.applicationName && application.applicationId) {
      amplitudeTrackUser(application.applicationId);
      amplitudeTrackEvent("view_page_payment", {
        form: application.applicationName.toLowerCase(),
      });
      // this is for mantain tracking on redirection
      if (amplitudeSessionId) amplitudeTrackSession(amplitudeSessionId);
    }
  }, [application.applicationName, application.applicationId, amplitudeSessionId]);

  const isMobileShowTest = windowIsExist && window.innerWidth < 790;

  const translatedProps = {
    titleContent: t("Credit/Debit Card Information"),
    alertContent: t(
      "Something isn't right. Please check your card details or try a different card."
    ),
  };

  return (
    <Layout
      hideLanguageSelector={true}
      hideMenuButton={false}
      headerTitle={applicationTitle}
      hideFooterNavigation={true}
      hideFooterNavigationLogo={true}>
      <Wrapper
        collapse
        rowWrap
        buttonsContainer>
        <Nav
          desktopOnly
          totalSteps={paymentSteps.length}
          stepNames={paymentSteps}
          currentStep={paymentSteps.length - 2}
        />
        <div id={`js-iframe-verifyPayment`}></div>
      </Wrapper>
      {!iframeLoaderContainer && (
        <Wrapper className="Payment__grid">
          {loading ? (
            <Loading full />
          ) : (
            <>
              <div className={`Payment__wrapper`}>
                <div className={`Payment__header`}>
                  <Title
                    regular
                    content={t(`Download Your Document After One More Step`)}
                  />
                </div>
                <div className={`Payment__body`}>
                  {!application.emptyGatways && (
                    <Alert
                      type="warning"
                      className="Payment__timer"
                      compact
                      center>
                      <Timer /> {t("Left to Download Your Document")}
                    </Alert>
                  )}
                  <Pay
                    application={application}
                    testAB={isMobileShowTest}
                    {...translatedProps}
                  />
                </div>
              </div>
              <PaymentCertificates />
              <SideSummary
                application={application}
                applicationID={application.applicationName}
                applicationName={application.applicationNumber}
                applicationPrice={application.applicationPrice.amount}
                applicationAddons={application.applicationAddons}
                applicationTotalPrice={application.applicationTotalPrice}
                applicationReference={application.applicationReference}
                showDetails
                payment
                isOpen={isMobile ? false : true}
              />
            </>
          )}
        </Wrapper>
      )}
    </Layout>
  );
};

export default PaymentPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
