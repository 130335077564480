import React from "react";
import PropTypes from "prop-types";
import Button from "../../../atoms/Button";
import Wrapper from "../../../Layout/Wrapper/wrapper";
import { useTranslation } from "gatsby-plugin-react-i18next";

const ActionButtons = ({
  isFormToEdit,
  currentStep,
  totalSteps,
  isEditingSection,
  submitApplication,
  nextStep,
  previousStep,
  loading,
}) => {
  const { t } = useTranslation();
  return(
  <Wrapper
    flex
    classNames="form__buttons">
    {!isFormToEdit ? (
      <>
        {currentStep > 0 && (
          <Wrapper classNames="form__buttons__button">
            <Button
              label={t("Back")}
              dataQa="back-step"
              testId={`back-step-${currentStep}`}
              onClick={previousStep}
              tertiary
              step
            />
          </Wrapper>
        )}

        {currentStep < totalSteps && (
          <Wrapper classNames="form__buttons__button form__buttons__button--rigth">
            <Button
              label={t("Next Step")}
              dataQa="next-step"
              testId={`next-step-${currentStep}`}
              onClick={nextStep}
              primaryAlt
              step
            />
          </Wrapper>
        )}

        {currentStep === totalSteps && (
          <Wrapper
            half
            classNames="form__buttons__button form__buttons__button--rigth">
            <Button
              label={t("Review Application")}
              buttonId="submit-application"
              dataQa="complete-application"
              testId={`complete-application-${currentStep}`}
              onClick={(e) => submitApplication(currentStep)}
              primary
              step
              disabled={loading}
            />
          </Wrapper>
        )}
      </>
    ) : (
      <Wrapper
        half
        classNames="form__buttons__button form__buttons__button--rigth">
        <Button
          label={t("Save changes")}
          dataQa="save-changes"
          buttonId="submit-application"
          testId={`save-changes-${currentStep}`}
          onClick={() => submitApplication(currentStep, (isEditingSection = true))}
          primary
          step
          disabled={loading}
        />
      </Wrapper>
    )}
  </Wrapper>
)};

ActionButtons.propTypes = {
  isFormToEdit: PropTypes.bool,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  submitApplication: PropTypes.func,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  loading: PropTypes.bool,
};

export default ActionButtons;
