import React, { useMemo } from "react";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";
import CardsPassport from "../../components/molecules/CardsPassport";
import { graphql } from "gatsby";
import { cardsPassportData } from "../../data/requirements";
import { buildPassportCardsData } from "../../components/utils/landingPages";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

const Requirements = ({ data }) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  const parsedCardsPassportData = useMemo(() => {
    const cards = cardsPassportData("default", language);
    return buildPassportCardsData(cards, data.allContentfulFormType.nodes);
  }, [data.allContentfulFormType.nodes, language]);

  return (
    <Layout>
      <Wrapper marginTop>
        <Title
          hero
          tag="h1"
          content={t("Requirements for each form")}
        />
        <CardsPassport
          cards={parsedCardsPassportData}
          isLanding={true}
          source="landing-requirements"
        />
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulFormType(filter: { show: { eq: true }, node_locale: {eq: $language} }) {
      nodes {
        slug
      }
    }
  }
`;

export default Requirements;
