/**
 *
 * @param {Number} date1 Date in timestamp
 * @param {Number} data2 Date in timestamp
 * @returns Difference between date1 to data2 in years
 */
export const diffInYears = (date1, data2) =>
  parseInt((date1 - data2) / 1000 / (60 * 60 * 24) / 365.25);

export const getAge = (date) => {
  const birthDate = new Date(date).getTime();
  const now = Date.now();

  if (date === null) return null;

  return diffInYears(now, birthDate);
};

export const formatToUSDate = (date) => {
  const newDate = new Date(date);

  const year = newDate.getUTCFullYear();
  const month = twoDigit(newDate.getUTCMonth() + 1);
  const day = twoDigit(newDate.getUTCDate());

  return `${month}/${day}/${year}`;
};

export const formatToShortDate = (date) => {
  const newDate = new Date(date);

  const year = newDate.getFullYear();
  const month = twoDigit(newDate.getMonth() + 1);
  const day = twoDigit(newDate.getDate());

  return `${month}/${day}/${year}`;
};

const twoDigit = (num) => ("0" + num).slice(-2);

export const addDays = (date, days) => {
  const oldDate = new Date(date);
  const newDate = oldDate.setDate(oldDate.getDate(), days);

  return newDate;
};
