import React from "react";
import "./styles.scss";
import { Title } from "../../atoms/Title/title";

export const MessageBox = (props) => {
  return (
    <div className="messageBox">
      {props.icon && (
        <div className="messageBox__header">
          <div className="messageBox__icon">
            <img
              src={props.icon}
              alt={`${props.title}`}
            />
          </div>
        </div>
      )}
      <div className="messageBox__body">
        <Title
          regular
          content={props.title}
        />
      </div>
      <div className="messageBox__body">{props.content}</div>
    </div>
  );
};
