export const filterDataReview = (field, value) => {
  let filteredValue = "";
  switch (field.type) {
    case "select":
      field.items.forEach((f) => {
        if (f.value === value) {
          filteredValue = f.name;
        }
      });
      break;
    case "radio":
      field.items.forEach((f) => {
        if (f.value === value) {
          filteredValue = f.label;
        }
      });
      break;
    default:
      filteredValue = value;
  }

  return filteredValue;
};
