import React, { useEffect } from "react";
import AlertPopup from "../../../molecules/AlertPopup";
import dataLayer from "../../../utils/dataLayer";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const CLOSE_DATALAYER_KEY = "closePopupNotYourForm";
const REDIRECT_DATALAYER_KEY = "redirectToD11FromPopupNotYourForm";
const SHOWUP_DATALAYER_KEY = "showUpPopupNotYourForm";

const Under16Alert = ({ onClose }) => {
  const { t } = useTranslation();
  const handleOnClose = () => {
    dataLayer.push(CLOSE_DATALAYER_KEY);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnRedirect = () => {
    dataLayer.push(REDIRECT_DATALAYER_KEY);
  };

  useEffect(() => {
    dataLayer.push(SHOWUP_DATALAYER_KEY);
  }, []);

  return (
    <AlertPopup
      title={t("We noticed you’re under 16 years old and are applying for a passport renewal.")}
      alertMessage={t(
        "If however, you’ve entered the wrong date of birth by mistake simply correct the error and continue filling in the form as normal."
      )}
      onClose={handleOnClose}
      content={
        <>
          <Trans components={{ b: <b /> }}>
            {t(
              "<b>Remember:</b> When you’re under 16 you must fill out a DS-11 new application even if this isn’t your first passport."
            )}
          </Trans>
        </>
      }
      redirect={{
        to: "/new-passport",
        label: t("Go to DS-11"),
        onClick: handleOnRedirect,
      }}
    />
  );
};

export default Under16Alert;
