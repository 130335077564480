import React from "react";
import Wrapper from "../../Layout/Wrapper/wrapper";
import { ReactSVG } from "react-svg";
import "./styles.scss";
import sanitizeHTML from "../../utils/sanitizeHtml";
import classnames from "classnames";

const Billboard = ({
  title,
  subtitle,
  imageOnRight,
  stateAbbreviation,
  backgroundImageUrl,
  component,
  className,
}) => {
  const classNames = classnames(`Billboard`, {
    [className]: typeof className === "string" && className.length > 0,
  });

  return (
    <div
      className={classNames}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
      <Wrapper classNames="Billboard__wrapper">
        <div className={`Billboard__image ${imageOnRight ? "Billboard__image--right" : ""}`}>
          {stateAbbreviation && <ReactSVG src={`/svg/states/${stateAbbreviation}.svg`} />}
          {imageOnRight && (
            <img
              src={imageOnRight}
              alt={title}
            />
          )}
        </div>
        <div className="Billboard__content">
          {title && <h1 className="Billboard__title">{title}</h1>}
          {subtitle && (
            <h2
              className="Billboard__subtitle"
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(subtitle) }}
            />
          )}
          {component && component}
        </div>
      </Wrapper>
    </div>
  );
};

export default Billboard;
