export const TypeSelectorValidate = (field, value, checked) => {
  const fielMinLeght = field.minlength ? field.value.length >= field.minlength : true;
  const fieldValue = field.value !== "";
  const fieldValidation = fielMinLeght ? fielMinLeght === fieldValue : false;

  let re =
    //eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  switch (field.component) {
    case "TextInput":
      switch (field.name) {
        case "email":
          return re.test(String(field.value).toLowerCase());
        case "emailAddress":
          return re.test(String(field.value).toLowerCase());
        case "emailAddressValidation":
          return re.test(String(field.value).toLowerCase());
        default:
          return fieldValidation;
      }
    case "InputMask":
      switch (field.name) {
        case "ssn":
          return field.value.length === 11;
        default:
          return fieldValue;
      }
    default:
      return fieldValidation;
  }
};
