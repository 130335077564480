import { graphql, useStaticQuery } from "gatsby";

const useGetSiteMetaData = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(useQuery);

  return siteMetadata;
};

export default useGetSiteMetaData;

const useQuery = graphql`
  query {
    site {
      siteMetadata {
        config {
          payment {
            showAgreeTermsAndCondition
          }
        }
      }
    }
  }
`;
