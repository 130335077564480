import { useMemo, useState, useEffect } from "react";
import { getCookie } from "../components/utils/cookies";
import { commonData } from "../data/variables/text_words/common.js";
import { companiesWords } from "../../bin/generateSensitiveWord/words/companies";
import useGetSiteMetadata from "./useGetSiteMetadata";
import useGetSiteSvgs from "./useGetSiteSvgs";
import {
  parseTextWithEnviromentVariables,
  getEnviromentVariable,
  generateMerchantsList,
} from "../components/utils/siteData";

const useSiteData = () => {
  const siteDataOriginal = useGetSiteMetadata();
  const siteInfo = getCookie("site_info");
  const { domain, companyKey, webFullName, hasPhone, termsAndConditionsCompanies } = siteInfo || "";
  const [isLoadingSiteData, setIsLoadingSiteData] = useState(true);
  const memoizedCompaniesIds = useMemo(
    () => termsAndConditionsCompanies,
    [termsAndConditionsCompanies]
  );

  const companyNamesFromId = memoizedCompaniesIds?.map((company) =>
    companiesWords[company].company_name.replace(/ /g, "_")
  );

  const avoidSvgFetchForSites = [
    "amazingusatravels.com",
    "leadingtouroffers.com",
    "us-passports.org",
  ].includes(domain);

  const { data: svgOrTextSiteData, isLoading: isLoadingSvgSiteData } = useGetSiteSvgs(
    siteDataOriginal.web_fontFamily.replace(/ /g, "-"),
    companiesWords[companyKey]?.company_name.replace(/ /g, "-"),
    memoizedCompaniesIds,
    companiesWords,
    avoidSvgFetchForSites
  );

  const buildedSiteData = useMemo(() => {
    if (isLoadingSvgSiteData) return;
    if (Object.keys(siteInfo).length === 0) return;

    let baseSiteData = {
      web_name: domain,
      web_full_name: webFullName,
      web_mail: `<a href="mailto:info@${domain}">info@${domain}</a>`,
      ...companiesWords[companyKey],
      ...siteDataOriginal,
      web_price: `$${siteInfo.webPrice}`,
      addon_price_insurance: `$${siteInfo.addonPriceInsurance}`,
      ...commonData,
      tiktokMessage:
        domain === "passportsusa.org"
          ? "Our App name: passportsusa.org is a domain registered by the Company."
          : null,
      tiktokMessageEs:
        domain === "passportsusa.org"
          ? "Nuestra App: passportsusa.org es un dominio registrado por la Empresa."
          : null,
    };

    if (hasPhone === true) {
      const { companyPhoneNumber } = getCookie("site_info");
      baseSiteData = { ...baseSiteData, site_phone: companyPhoneNumber };
    }

    baseSiteData = { ...baseSiteData, ...svgOrTextSiteData };

    return baseSiteData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteInfo]);

  const parsedSiteData = useMemo(() => {
    if (!buildedSiteData) return;
    if (Object.keys(siteInfo).length === 0) return;

    const parsedSiteData = {
      ...buildedSiteData,
      about_our_services:
        parseTextWithEnviromentVariables(
          getEnviromentVariable("about_our_services", buildedSiteData),
          buildedSiteData
        ) || "",
      about_our_services_es:
        parseTextWithEnviromentVariables(
          getEnviromentVariable("about_our_services_es", buildedSiteData),
          buildedSiteData
        ) || "",
      merchant_of_record_info: generateMerchantsList(
        companyNamesFromId,
        "merchant_of_record_info",
        buildedSiteData,
        companiesWords
      ),
      merchant_of_record_info_es: generateMerchantsList(
        companyNamesFromId,
        "merchant_of_record_info_es",
        buildedSiteData,
        companiesWords
      ),
    };
    return parsedSiteData;
  }, [buildedSiteData, companyNamesFromId, siteInfo]);

  useEffect(() => {
    if (parsedSiteData) {
      setIsLoadingSiteData(false);
    }
  }, [parsedSiteData]);

  return {
    isLoadingSiteData,
    siteData: parsedSiteData,
  };
};

export default useSiteData;
