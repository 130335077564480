import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import classnames from "classnames";
import { Title } from "../../atoms/Title/title";
import { ReactSVG } from "react-svg";
import RichText from "../../organisms/RichText/richText";
import SiteDataContext from "../../../context/SiteDataContext";
import "./styles.scss";

const Steps = ({ title, stepsArray, hasRichtext, centered }) => {
  const { t } = useTranslation();
  const siteData = useContext(SiteDataContext);

  const classNames = classnames(`Steps`, {
    "Steps--centered": centered,
  });

  return (
    <div className={classNames}>
      {title && (
        <Title
          medium
          tag="h2"
          content={t(title)}
        />
      )}
      <div className="Steps__list">
        {stepsArray.map((item, index) => (
          <div
            className="Steps__item"
            key={`Step-item-${index}`}>
            {item.icon ? (
              <ReactSVG
                src={item.icon}
                className="Steps__icon"
                title={item.title}
              />
            ) : (
              <div className="Steps__number extra-large">{stepsArray.indexOf(item) + 1}</div>
            )}
            {hasRichtext ? (
              <RichText
                content={item.copy}
                siteData={siteData}
              />
            ) : (
              <>
                <div className="Steps__title">{t(item.title)}</div>
                <p className="Steps__text">{t(item.text)}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Steps;
