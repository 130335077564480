import React, { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Loading from "../../components/atoms/Loading";
import DotsLoader from "../../components/atoms/DotsLoader";
import Seo from "../../components/utils/SEO";
import { graphql, navigate } from "gatsby";
import { MessageBox } from "../../components/molecules/MessageBox";
import { GetTransactionInfoHandler } from "../../services/api/GetTransactionInfo/GetTransactionInfoHandler";
import { GetApplicationInfoHandler } from "../../services/api/GetApplicationInfo/GetApplicationInfoHandler";
import { getCookie, removeCookie } from "../../components/utils/cookies";
import { delay, isInAnIframe } from "../../components/utils";
import "./styles.scss";

const VerifyPayment = () => {
  const { t } = useTranslation();
  const orderCookie = getCookie("address_token");

  const [state, setState] = useState({
    gatewayIdentifier: orderCookie.gateway,
    transactionId: orderCookie.transactionId,
    applicationId: orderCookie.order,
    loading: true,
    isInAnIframe: null,
    mounted: false,
  });

  const getStatusTransaction = () => {
    return new Promise(async (resolve, reject) => {
      const GetTransactionInfo = new GetTransactionInfoHandler({});
      const data = {
        gatewayIdentifier: state.gatewayIdentifier,
        gatewayExternalId: state.transactionId,
      };

      let retries = 20;
      let flag = true;
      while (flag) {
        try {
          const res = await GetTransactionInfo.customAction(data);

          if (res.transaction_status === "Success") {
            flag = false;
            return resolve();
          } else if (res.transaction_status === "Failed" || retries === 0) {
            flag = false;
            throw reject();
          }

          retries = retries - 1;
          await delay(4000);
        } catch (error) {
          flag = false;
          throw reject();
        }
      }
    });
  };

  const getStatusApplication = () => {
    return new Promise(async (resolve, reject) => {
      const GetApplicationInfo = new GetApplicationInfoHandler({});

      const redirectToThankYou = () => {
        const orderCookie = getCookie("address_token");

        if (orderCookie.originalSite) {
          // remove local cookies before redirect
          removeCookie("address_token");
          removeCookie("pdfId");
          removeCookie("app_id");
          removeCookie("order_status");

          window.top.location.href = `https://${orderCookie.originalSite}/thank-you?order=${orderCookie.applicationId}&pdf=${orderCookie.pdfId}`; // Leave window.top.location.href as is outside of the iframe
        } else {
          window.top.location.href = "/thank-you"; // Leave window.top.location.href as is outside of the iframe
        }
      };

      const data = {
        applicationId: state.applicationId,
      };

      let retries = 20;
      let flag = true;
      while (flag) {
        try {
          const response = await GetApplicationInfo.customAction(data);

          if (response.status === "registered" && retries !== 0) {
            retries = retries - 1;
            await delay(2000);
          } else if (response.status === "registered" && retries === 0) {
            flag = false;
            throw reject();
          } else {
            flag = false;
            redirectToThankYou();
            return resolve();
          }
        } catch (error) {
          flag = false;
          throw reject(error);
        }
      }
    });
  };

  const verifyOrder = async () => {
    try {
      if (state.gatewayIdentifier === "macropay" && typeof state.transactionId === "string") {
        await getStatusTransaction();
      }

      await getStatusApplication();
    } catch (error) {
      console.error("error verifyOrder", error);
      window.top.location.href = "/payment?error=true";
    }
  };

  useEffect(() => {
    if (state.applicationId === undefined || state.applicationId === "") {
      navigate("/home");
    }

    setState((prevState) => ({
      ...prevState,
      isInAnIframe: isInAnIframe(),
      mounted: true,
    }));

    verifyOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.applicationId]);

  return !state.mounted ? (
    <Loading full />
  ) : (
    <>
      {state.isInAnIframe ? (
        <Message className="verify-payment verify-payment--inIframe" />
      ) : (
        <Layout
          bgColor={`bg-grey`}
          hideDisclaimer
          hideFooterNavigation>
          <Seo title={t("Thank you for trusting us")} />
          <Message className="verify-payment verify-payment--normal" />
        </Layout>
      )}
    </>
  );
};

const Message = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      classNames={className}
      small>
      <MessageBox
        title={t("Please wait while we verify the payment")}
        content={
          <>
            <Loading
              message={
                <span>
                  {t("Verifying ")}
                  <DotsLoader />
                </span>
              }
            />
          </>
        }
      />
    </Wrapper>
  );
};

export default VerifyPayment;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
