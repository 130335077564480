import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Wrapper from "../../Layout/Wrapper/wrapper";
import usePrepareData from "./hooks/usePrepareData";
import useStyles from "./hooks/useStyles";
import classnames from "classnames";
import { ReactSVG } from "react-svg";
import SiteDataContext from "../../../context/SiteDataContext";
import RichText from "../../organisms/RichText/richText";
import(`./OurBenefits.scss`);

const OurBenefits = ({ data, template, className, showHeader = true, hasRichtext }) => {
  const { t } = useTranslation();
  const { title, subtitle, benefits } = usePrepareData(data);
  const { styles } = useStyles(template);
  const siteData = useContext(SiteDataContext);

  const classNames = classnames(`our-benefits`, {
    [styles.container]: styles.container,
    [className]: className,
  });

  return (
    <div className={classNames}>
      <Wrapper paddingTop>
        <div className="our-benefits__wrapper">
          {showHeader && (
            <div className={`our-benefits__present`}>
              <h3 className={`our-benefits__present__title large`}>{t(title)}</h3>
              <span className={`our-benefits__present__desc medium`}>{subtitle}</span>
            </div>
          )}

          <ul className={`our-benefits__list`}>
            {Object.keys(benefits).map((key) => {
              const benefit = benefits[key];
              return (
                <li
                  key={key}
                  className={`our-benefits__item`}>
                  <ReactSVG
                    className={`our-benefits__icon`}
                    src={showHeader ? benefit.icon : benefit.simpleIcon}
                  />
                  {hasRichtext ? (
                    <RichText
                      content={benefit.value}
                      customClass={`medium our-benefits__richtext`}
                      siteData={siteData}
                    />
                  ) : (
                    <p className={`medium`}>{t(benefit.value)}</p>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </Wrapper>
    </div>
  );
};
export default OurBenefits;
