import { useMemo } from "react";
import PropTypes from "prop-types";

/**
 *
 * @param props
 * @param {String[]} props.stepNames It is a label of use in the form, this labels have a action in the form
 * @param {String[]} props.customStepNames Add new labels in the nav, this labels don't have a action in the form
 * @returns {String[]} return concatenated step names
 *
 */

const useGetStepNames = ({ stepNames = [], customStepNames = [] }) => {
  const concatenatedStepNames = useMemo(() => {
    let data = [...stepNames, ...customStepNames];

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepNames]);

  return {
    concatenatedStepNames,
  };
};

useGetStepNames.propTypes = {
  props: PropTypes.shape({
    stepNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    customStepNames: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default useGetStepNames;
