import { Children, isValidElement, cloneElement } from "react";

export const addPropsInChildren = (children, props) =>
  Children.map(children, (child) => {
    if (isValidElement(child)) {
      let childrenProps = child.props.children;

      return cloneElement(child, { ...props, children: childrenProps });
    }

    return child;
  });
