import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isHTML } from "../../utils";
import "./styles.scss";

/**
 *
 * @param {Object} props
 * @param {"error"|"warning"|"success"|"light"} props.type
 * @param {Function} props.onClose
 * @param {String} props.className
 * @param {Object} props.content
 * @param {Boolean} props.showLeftIcon
 * @param {String} props.svgIcon
 * @param {Boolean} props.closeIcon
 * @param {Boolean} props.compact
 * @param {Boolean} props.center
 *
 */

const Alert = ({
  type = "warning",
  className,
  onClose,
  children,
  content,
  showLeftIcon,
  svgIcon,
  closeIcon,
  compact,
  center,
  collapsedMobileTest,
}) => {
  const classNames = classnames("alert", {
    [className]: className,
    "alert--error": type === "error",
    "alert--warning": type === "warning",
    "alert--success": type === "success",
    "alert--light": type === "light",
    "alert--compact": compact,
    "alert--leftImg": showLeftIcon,
    "alert--withIcon": svgIcon,
    "alert--center": center,
    "alert--collapsedMobileTest": collapsedMobileTest,
  });

  const message = useMemo(() => {
    if (children) {
      return children;
    }

    if (isHTML(content)) {
      return <span dangerouslySetInnerHTML={{ __html: content }}></span>;
    }

    return content;
  }, [children, content]);

  const handleOnClose = (event) => {
    if (typeof onClose === "function") {
      onClose(event);
    }
  };

  return (
    <div
      data-testid="alert"
      className={classNames}>
      {svgIcon && (
        <ReactSVG
          src={`/svg/${svgIcon}`}
          className="alert__svg"
          title="alert-icon"
        />
      )}
      {closeIcon && (
        <div
          data-testid="alert__close"
          className="alert__close"
          onClick={handleOnClose}
          onKeyDown={handleOnClose}
          role="button"
          tabIndex={0}
          aria-label="Close">
          &#10005;
        </div>
      )}
      {message}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(["error", "warning", "success", "light"]),
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  onClose: PropTypes.func,
  showLeftIcon: PropTypes.bool,
  svgIcon: PropTypes.string,
  closeIcon: PropTypes.bool,
  compact: PropTypes.bool,
  center: PropTypes.bool,
};

export default Alert;
