export const cardsPassportData = () => {
  let cards = [
    {
      id: "fbe3d439-9806-5b6f-96d3-3e0d48332492",
      show: true,
      name: "DS-11",
      headerTitle: "First Time Passport <span style='white-space: nowrap;'>Form DS-11</span>",
      formButtonText: "Start Now",
      slug: "new-passport",
      formImage: {
        id: "7ab7d93c-a65a-5a10-be6b-a53944a7605e",
        url: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Complete form DS-11 if you are:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Applying for a child under 16.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "You have never applied for a U.S. travel document before.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "It’s been 15 years since your last passport was issued.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "You’re over 16 and your last passport was issued when you were 15 or under.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 1,
      orderByTemplate: {
        default: 1,
        template_1: 2,
      },
    },
    {
      id: "6bc785f3-7b63-506f-aa47-45191ac91a44",
      show: true,
      name: "DS-82",
      headerTitle: "Passport Renewal <span style='white-space: nowrap;'>Form DS-82</span>",
      formButtonText: "Start Now",
      slug: "passport-renewal",
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: false,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Complete form DS-82 if your passport:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Is undamaged.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Is in your possession.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Was issued when you were 16 or older.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Has few blank pages left.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Expired in the last 5 years.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Will expire in the next year",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Was issued over 1 year ago and your name has been legally changed",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      alertIcon: "certified-check.svg",
      alertText: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "paragraph",
              data: {},
              content: [
                {
                  nodeType: "text",
                  value: "Over ",
                  marks: [],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: "140,000",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: " passports processed.",
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
      order: 2,
      orderByTemplate: {
        default: 2,
        template_1: 1,
      },
    },
    {
      id: "fc8e81b7-09c9-5cb0-bcd3-da26d380677c",
      name: "DS-64",
      headerTitle: "Missing/Stolen Passport <span style='white-space: nowrap;'>Form DS-64</span>",
      formButtonText: "Start Now",
      slug: "lost-stolen-passport",
      show: true,
      formImage: {
        id: "e8a3adb2-ccec-51d8-8274-72c3ae9e1b27",
        url: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png?w=95&h=122&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Complete this form DS-64 if:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Your last passport was lost or stolen and the loss hasn’t been reported.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "You’d like to apply for a new passport book or card after the loss/theft of your previous one",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 3,
      orderByTemplate: {
        default: 3,
        template_1: 3,
      },
    },
    {
      id: "e83c32e8-9e8d-4286-bdd4-c3bf72902000",
      show: true,
      name: "DS-5504",
      headerTitle: "Changes and Corrections",
      formButtonText: "Apply now",
      slug: "passport-corrections",
      newLabel: true,
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requirements:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "Start a DS-5504 if the following applies:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Your passport has a printing error that occured within a year of its issuing date.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Your passport is in good condition.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "You have documentation to support the change or correction you are requesting.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "You have a “Limited Validity Passport”.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 5,
      orderByTemplate: {
        default: 5,
        template_1: 5,
      },
    },
  ];

  // TODO: Sort array cards by defined attributes
  return cards.sort((item, prevItem) => {
    const template = "default";

    if (typeof item.orderByTemplate === "object") {
      return item.orderByTemplate[template] - prevItem.orderByTemplate[template];
    } else {
      return item.order - prevItem.order;
    }
  });
};

export const ourBenefitsData = {
  benefitTitle: "Simple Fillings Advantages:",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: "Personalized instructions for your application form",
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: "Quick and easy-to-fill online templates",
  benefit2: "Avoid simple errors that cost time and money",
  benefit3: "Full customer support",
  benefit4: "Step-by-step guidance",
};

export const stepsData = {
  edges: [
    {
      node: {
        id: "13de9fd1-8c3c-5f84-8410-afe95ccf68db",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "Step 1",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Enjoy peace of mind when filling in a passport application online.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Get all the help you need during the process.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 1,
      },
    },
    {
      node: {
        id: "0eaa22da-408c-5cdc-921e-f5c2f683607a",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "Step 2",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Fill-in, print, and sign your application form with the help of easy-to-follow instructions.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Then simply submit it at a local passport office.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 2,
      },
    },
    {
      node: {
        id: "738902d5-db5a-5d8c-a61f-c7d2ab35dcc5",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: " ",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "All Government processing fees must be paid separately.",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "hyperlink",
                    content: [
                      {
                        nodeType: "text",
                        value: "Visit Official Site",
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {
                      uri: "https://travel.state.gov/content/passports/en/passports.html",
                    },
                  },
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 3,
      },
    },
  ],
};
