import initialAddons from "../../data/addons.json";

export const buildAddons = (addons) => {
  if (addons && Array.isArray(addons)) {
    const addonsOutput = addons.map((addon) => buildAddon(addon));
    return addonsOutput;
  } else {
    return [];
  }
};

const buildAddon = (addon) => ({
  ...initialAddons[addon.identifier],
  ...addon,
  price: `$${addon.amount.toFixed(2)}`,
});

/**
 *
 * @param {Number} price
 * @param {Array} addons
 */
export const calculateTotal = (price, addons) => {
  let result = +price;

  if (addons && Array.isArray(addons)) {
    addons.forEach((addon) => (addon.value === "1" ? (result += +addon.amount) : null));
  }

  return result;
};
