import React, { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Button from "../../components/atoms/Button";
import Loading from "../../components/atoms/Loading";
import { Title } from "../../components/atoms/Title/title";
import { Subtitle } from "../../components/atoms/Subtitle/subtitle";
import { GetPdfHandler } from "../../services/api/GetPdf/GetPdfHandler";
import { getUrlParams, isIEBrowser } from "../../components/utils";
import * as styles from "./styles.module.scss";
import iconDownloadPdf from "../../images/svg/save_alt_24px_white.svg";
import "promise-polyfill/src/polyfill";
import { graphql } from "gatsby";

const Pdf = () => {
  const { t } = useTranslation();
  const { reference, signature } = getUrlParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const getApplicationPdf = async (params) => {
    try {
      const PdfHandler = new GetPdfHandler({ type: "application/pdf" });
      const response = await PdfHandler.customAction(params);

      let file = new Blob([response.pdf], { type: "application/pdf" });
      let fileURL = URL.createObjectURL(file);

      if (isIEBrowser()) {
        window.navigator.msSaveOrOpenBlob(file, `${reference}.pdf`);

        fileURL = window.location.href;
      } else {
        var a = document.createElement("a");
        a.href = fileURL;
        a.setAttribute("download", `${reference}.pdf`);
        a.click();
      }

      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (typeof reference !== "undefined") {
      getApplicationPdf({ reference, signature });
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, signature]);

  return (
    <Layout>
      <Wrapper
        compact
        center>
        {loading ? (
          <Loading full />
        ) : (
          <>
            {typeof reference !== "undefined" && (
              <>
                {success ? (
                  <Message
                    title={t("Thank you for trusting us")}
                    subtitle={t("If the download doesn't start automatically after a few seconds, use the following button.")}
                    button={{
                      label: t("Download your form"),
                      link: window.location.href,
                      icon: true,
                      download: false,
                    }}
                  />
                ) : (
                  <Message
                    title={t("The link has expired")}
                    subtitle={t("For security reasons the link you are looking for has expired. Please, contact us and we will send you another link.")}
                    button={{
                      label: t("CONTACT US"),
                      link: "/contact",
                      icon: false,
                      download: false,
                    }}
                  />
                )}
              </>
            )}

            <Wrapper paddingTop>
              <Button
                link="/home"
                tertiary
                label={t("Go to homepage")}
              />
            </Wrapper>
          </>
        )}
      </Wrapper>
    </Layout>
  );
}

export default Pdf;

const Message = ({ title, subtitle, button: { link, download, label, icon } }) => {
  return (
    <>
      <Title
        hero
        tag="h1"
        content={title}></Title>
      <Subtitle
        big
        content={subtitle}
      />
      <Button
        link={link}
        label={label}
        icon={icon ? iconDownloadPdf : false}
        download={download}
        data-qa={"download-pdf"}
        className={styles.download_button}
        iconLeft
        primary
      />
    </>
  );
};

Message.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    download: PropTypes.bool,
    icon: PropTypes.bool,
  }),
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;