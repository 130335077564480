import { useState, useMemo, useCallback, useEffect } from "react";
import useGetApplication from "./useGetApplication";
import { GetFormDetailsHandler } from "../services/api/GetFormDetails/GetFormDetailsHandler";
import { GetOrderHandler } from "../services/api/GetOrder/GetOrderHandler";
import { buildFormWithStorageData } from "../components/utils/formDataInStorage";
import { buildAddons } from "../components/utils/addons";
import { getCookie, removeCookies } from "../components/utils/cookies";
import { buildFormDataWithDBdata } from "../components/utils/formDataFromBD";
import { windowIsExist } from "../components/utils";
import { navigate } from "gatsby";

export const buildStructure = (structure) => ({
  ...structure,
  steps: structure.steps.map((step) => {
    return {
      ...step,
      allFields: step.groups.reduce(
        (lastFields, currentField) => [...lastFields, ...currentField.fields],
        []
      ),
    };
  }),
});

const usePrepareFormData = (json) => {
  const { applicationId, getApplicationInfo } = useGetApplication();

  const [loading, setLoading] = useState(true);

  const [form, setForm] = useState(buildFormWithStorageData(json.fields));
  const [structure, setStructure] = useState(buildStructure(json.structure));

  const [formDetails, setFormDetails] = useState({
    addons: [],
    price: 0,
    pdf: {},
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formName = useMemo(() => json.fields.pdfId.name, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stepNames = useMemo(
    () => structure.steps.map((step) => step.label.split(".")[1]),
    [structure]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const orderCookie = useMemo(() => {
    const cookie = getCookie("address_token");
    return {
      ...cookie,
      pdfId: cookie.pdfId,
      orderReference: cookie.reference,
      applicationId: applicationId,
    };
  }, [applicationId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFormToEdit = useMemo(
    () => windowIsExist && window.location.pathname.includes("/edit/"),
    []
  );

  const getFormDetails = useCallback(
    async (existApplication) => {
      const GetFormDetails = new GetFormDetailsHandler({});
      try {
        const response = await GetFormDetails.customAction({
          formId: form.pdfId.value,
          addons: true,
        });

        let addons = buildAddons(response.addons);
        const hasFormAddons = typeof form.addons !== "undefined";

        if (hasFormAddons && !existApplication) {
          const firstStep = structure.steps[0];
          const field = firstStep.groups.find((item) => item.name === "addons");
          if (!field.hide && !isFormToEdit) {
            addons = addons.map((addon) => ({ ...addon, value: addon.preselected ? "1" : "0" }));
          }
        }

        if (applicationId) {
          try {
            const { form } = await getOrder();
            const orderActiveAddons = form.addons ? form.addons.map((i) => i.id) : [];
            addons = addons.map((addon) => ({
              ...addon,
              value: orderActiveAddons.includes(addon.id) ? "1" : "0",
            }));
          } catch (error) {
            console.error(error);
          }
        }

        const details = {
          addons: addons,
          price: response.pdf.prices[0].amount,
          pdf: response.pdf,
        };

        setFormDetails(details);

        return details;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, structure, orderCookie]
  );

  const getOrder = useCallback(async () => {
    const orderHandler = new GetOrderHandler({});

    return orderHandler.customAction({
      applicationId,
    });
  }, [applicationId]);

  const prepareFormData = useCallback(async () => {
    try {
      const response = await getOrder();
      const currentForm = { ...form };

      return buildFormDataWithDBdata(currentForm, response.form);
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const prepareStructure = () => {
    setStructure(
      buildStructure({
        ...json.structure,
        steps: json.structure.steps.map((step) => ({ ...step, validated: true })),
      })
    );
  };

  const verifyApplicationStatus = useCallback(async () => {
    try {
      await getApplicationInfo();
      prepareStructure();

      const buildedFormFromDB = await prepareFormData();
      const { addons } = await getFormDetails();

      setForm({ ...buildedFormFromDB, addons: { ...form.addons, value: addons } });

    } catch (error) {
      if (typeof error.applicationStatus === "string" && error.applicationStatus !== "registered") {
        removeCookies();
        window.location.reload();
      }
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCookie, prepareFormData]);

  const loadFormData = useCallback(async () => {
    try {
      const { addons } = await getFormDetails();

      if (orderCookie.applicationId && orderCookie.pdfId === form.pdfId.value) {
        await verifyApplicationStatus();
      } else {
        setForm({ ...form, addons: { ...form.addons, value: addons } });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error(error);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, orderCookie]);

  useEffect(() => {
    if (
      isFormToEdit &&
      (!orderCookie.applicationId || json.fields.pdfId.value !== orderCookie.pdfId)
    ) {
      navigate(window.location.pathname.replace("/edit", ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormToEdit, orderCookie]);

  useEffect(() => {
    loadFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    form,
    structure,
    stepNames,
    formDetails,
    orderCookie,
    formName,
    isFormToEdit,
  };
};

export default usePrepareFormData;
