import React, { useContext } from "react";
import SiteDataContext from "../../../context/SiteDataContext";
import "./styles.scss";
import { Title } from "../../atoms/Title/title";
import RichText from "../../organisms/RichText/richText";

export const Infobox = (props) => {
  const siteData = useContext(SiteDataContext);

  return (
    <div
      className="infobox"
      data-qa={props.dataQa}>
      <Title
        regular
        content={props.title}
      />
      <RichText
        content={props.content}
        siteData={siteData}
      />
    </div>
  );
};
