export default function stickyFallback(fixed) {
  const orderSummaryContainer = document.getElementById("orderSummaryContainer");
  const orderSummaryInitial = orderSummaryContainer.getBoundingClientRect().top - 20;

  if (typeof window !== "undefined") {
    if (window.innerWidth > 960 && fixed) {
      window.addEventListener("scroll", function () {
        let fixedPositionIncrement = window.scrollY - orderSummaryInitial;
        orderSummaryContainer.style.top =
          fixedPositionIncrement > 0 ? fixedPositionIncrement + "px" : 0;
      });
    }
  }

  return () => {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", () => {});
    }
  };
}
