// extracted by mini-css-extract-plugin
export var big = "changes-corrections-module--big--12b6d";
export var body = "changes-corrections-module--body--3b575";
export var body_content = "changes-corrections-module--body_content--c13c0";
export var body_content_action = "changes-corrections-module--body_content_action--92a7f";
export var body_content_button = "changes-corrections-module--body_content_button--659a8";
export var extraLarge = "changes-corrections-module--extra-large--cfa94";
export var get_started_box = "changes-corrections-module--get_started_box--3f5fb";
export var header = "changes-corrections-module--header--204df";
export var header_action = "changes-corrections-module--header_action--a27ae";
export var header_action_button = "changes-corrections-module--header_action_button--bc5bc";
export var header_action_link = "changes-corrections-module--header_action_link--5d830";
export var header_image = "changes-corrections-module--header_image--6c8f9";
export var header_left = "changes-corrections-module--header_left--579af";
export var header_subtitle = "changes-corrections-module--header_subtitle--d31ef";
export var header_title = "changes-corrections-module--header_title--4d41c";
export var hero = "changes-corrections-module--hero--ebf62";
export var inputFull = "changes-corrections-module--input-full--fad68";
export var inputHalf = "changes-corrections-module--input-half--4b773";
export var inputHalfDesktop = "changes-corrections-module--input-half--desktop--ee301";
export var inputOneFourth = "changes-corrections-module--input-one-fourth--fc808";
export var inputOneSixth = "changes-corrections-module--input-one-sixth--a785a";
export var inputOneThird = "changes-corrections-module--input-one-third--4b011";
export var inputOneThirdDesktop = "changes-corrections-module--input-one-third--desktop--348e9";
export var inputOneThirdFirst = "changes-corrections-module--input-one-third--first--23286";
export var inputOneThirdGutter = "changes-corrections-module--input-one-third--gutter--569bf";
export var inputOneThirdInline = "changes-corrections-module--input-one-third--inline--4ffc5";
export var inputOneThirdNoMargin = "changes-corrections-module--input-one-third--no-margin--34a9d";
export var inputTwoThirds = "changes-corrections-module--input-two-thirds--99c93";
export var large = "changes-corrections-module--large--eb6f5";
export var mainInstructions = "changes-corrections-module--main--instructions--9ec5e";
export var medium = "changes-corrections-module--medium--5e3ae";
export var note = "changes-corrections-module--note--16dbf";
export var ourBenefits = "changes-corrections-module--ourBenefits--bd46c";
export var requeriments = "changes-corrections-module--requeriments--e14b1";
export var small = "changes-corrections-module--small--2b59b";
export var steps__landing = "changes-corrections-module--steps__landing--006f4";
export var superHero = "changes-corrections-module--super-hero--ba667";
export var uAlignCenter = "changes-corrections-module--u-align-center--51bcf";
export var uBox = "changes-corrections-module--u-box--dff95";
export var uBoxSmall = "changes-corrections-module--u-box--small--7b40c";
export var uDesktopOnly = "changes-corrections-module--u-desktop-only--4c8da";
export var uDisplayNone = "changes-corrections-module--u-display-none--b18f4";
export var uFlex = "changes-corrections-module--u-flex--2f78e";
export var uFlexAlignCenter = "changes-corrections-module--u-flex--align-center--8ef54";
export var uFlexJustifyBetween = "changes-corrections-module--u-flex--justify-between--d87db";
export var uFlexJustifyEnd = "changes-corrections-module--u-flex--justify-end--a9e9b";
export var uFlexWrap = "changes-corrections-module--u-flex--wrap--8da1c";
export var uFull = "changes-corrections-module--u-full--6dc83";
export var uHalf = "changes-corrections-module--u-half--caaa7";
export var uHiddenNoMargin = "changes-corrections-module--u-hidden-no-margin--85f02";
export var uLegalLink = "changes-corrections-module--u-legal-link--aba5f";
export var uListBullet = "changes-corrections-module--u-list-bullet--77493";
export var uMarginBottom = "changes-corrections-module--u-marginBottom--ba5d8";
export var uMarginS = "changes-corrections-module--u-margin-s--421ee";
export var uMobileOnly = "changes-corrections-module--u-mobile-only--ce0fe";
export var uStickyFooter = "changes-corrections-module--u-sticky-footer--2c2c9";
export var uVisibilityNone = "changes-corrections-module--u-visibility-none--561d6";
export var uniqueRow = "changes-corrections-module--unique-row--e3241";
export var wrapper = "changes-corrections-module--wrapper--f7899";