import React, { createContext } from "react";
import useGetSiteInfo from "../hooks/useGetSiteInfo";
import useSiteData from "../hooks/useSiteData";
import ErrorBoundary from "../components/utils/ErrorBoundary";

const SiteDataContext = createContext({});

export const SiteDataContextProvider = ({ children }) => {
  return (
    <ErrorBoundary>
      <ProviderContent>{children}</ProviderContent>
    </ErrorBoundary>
  );
};

const ProviderContent = ({ children }) => {
  useGetSiteInfo();
  const siteData = useSiteData();

  return <SiteDataContext.Provider value={siteData}>{children}</SiteDataContext.Provider>;
};

export default SiteDataContext;
