import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { addPropsInChildren } from "../../utils/components";
import StepWizardContext from "../../../context/StepWizardContext";

const StepWizard = ({
  steps: propSteps,
  previousStep: propPreviousStep,
  children,
  stepValidator,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [mounted, setMounted] = useState(false);

  const steps = useMemo(
    () => (propSteps && Array.isArray(propSteps) ? propSteps : []),
    [propSteps]
  );
  const step = useMemo(() => steps[currentStep] || {}, [currentStep, steps]);

  const totalSteps = useMemo(() => (steps.length ? steps.length - 1 : 0), [steps.length]);

  const handleStepValidator = useCallback(
    (index, validator) => {
      if (typeof validator === "function") {
        return validator(index);
      } else if (typeof stepValidator === "function") {
        return stepValidator(index);
      }

      return true;
    },
    [stepValidator]
  );

  const nextStep = useCallback(
    (index) => {
      if (handleStepValidator(index)) {
        const newStep = index + 1;

        setCurrentStep(newStep);
        changeHash(newStep);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, handleStepValidator]
  );

  const previousStep = useCallback(
    (index) => {
      const prevStep = typeof index === "number" ? index : currentStep - 1;

      setCurrentStep(prevStep);
      changeHash(prevStep);

      if (typeof propPreviousStep === "function") {
        propPreviousStep();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep]
  );

  const checkStepsCompleted = useCallback(
    (stepIndex, validator) => {
      let isValid = false;

      for (let index = 0; index < stepIndex; index++) {
        const stepValidated = handleStepValidator(index, validator);

        if (typeof stepValidated === "boolean") {
          isValid = stepValidated;
        } else if (typeof stepValidated === "object") {
          isValid = stepValidated.isValid;
        }

        if (!isValid) break;
      }

      return isValid;
    },
    [handleStepValidator]
  );

  const navigateTo = useCallback(
    (index) => {
      if (index > currentStep && checkStepsCompleted(index)) {
        setCurrentStep(index);
      } else {
        setCurrentStep(index);
      }

      changeHash(index);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, checkStepsCompleted]
  );

  const changeHash = useCallback(
    (index) => {
      if (steps[index]) {
        const { name } = steps[index];
        window.history.replaceState(null, null, `#${name}`);
      }
    },
    [steps]
  );

  const childrenWithProps = useMemo(
    () =>
      addPropsInChildren(children, {
        currentStep,
        totalSteps,
        step,
        hashKey: step.name || "",
        nextStep,
        previousStep,
        navigateTo,
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, currentStep, totalSteps, currentStep, nextStep, previousStep]
  );

  useEffect(() => {
    const stepIndex = steps.findIndex((step) => `#${step.name}` === window.location.hash);

    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
    }

    setMounted(true);
  }, [steps]);

  return (
    <StepWizardContext.Provider
      value={{
        currentStep,
        totalSteps,
        step,
        hashKey: step.name,
        nextStep,
        previousStep,
        navigateTo,
        checkStepsCompleted,
      }}>
      {mounted ? childrenWithProps : null}
    </StepWizardContext.Provider>
  );
};

StepWizard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  isValidStep: PropTypes.bool,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
};

export default StepWizard;
