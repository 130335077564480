import React, { Component } from "react";
import "./styles.scss";
export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: props.minutes ? props.minutes : 5,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { minutes, seconds } = this.state;
    return (
      <span className="timer">
        {minutes === 0 && seconds === 0
          ? "0:00"
          : `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
      </span>
    );
  }
}
