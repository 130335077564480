import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import security from "../../../images/icons/security.png";

const SecurityFooter = ({ className }) => {
  const { t } = useTranslation();
  
  return (
    <div className={className}>
      <div className={`security-text`}>
        <img
          className={`paymentForm__security-icon`}
          src={security}
          alt="Security icon"
        />
        <p>
          {t("Your personal information is securely encrypted by Secure Sockets Layer (SSL) software.")}
        </p>
      </div>
    </div>
  );
};

export default SecurityFooter;
