import { scrollBehaviorTo, scrollY } from "../../utils";
import { getCookie } from "../../utils/cookies";

export const isAmex = (cardNumber) => {
  const amex = /^3[47][0-9]{13}$/;
  return amex.test(cardNumber.split(" ").join(""));
};

export const cvvMessage = (cardNumber) => {
  if (cardNumber === "" || !cardNumber) {
    return {
      placeholder: "123",
      helperMessage: "Last 3 or 4 digits written on the back or front of your card",
    };
  } else if (isAmex(cardNumber)) {
    return {
      placeholder: "1234",
      helperMessage: "4 digits above the last few digits of the card.",
    };
  } else {
    return {
      placeholder: "123",
      helperMessage: "Last 3 digits on the back of your card, commonly written in italic.",
    };
  }
};

export const scrollToRef = (ref) => {
  scrollBehaviorTo(
    document.getElementById(ref) &&
      document.getElementById(ref).getBoundingClientRect().top + scrollY() - 40
  );
};

export const loadIframeUrl = (src, onload, iframeContainerId) => {
  let iframeElement = document.createElement("iframe");
  iframeElement.src = src;
  iframeElement.classList.add("Pay__iframe");
  iframeElement.onload = onload;

  const orderCookie = getCookie("address_token");
  const iframeContainer = document.getElementById(
    `js-iframe-${iframeContainerId ? iframeContainerId : orderCookie.gateway}`
  );

  if (iframeContainer) {
    iframeContainer.innerHTML = "";
    iframeContainer.appendChild(iframeElement);
  }
};

const payItems = {
  form: {
    class: "Pay__item",
  },
  header: {
    class: "Pay__item-header",
    title: {
      class: "Pay__title Pay__title--single",
      showRadioButton: true,
    },
  },
  body: {
    class: "Pay__item-body",
  },
  footer: {
    class: "Pay__item-footer",
  },
};

const payItem = {
  form: {
    class: "Pay__item Pay__item--single",
  },
  header: {
    class: "Pay__item-header",
    title: {
      class: "Pay__title",
      showRadioButton: false,
    },
  },
  body: {
    class: "Pay__item-payment",
  },
  footer: {
    class: "Pay__item-footer",
  },
};

export const buildPayItemForm = (items) =>
  Array.isArray(items) && items.length > 1 ? payItems : payItem;
