import { buildAddons } from "./addons";
import { changeFieldProperties } from "./form";

const addOptionalInLabel = (label = "") =>
  label.includes("Optional") ? label : `${label} (Optional)`;
const removeOptionalInLabel = (label = "") =>
  label.includes("Optional") ? label.replace("(Optional)", "") : label;

export const loadPassportStatusField = ({ value, form }) => {
  let newForm = { ...form };
  const isYes = value === "yes";
  const fields = ["passportIssuanceDate", "bookNumber"];

  const properties = {
    required: isYes,
    label: isYes ? removeOptionalInLabel : addOptionalInLabel,
  };

  newForm = {
    ...newForm,
    ...changeFieldProperties(fields, properties, newForm),
  };

  return newForm;
};

export const loadPassportCardStatus = ({ value, form }) => {
  let newForm = { ...form };

  const isYes = value === "yes";
  const fields = ["cardIssuanceDate", "passportCardNumber"];
  const properties = {
    required: isYes,
    label: isYes ? removeOptionalInLabel : addOptionalInLabel,
  };

  newForm = {
    ...newForm,
    ...changeFieldProperties(fields, properties, newForm),
  };

  return {
    ...newForm,
  };
};

export const loadMarriedField = ({ value, form }) => {
  let newForm = { ...form };

  if (value === "no") {
    newForm.widowedDivorced.value = "";
  }

  return newForm;
};

const loadLegalstructureIdField = ({ key, form, responseForm }) => ({
  ...form,
  [key]: {
    ...form[key],
    value: responseForm[key],
    validField: true,
  },
});

const loadAddonsField = ({ key, form }) => {
  let newForm = { ...form };

  const addons = buildAddons(form[key].value);

  return {
    ...newForm,
    [key]: {
      ...newForm[key],
      value: addons.map((addon) => ({ ...addon, value: "1" })),
    },
  };
};

export const loadFormDataFromDB = {
  passportStatus: loadPassportStatusField,
  passportCardStatus: loadPassportCardStatus,
  married: loadMarriedField,
  legalstructureId: loadLegalstructureIdField,
  addons: loadAddonsField,
};

export const buildFormDataWithDBdata = (currentForm, responseForm) => {
  let newForm = { ...currentForm };

  for (const key in responseForm) {
    if (!newForm[key]) continue;

    newForm[key] = {
      ...newForm[key],
      value: responseForm[key],
      validField: true,
    };

    const loadField = loadFormDataFromDB[key];

    if (typeof loadField === "function") {
      newForm = {
        ...newForm,
        ...loadField({ key, value: newForm[key].value, form: newForm, responseForm }),
      };
    }

    if (newForm[key].value === "undefined") {
      newForm[key].value = "";
      newForm[key].validField = false;
    }

    if (newForm[key].value === null) {
      newForm[key].value = "";
    }
  }

  return newForm;
};
