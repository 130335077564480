import { useEffect } from "react";
import PropTypes from "prop-types";
import { isIEBrowser } from "../../utils";

const PreventReload = ({ isActive = true, children }) => {
  useEffect(() => {
    if (!isIEBrowser() && isActive) {
      const avoidExit = (event) => {
        const confirmationMessage = "o/";

        event.returnValue = confirmationMessage;
        return confirmationMessage;
      };

      window.addEventListener("beforeunload", avoidExit);

      return () => {
        window.removeEventListener("beforeunload", avoidExit);
      };
    }
  }, [isActive]);

  return children;
};

PreventReload.propTypes = {
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
};

export default PreventReload;
