import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import * as styles from "./modal.module.scss";

const ModalComponent = ({ children, className }) => {
  const [container, setContainer] = useState(null);
  const id = "__gatsby_modal";

  useEffect(() => {
    let div = document.getElementById(id);

    if (!div) {
      div = document.createElement("div");
      div.setAttribute("data-testid", "modal");
      div.id = id;
      div.className = `${styles.wrapper} ${className}`;
    }

    document.body.appendChild(div);
    setContainer(div);
    return () => {
      document.body.removeChild(div);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !container || createPortal(children, container);
};

export default ModalComponent;
