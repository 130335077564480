import React, { useContext } from "react";
import { graphql } from "gatsby";
import { getCookie } from "../../components/utils/cookies";
import { Layout } from "../../components/Layout/layout";
import SiteDataContext from "../../context/SiteDataContext";
import RichText from "../../components/organisms/RichText/richText";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Helmet } from "react-helmet";
import variableAdder from "../../services/variableAdder/variableAdder.js";
import { Title } from "../../components/atoms/Title/title";
import useGetLegalContent from "../../hooks/useGetLegalContent";
import "./legal.scss";

const LegalTemplate = ({ pageContext, data }) => {
  const { contentfulLegal: page } = data;
  const { domain } = getCookie("site_info");
  const showContentForSitesWithPhoneOrChat = useGetLegalContent(pageContext.slug);
  const siteData = useContext(SiteDataContext);
  const metadescription = page.metadescription;

  let pageContent = page.content;

  if (siteData.web_name === "pass.form-genius.com") {
    pageContent = page.contentFormGenius;
  } else if (showContentForSitesWithPhoneOrChat) {
    pageContent = page.contentForSitesWithPhoneOrChat;
  }

  return (
    <Layout hideMenuButton={false}>
      <Helmet>
        <title>{page.title}</title>
        <meta
          name="description"
          content={variableAdder(metadescription, domain)}
        />
      </Helmet>
      <div className="legal">
        <Wrapper paddingTop>
          <Title
            hero
            tag="h1"
            content={page.title}
          />
          <RichText
            content={pageContent}
            siteData={siteData}
            withRenderText
          />
        </Wrapper>
      </div>
    </Layout>
  );
};

export default LegalTemplate;

export const pageQuery = graphql`
  query ($slug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulLegal(slug: { eq: $slug }, node_locale: { eq: $language }) {
      title
      metadescription
      content {
        raw
      }
      contentForSitesWithPhoneOrChat {
        raw
      }
      contentFormGenius {
        raw
      }
    }
  }
`;