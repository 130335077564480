import React from "react";
import { initDatadog } from "./src/components/utils/Datadog";
import { initAmplitude } from "./src/components/utils/Amplitude";
import { SiteDataContextProvider } from "./src/context/SiteDataContext";

export const wrapRootElement = ({ element }) => {
  initDatadog();
  initAmplitude();
  return <SiteDataContextProvider>{element}</SiteDataContextProvider>;
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  return location.href.indexOf("#") > -1 ? false : true;
};

export const onClientEntry = () => {
  // Disable Gatsby's default error overlay for show errorBoundary locally
  if (window && window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = () => {};
  }
};
