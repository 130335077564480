import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Button from "../../atoms/Button";
import hero from "/static/images/hero.jpg";
import avatar1 from "/static/images/avatar1.jpg";
import avatar2 from "/static/images/avatar2.jpg";
import avatar3 from "/static/images/avatar3.jpg";
import avatar4 from "/static/images/avatar4.jpg";
import { ReactSVG } from "react-svg";
import { Title } from "../../atoms/Title/title";
import Tooltip from "../../Form/Tooltip/tooltip";
import SiteDataContext from "../../../context/SiteDataContext";

import "./styles.scss";

const Hero = ({ formData, title }) => {
  const { t } = useTranslation();
  const data = useContext(SiteDataContext);

  return (
    <div
      id="Hero"
      flxDesktop
      className="Hero">
      <div className="Hero__info">
        <div className="Hero__formTitle">
          <Title
            regular
            tag="h2"
            content={title}
          />
        </div>
        <h1 className="Hero__title">{t("Make Your U.S. Passport Application Easy")}</h1>
        <p className="Hero__text">
          {t(
            "Use our intuitive form with built-in error checking. Our efficient nationwide service handles your application, saving you time, worries, and stress."
          )}
        </p>
        <div className="Hero__ratings">
          <div className="Hero__ratingsAvatars">
            <img
              className="Hero__ratingsAvatar"
              alt="a person 1"
              src={avatar1}
            />
            <img
              className="Hero__ratingsAvatar"
              alt="a person 2"
              src={avatar2}
            />
            <img
              className="Hero__ratingsAvatar"
              alt="a person 3"
              src={avatar3}
            />
            <img
              className="Hero__ratingsAvatar"
              alt="a person 4"
              src={avatar4}
            />
          </div>
          <div className="Hero__ratingsStars">
            <div className="Hero__ratingsStars__header">
              <ReactSVG
                className="Hero__ratingsStar"
                src="/svg/star-rating.svg"
              />
              <ReactSVG
                className="Hero__ratingsStar"
                src="/svg/star-rating.svg"
              />
              <ReactSVG
                className="Hero__ratingsStar"
                src="/svg/star-rating.svg"
              />
              <ReactSVG
                className="Hero__ratingsStar"
                src="/svg/star-rating.svg"
              />
              <ReactSVG
                className="Hero__ratingsStar"
                src="/svg/star-rating.svg"
              />
              <span className="Hero__ratingsNumber">4,9</span>
            </div>
            <div className="Hero__ratingsStars__footer">
              <span>3,900+</span> {t("reviews")}
            </div>
          </div>
        </div>
        <p className="Hero__subtitle">
          {t("We're a private company providing passport assistance.")}
          <Tooltip
            position="left"
            effect="solid"
            event="click"
            tip={"hero"}
            icon="info"
            dataFor={`m-tooltip__hero`}
            content={`${!data.isLoadingSiteData ? data.siteData.web_name : ""} ${t(
              "is a private company unaffiliated with the U.S. Government. The service offers U.S. citizens a PDF editor and customer support to simplify the process of fulfilling passport applications for a fee. IMPORTANT: Passport applications must still be submitted through official U.S. Govt offices and/or websites. By paying for and using our private services you confirm acknowledgment of the above statements."
            )}`}
          />
        </p>
        <div className="Hero__links">
          {formData.map((formItem) => (
            <Button
              full
              short
              primaryAlt
              justifyBetween
              label={t(formItem.formTitle?.split("-")[0])}
              link={formItem.slug}
              rootLink={true}
              dataQa={`home-${formItem.name}`}
              svgSrc={"/svg/arrow_right_24px.svg"}
            />
          ))}
        </div>
      </div>
      <div className="Hero__image">
        <img
          alt="cover with form in different devices"
          src={hero}
        />
      </div>
    </div>
  );
};

export default Hero;
