import React, { useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../../components/Layout/layout";
import { amplitudeTrackEvent } from "../../components/utils/Amplitude";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Loading from "../../components/atoms/Loading";
import Seo from "../../components/utils/SEO";
import { sendPurchaseEvent } from "../../components/utils/dataLayer/events";
import { graphql } from "gatsby";
import { Title } from "../../components/atoms/Title/title";
import Button from "../../components/atoms/Button";
import { ReactSVG } from "react-svg";
import useGetApplicationSimple from "../../hooks/useGetApplicationSimple";
import { removeCookie } from "../../components/utils/cookies";
import thankyou from "/static/images/thankyou.png";
import { windowIsExist } from "../../components/utils";
import "./styles.scss";

const ThankYou = () => {
  const { t } = useTranslation();

  const { application, getApplicationData, loading } = useGetApplicationSimple();

  useEffect(() => {
    getApplicationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushDataLayer = (total, addons) => {
    if (windowIsExist) {
      sendPurchaseEvent(
        application.applicationName,
        application.applicationReference,
        addons,
        application.price,
        total
      );
    } else {
      pushDataLayer(total, addons);
    }
  };

  const cleanCookies = () => {
    removeCookie("address_token");
    removeCookie("pdfId");
    removeCookie("app_id");
    removeCookie("order_status");
  };

  useEffect(() => {
    if (loading === false) {
      pushDataLayer(application.price, application.addons);

      cleanCookies();

      // Keep this event here, in the same order after cleanCookies function,
      // otherwise it will not send the form data
      amplitudeTrackEvent("view_page_thankYou", {
        form: application.applicationName.toLowerCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const applicationTitle =
    application && !loading
      ? t(`${application.applicationNumber} - ${application.applicationName} Form`)
      : undefined;

  return (
    <Layout
      headerTitle={t(applicationTitle)}
      headerBigMaxWidth
      footerBigMaxWidth
      hideFooterNavigation={true}
      hideLanguageSelector={true}>
      <Seo title={t("Thank you for trusting us")} />
      <Wrapper
        large
        className="Thank-you">
        {loading ? (
          <Loading full />
        ) : (
          <>
            <div className="Thank-you__container">
              <div className="Thank-you__image">
                <img
                  src={thankyou}
                  alt={t("Your payment was successful!")}
                />
              </div>
              <div className="Thank-you__info">
                <span className="Thank-you__status">{t("Confirmed")}</span>
                <Title
                  regular
                  content={t("Your payment was successful!")}
                />
                <p>
                  {t(
                    "We're processing your application. Your PDF form will be emailed to you in the next few hours."
                  )}
                </p>
                <div className="Thank-you__box">
                  <span className="Thank-you__passport">{t(application.applicationNumber)}</span>
                  <span className="Thank-you__form">
                    {t("Form")}: {application.applicationName}
                  </span>
                  <div className="Thank-you__addons">
                    {application.addons.map((addon) => (
                      <span className="Thank-you__addon">
                        <ReactSVG
                          src={`/svg/check.svg`}
                          className="Thank-you__icon"
                          title="alert-icon"
                        />
                        {t(addon.title)}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="Thank-you__box Thank-you__box--success">
                  <span className="Thank-you__reference-title">
                    {t("Application Reference Code")}
                  </span>
                  <span className="Thank-you__reference">{application.applicationReference}</span>
                </div>
                <div className="Thank-you__link">
                  <Title
                    regular
                    content={t("Apply for another passport now!")}
                  />
                  <Button
                    label={t("Start a new application")}
                    dataQa="start-new-application"
                    primary
                    link="/"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

export default ThankYou;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
