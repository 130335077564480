import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { windowIsExist } from "../../components/utils";
import { amplitudeTrackEvent } from "../../components/utils/Amplitude";
import { useI18next } from "gatsby-plugin-react-i18next";
import HomeLayout from "./Layout";
import Steps from "../../components/molecules/Steps/AB/";
import usePrepareHomeData from "./hooks/usePrepareHomeData";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Hero from "../../components/organisms/hero";
import Slider from "../../components/organisms/Slider";
import OurBenefitsPlus from "../../components/molecules/OurBenefitsPlus";
import { childPassportCard } from "../../data/child-passport";
import "./styles.scss";

const HomeDefaultTemplate = ({ pageContext }) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const { homeData, formData, stepsData, jumbotronTitle, stepsHowItWorks } = usePrepareHomeData(
    pageContext[`${language}`].data
  );

  const updatedCards = (cards) => {
    const cardsArray = cards;
    if (cardsArray) {
      const childPassportCardId = "fbe3d439-9806-5b6f-96d3-3e0d48332492-2";
      const cardsHasChildPassportCard = cardsArray.find((card) => {
        return card.id === childPassportCardId;
      });
      const cardsHasNotChildPassportCard = cardsHasChildPassportCard === undefined;

      if (cardsHasNotChildPassportCard) {
        cardsArray.push(childPassportCard);
      }

      return cardsArray;
    }
  };

  const collapsedMobileTest = windowIsExist && window.innerWidth > 790 ? false : true;

  useEffect(() => {
    amplitudeTrackEvent("view_landing_home", {
      content: "landing-product",
    });
  }, []);

  const updatedCardsHome = updatedCards(formData.nodes);

  return (
    <HomeLayout
      plainHeader={true}
      containerType="large"
      collapsedMobileTest={collapsedMobileTest}>
      <section className="sectionAB sectionAB--white">
        <Wrapper large>
          <Hero
            title={jumbotronTitle}
            formData={updatedCardsHome}
          />
        </Wrapper>
        <Wrapper>
          <div className="StepsTest">
            <div className="StepsTest__item">
              <div className="StepsTest__small">{t("Over")}</div>
              <div className="StepsTest__bold">{t("700,000+")}</div>
              <div className="StepsTest__small">{t("passports processed")}</div>
            </div>
            <div className="StepsTest__item StepsTest__item--lines">
              <div className="StepsTest__bold">{t("600,000+")}</div>
              <div className="StepsTest__small">{t("satisfied clients")}</div>
            </div>
            <div className="StepsTest__item">
              <div className="StepsTest__bold">{t("1,000,000+")}</div>
              <div className="StepsTest__small">{t("hours saved")}</div>
            </div>
          </div>
        </Wrapper>
      </section>
      <Wrapper>
        <Slider formData={updatedCardsHome} />
      </Wrapper>
      <section className="sectionAB sectionAB--big sectionAB--white">
        <Wrapper>
          <OurBenefitsPlus
            data={homeData}
            stepsData={stepsData}
          />
        </Wrapper>
      </section>
      <section className="sectionAB sectionAB--big sectionAB--primary">
        <Wrapper>
          <Steps
            allowTranslations={true}
            title={t("How it works")}
            stepsArray={stepsHowItWorks}
          />
        </Wrapper>
      </section>
    </HomeLayout>
  );
};

export default HomeDefaultTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
