import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Title } from "../../../atoms/Title/title";
import { ReactSVG } from "react-svg";
import "./styles.scss";
import Button from "../../../atoms/Button";
import { scrollToRef } from "../../../Form/Pay/utilities";

const Steps = ({ title, stepsArray }) => {
  const { t } = useTranslation();

  return (
    <div className="StepsAB">
      {title && (
        <Title
          className="StepsAB__title"
          tag="h2"
          content={t(title)}
        />
      )}
      <div className="StepsAB__list">
        {stepsArray.map((item, index) => (
          <div
            className="StepsAB__item"
            key={`Step-item-${index}`}>
            <div className="StepsAB__media">
              <ReactSVG
                src={item.icon}
                className="StepsAB__icon"
                title={item.title}
              />
            </div>
            <div className="StepsAB__info">
              <div className="StepsAB__info__title">
                <span className="StepsAB__info__number">{index + 1}</span>
                {t(item.title)}
              </div>
              <p className="StepsAB__text">{t(item.text)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="u-align-center">
        <Button
          onClick={() => scrollToRef("Hero")}
          short
          long
          primary
          justifyBetween
          label={t("Get Started")}
          link={"#Hero"}
          svgSrc={"/svg/arrow_right_24px.svg"}
        />
      </div>
    </div>
  );
};

export default Steps;
