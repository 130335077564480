import React from "react";
import classNames from "classnames";
import "./styles.scss";

export const Title = (props) => {
  const classnames = classNames(`title`, {
    "title--hero": props.hero,
    "title--regular": props.regular,
    "title--medium": props.medium,
    "title--grand": props.grand,
    "title--icon": props.icon,
    [props.className]: props.className,
  });

  var img = null;
  if (props.icon) {
    img = (
      <img
        src={props.icon}
        alt=""
      />
    );
  }

  let Tag = props.tag ? props.tag : "div";

  return (
    <Tag
      data-testid="title"
      className={classnames}
      data-qa={props.dataQa}>
      {img}
      {props.content}
    </Tag>
  );
};
