import { getCookie } from "../components/utils/cookies";

const useGetLegalContent = (slug) => {
  const siteInfo = getCookie("site_info");
  const siteHasPhoneOrChat = siteInfo.hasPhone === true || siteInfo.hasChat === true;
  const isTermAndConditionPage = slug === "terms-and-conditions";
  const showContentForSitesWithPhoneOrChat = siteHasPhoneOrChat && isTermAndConditionPage;

  return showContentForSitesWithPhoneOrChat;
};

export default useGetLegalContent;
