import React, { useState } from "react";
import "./styles.scss";

export const CarouselItem = ({ children, width }) => {
  return (
    <div
      className="Carousel__item"
      style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex > React.Children.count(children) - 1) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  return (
    <div className="Carousel">
      <button
        onClick={() => {
          updateIndex(activeIndex - 1);
        }}
        className="Carousel__prev Carousel__button"
        value="prev"
        aria-label="previous">
        {" "}
        ‹{" "}
      </button>
      <div className="Carousel__content">
        <div
          className="Carousel__inner"
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: "100%" });
          })}
        </div>
        <div
          className="Carousel__nav"
          aria-label="carousel buttons"
          aria-controls="carousel">
          {React.Children.map(children, (child, index) => {
            return (
              <button
                className={`Carousel__navitems ${
                  index === activeIndex ? "Carousel__navactive" : ""
                } Carousel__button`}
                aria-label={`navitems-${index}`}
                onClick={() => {
                  updateIndex(index);
                }}></button>
            );
          })}
        </div>
      </div>
      <button
        onClick={() => {
          updateIndex(activeIndex + 1);
        }}
        className="Carousel__next Carousel__button"
        value="next"
        aria-label="next">
        {" "}
        ›{" "}
      </button>
    </div>
  );
};

export default Carousel;
