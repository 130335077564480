import Nmi from "./Nmi";
import Macropay from "./Macropay";
import CommerceGate from "./CommerceGate";
import H2h from "./H2h";
import Solidgate from "./Solidgate";

const gatewaysH2h = ["billapay", "vendo", "ingenico", "worldpay", "fake"];
const gatewaysNMI = ["magnify", "micamp", "merchant_one"];

export const isH2H = (gateway) => gatewaysH2h.includes(gateway);
export const isNmi = (gateway) => gatewaysNMI.includes(gateway);

const PSPs = {
  nmi: {
    Psp: Nmi,
    getProps: ({ gateway, payOrder, paymentGatewayDetails, fieldChecked, setState }) => ({
      gateway: gateway,
      show: isNmi(gateway),
      payOrder,
      paymentGatewayDetails,
      fieldChecked,
      setState: setState,
    }),
  },
  macropay: {
    Psp: Macropay,
    getProps: ({
      paymentGatewayDetails,
      setState,
      gateway,
      fieldChecked,
      loadIframeUrl,
      buttonDisabled,
    }) => ({
      gateway,
      show: gateway === "macropay",
      paymentGatewayDetails,
      setState,
      fieldChecked,
      loadIframeUrl,
      buttonDisabled,
    }),
  },
  commerce_gate: {
    Psp: CommerceGate,
    getProps: ({ fieldChecked, gateway, paymentGatewayDetails, application }) => ({
      show: fieldChecked && gateway === "commerce_gate",
      paymentGatewayDetails,
      application,
    }),
  },
  h2h: {
    Psp: H2h,
    getProps: ({ gateway, isSubmitting, fieldChecked, payOrder, setState }) => {
      return {
        show: fieldChecked && gatewaysH2h.includes(gateway),
        gateway,
        isSubmitting,
        payOrder,
        setState,
      };
    },
  },
  solid_gate: {
    Psp: Solidgate,
    getProps: ({ gateway, paymentGatewayDetails, setState }) => {
      return {
        show: gateway === "solid_gate",
        gateway,
        paymentGatewayDetails,
        setState,
      };
    },
  },
  unknown: {
    Psp: () => "Unknown Psp",
    getProps: () => ({ show: true }),
  },
};

export const getPSP = (gateway) => {
  let pspName = gateway;

  if (isH2H(gateway)) {
    pspName = "h2h";
  } else if (isNmi(gateway)) {
    pspName = "nmi";
  }

  return PSPs[pspName] || PSPs.unknown;
};

export default PSPs;
