import React, { useContext, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Button from "../../atoms/Button";
import RichText from "../RichText/richText";
import SiteDataContext from "../../../context/SiteDataContext";
import { ReactSVG } from "react-svg";
import { Title } from "../../atoms/Title/title";
import ds11 from "/static/images/DS11.jpg";
import ds82 from "/static/images/DS82.jpg";
import ds64 from "/static/images/DS64.jpg";
import ds5504 from "/static/images/ds5504.jpg";

import "./styles.scss";

const Slider = ({ formData }) => {
  const siteData = useContext(SiteDataContext);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(formData[0].id);

  const formImageIndex = {
    "DS-11": ds11,
    "DS-82": ds82,
    "DS-64": ds64,
    "DS-5504": ds5504,
  };

  return (
    <div className="Slider">
      <Title
        className="Slider__title"
        tag="h2"
        content={t("Get Your Passport")}
      />
      <div className="Slider__nav">
        {formData.map((formItem) => (
          <div
            role="presentation"
            onClick={() => setActiveTab(formItem.id)}
            onKeyDown={() => setActiveTab(formItem.id)}
            className={`Slider__navItem ${
              activeTab === formItem.id ? "Slider__navItem--active" : ""
            }`}>
            {t(formItem.formTitle?.split("-")[0])}
          </div>
        ))}
      </div>
      {formData.map((formItem) => (
        <div
          className={`Slider__card ${
            activeTab === formItem.id ? "Slider__card--visible" : "Slider__card--hidden"
          }`}>
          <div className="Slider__data">
            <div className="Slider__header">
              <ReactSVG
                className="Slider__headerLogo
               "
                src={`/svg/icon-form-${formItem.name.toLowerCase().replace("-", "")}.svg`}
                title={t(formItem.title)}
              />
              <div className="Slider__headerContainer">
                <h2 className="Slider__headerTitle">{t(formItem.formTitle?.split("-")[0])}</h2>
                <span className="Slider__headerSubtitle">{t(`${formItem.name} Form`)}</span>
              </div>
            </div>
            <RichText
              content={formItem.requeriments}
              siteData={siteData}
            />
            <Button
              short
              primaryAlt
              label={t(formItem.formButtonText)}
              link={formItem.slug}
              rootLink={true}
              dataQa={`home-${formItem.name}`}
              svgSrc={"/svg/arrow_right_24px.svg"}
            />
          </div>
          <div className="Slider__image">
            <img
              alt={`form ${formItem.name}`}
              src={
                formImageIndex[formItem.name]
                  ? formImageIndex[formItem.name]
                  : formImageIndex["DS-11"]
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Slider;
