const {
  buttonColor,
  buttonTextcolor,
  buttonBorderradius,
  buttonHovercolor,
  fontFamily,
  inputBorderradius,
  inputBordercolor,
} = require("./solidGate.module.scss");

const addImportant = (value) => `${value} !important`;

export const submit_button = {
  "margin-top": "1.5rem",
  "background-color": addImportant(buttonColor),
  "font-weight": addImportant(700),
  "font-size": addImportant("1rem"),
  color: addImportant(buttonTextcolor),
  "border-radius": addImportant(buttonBorderradius),

  ".title": {
    "letter-spacing": addImportant(0),
    display: addImportant("flex"),
    "align-items": addImportant("center"),
    "justify-content": addImportant("center"),
    ":before": {
      "margin-bottom": addImportant("4px"),
    },
  },

  "> span": {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },

  ":hover": {
    "background-color": addImportant(buttonHovercolor),
  },
};

export const form_body = {
  "font-family": addImportant(fontFamily),
  padding: addImportant("0 0.5rem"),
  overflow: "hidden",
};

export const input = {
  input: {
    "border-radius": addImportant(inputBorderradius),
    "font-size": addImportant("1rem"),
    transition: addImportant("box-shadow 0.3s ease"),

    ":hover": {
      "box-shadow": addImportant(`0 0 0 1px ${inputBordercolor}`),
    },
  },
  label: {
    "font-weight": addImportant(700),
    "font-size": addImportant("1rem"),
    "line-height": addImportant("1.5rem"),
  },
};

export const card_holder = {
  ...input,
  order: addImportant("1"),
};

const styles = {
  buttonColor,
  buttonTextcolor,
  buttonBorderradius,
  buttonHovercolor,
  fontFamily,
  inputBorderradius,
  inputBordercolor,
  input,
};

export default styles;
