import { useReducer } from "react";
import { getAge } from "../../../utils/dates";

const DS82 = "passport-renewal";
const DS5504 = "passport-corrections";

export const SHOW_UNDER_16_KEY_ACTION = "showUnder16Alert";
export const SHOW_OVER_15_KEY_ACTION = "showOver15Alert";
export const SHOW_PASSPORT_BOOK_OVER_1_KEY_ACTION = "showPassportBookOver1Alert";
export const SHOW_PASSPORT_CARD_OVER_1_KEY_ACTION = "showPassportCardOver1Alert";
export const SHOW_PASSPORT_BOOK_OVER_2_KEY_ACTION = "showPassportBookOver2Alert";
export const SHOW_PASSPORT_CARD_OVER_2_KEY_ACTION = "showPassportCardOver2Alert";
export const SHOW_PASSPORT_NOT_5504_KEY_ACTION = "showPassportNot5504Alert";

const initialConfigOver15Alert = {
  enabledForms: [DS82],
  validation: (date) => (date ? getAge(date.toISOString()) > 14 : false),
  type: SHOW_OVER_15_KEY_ACTION,
  state: {
    showOver15Alert: true,
  },
};

const initialConfigPassportBookOver1Alert = {
  enabledForms: [DS5504],
  validation: (value, name, form) => {
    if (!value) {
      return false;
    }
    let passportIssuanceDate = "";
    let nameChange = "";
    switch (name) {
      case "passportIssuanceDate":
        passportIssuanceDate = getAge(value.toISOString());
        nameChange = form["nameChange"];
        break;
      case "nameChange":
        passportIssuanceDate = getAge(form["passportIssuanceDate"]);
        nameChange = value;
        break;
      default:
        return {};
    }

    return passportIssuanceDate > 0 && nameChange === "yes" ? true : false;
  },
  type: SHOW_PASSPORT_BOOK_OVER_1_KEY_ACTION,
  state: {
    showPassportBookOver1Alert: true,
  },
};
const initialConfigPassportCardOver1Alert = {
  enabledForms: [DS5504],
  validation: (value, name, form) => {
    if (!value) {
      return false;
    }
    let cardIssuanceDate = "";
    let nameChange = "";
    switch (name) {
      case "cardIssuanceDate":
        cardIssuanceDate = getAge(value.toISOString());
        nameChange = form["nameChange"];
        break;
      case "nameChange":
        cardIssuanceDate = getAge(form["cardIssuanceDate"]);
        nameChange = value;
        break;
      default:
        return null;
    }
    return cardIssuanceDate > 0 && nameChange === "yes" ? true : false;
  },
  type: SHOW_PASSPORT_CARD_OVER_1_KEY_ACTION,
  state: {
    showPassportCardOver1Alert: true,
  },
};

const initialConfigPassportBookOver2Alert = {
  enabledForms: [DS5504],
  validation: (value, name, form) => {
    if (!value) {
      return false;
    }
    let passportIssuanceDate = "";
    let limitedValidityPassport = "";
    switch (name) {
      case "passportIssuanceDate":
        passportIssuanceDate = getAge(value.toISOString());
        limitedValidityPassport = form["limitedValidityPassport"];
        break;
      case "limitedValidityPassport":
        passportIssuanceDate = getAge(form["passportIssuanceDate"]);
        limitedValidityPassport = value;
        break;
      default:
        return null;
    }

    return passportIssuanceDate > 1 && limitedValidityPassport === "yes" ? true : false;
  },
  type: SHOW_PASSPORT_BOOK_OVER_2_KEY_ACTION,
  state: {
    showPassportBookOver2Alert: true,
  },
};
const initialConfigPassportCardOver2Alert = {
  enabledForms: [DS5504],
  validation: (value, name, form) => {
    if (!value) {
      return false;
    }
    let cardIssuanceDate = "";
    let limitedValidityPassport = "";
    switch (name) {
      case "cardIssuanceDate":
        cardIssuanceDate = getAge(value.toISOString());
        limitedValidityPassport = form["limitedValidityPassport"];
        break;
      case "limitedValidityPassport":
        cardIssuanceDate = getAge(form["cardIssuanceDate"]);
        limitedValidityPassport = value;
        break;
      default:
        return null;
    }

    return cardIssuanceDate > 1 && limitedValidityPassport === "yes" ? true : false;
  },
  type: SHOW_PASSPORT_CARD_OVER_2_KEY_ACTION,
  state: {
    showPassportCardOver2Alert: true,
  },
};

const initialConfigPassportNot5504Alert = {
  enabledForms: [DS5504],
  validation: (value, name, form) => {
    let nameChange = "";
    let incorrectPrintedInfo = "";
    let limitedValidityPassport = "";

    switch (name) {
      case "nameChange":
        nameChange = value;
        incorrectPrintedInfo = form["incorrectPrintedInfo"];
        limitedValidityPassport = form["limitedValidityPassport"];
        break;
      case "incorrectPrintedInfo":
        nameChange = form["nameChange"];
        incorrectPrintedInfo = value;
        limitedValidityPassport = form["limitedValidityPassport"];
        break;
      case "limitedValidityPassport":
        nameChange = form["nameChange"];
        incorrectPrintedInfo = form["incorrectPrintedInfo"];
        limitedValidityPassport = value;
        break;
      default:
        return null;
    }

    return nameChange === "no" && incorrectPrintedInfo === "no" && limitedValidityPassport === "no"
      ? true
      : false;
  },
  type: SHOW_PASSPORT_NOT_5504_KEY_ACTION,
  state: {
    showPassportNot5504Alert: true,
  },
};

const initialConfigUnder16Alert = {
  enabledForms: [DS82],
  validation: (date) => (date ? getAge(date.toISOString()) < 16 : false),
  type: SHOW_UNDER_16_KEY_ACTION,
  state: {
    showUnder16Alert: true,
  },
};

const alertsConfig = {
  birthDate: [
    {
      ...initialConfigUnder16Alert,
    },
  ],
  passportIssuanceDate: [
    {
      ...initialConfigOver15Alert,
    },
    {
      ...initialConfigPassportBookOver1Alert,
    },
    {
      ...initialConfigPassportBookOver2Alert,
    },
  ],
  cardIssuanceDate: [
    {
      ...initialConfigOver15Alert,
    },
    {
      ...initialConfigPassportCardOver1Alert,
    },
    {
      ...initialConfigPassportCardOver2Alert,
    },
  ],
  nameChange: [
    {
      ...initialConfigPassportBookOver1Alert,
    },
    {
      ...initialConfigPassportCardOver1Alert,
    },
    {
      ...initialConfigPassportNot5504Alert,
    },
  ],
  incorrectPrintedInfo: [
    {
      ...initialConfigPassportNot5504Alert,
    },
  ],
  limitedValidityPassport: [
    {
      ...initialConfigPassportBookOver2Alert,
    },
    {
      ...initialConfigPassportCardOver2Alert,
    },
    {
      ...initialConfigPassportNot5504Alert,
    },
  ],
};

const initialState = {
  showUnder16Alert: false,
  showOver15Alert: false,
  showPassportBookOver1Alert: false,
  showPassportCardOver1Alert: false,
  showPassportBookOver2Alert: false,
  showPassportCardOver2Alert: false,
  showPassportNot5504Alert: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SHOW_UNDER_16_KEY_ACTION:
      return { ...state, showUnder16Alert: action.payload.showUnder16Alert };
    case SHOW_OVER_15_KEY_ACTION:
      return { ...state, showOver15Alert: action.payload.showOver15Alert };
    case SHOW_PASSPORT_BOOK_OVER_1_KEY_ACTION:
      return { ...state, showPassportBookOver1Alert: action.payload.showPassportBookOver1Alert };
    case SHOW_PASSPORT_CARD_OVER_1_KEY_ACTION:
      return { ...state, showPassportCardOver1Alert: action.payload.showPassportCardOver1Alert };
    case SHOW_PASSPORT_BOOK_OVER_2_KEY_ACTION:
      return { ...state, showPassportBookOver2Alert: action.payload.showPassportBookOver2Alert };
    case SHOW_PASSPORT_CARD_OVER_2_KEY_ACTION:
      return { ...state, showPassportCardOver2Alert: action.payload.showPassportCardOver2Alert };
    case SHOW_PASSPORT_NOT_5504_KEY_ACTION:
      return { ...state, showPassportNot5504Alert: action.payload.showPassportNot5504Alert };
    default:
      throw state;
  }
};

const useHandleralertPopup = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showAlertPopup = ({ type, state }) => {
    dispatch({ type, payload: state });
  };

  const closeAlertPopup = (type) => {
    dispatch({ type, payload: initialState });
  };

  const verifyAlertsPopup = (event, formName, form) => {
    if (event && event.target) {
      const { name, value } = event.target;
      const alertConfig = alertsConfig[name];

      if (alertConfig) {
        for (var i = 0; i < alertConfig.length; i++) {
          const openAlert =
            alertConfig[i].enabledForms.includes(formName) &&
            alertConfig[i].validation(value, name, form);

          if (openAlert) {
            showAlertPopup(alertConfig[i]);
            break;
          }
        }
      }
    }
  };

  return {
    alertsPopup: state,
    showAlertPopup,
    closeAlertPopup,
    verifyAlertsPopup,
  };
};

export default useHandleralertPopup;
