import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import usePrepareData from "./hooks/usePrepareData";
import { ReactSVG } from "react-svg";
import SiteDataContext from "../../../context/SiteDataContext";
import RichText from "../../organisms/RichText/richText";
import Button from "../../atoms/Button";
import { scrollToRef } from "../../Form/Pay/utilities";
import(`./styles.scss`);

const OurBenefitsPlus = ({
  data,
  stepsData,
  allowTranslations,
  showHeader = true,
  hasRichtext,
}) => {
  const { t } = useTranslation();
  const { title, benefits } = usePrepareData(data);
  const siteData = useContext(SiteDataContext);

  return (
    <div className="our-benefits-plus">
      <div className="our-benefits-plus__wrapper">
        <h2>{title}</h2>
        <div className={`our-benefits-plus__container`}>
          <ul className={`our-benefits-plus__list`}>
            {Object.keys(benefits).map((key) => {
              const benefit = benefits[key];
              return (
                <li
                  key={key}
                  className={`our-benefits-plus__item`}>
                  <ReactSVG
                    className={`our-benefits-plus__icon`}
                    src={showHeader ? benefit.icon : benefit.simpleIcon}
                  />
                  {hasRichtext ? (
                    <RichText
                      content={benefit.value}
                      customClass={`medium our-benefits-plus__richtext`}
                      siteData={siteData}
                    />
                  ) : (
                    <p className={`medium`}>{t(benefit.value)}</p>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="our-benefits-plus__steps">
            {stepsData.edges.map((item, index) => {
              return (
                <li
                  key={`Step-${index}`}
                  className={`our-benefits-plus__step our-benefits-plus__step--${
                    index < 2 ? "half" : "full"
                  } ${index === 1 ? "our-benefits-plus__step--accent" : ""}`}>
                  <RichText
                    allowTranslations={allowTranslations}
                    content={item.node.stepSvg}
                    siteData={siteData}
                  />
                </li>
              );
            })}
          </div>
        </div>
      </div>
      <div className="u-align-center">
        <Button
          onClick={() => scrollToRef("Hero")}
          short
          long
          primary
          justifyBetween
          label={t("Get Started")}
          link={"#Hero"}
          svgSrc={"/svg/arrow_right_24px.svg"}
        />
      </div>
    </div>
  );
};
export default OurBenefitsPlus;
