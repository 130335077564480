import React, { useMemo } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";
import OurBenefits from "../../components/Form/OurBenefits";
import Steps from "../../components/Form/Steps";
import { ourBenefitsData, stepsData, cardsPassportData } from "../../data/fill-now";
import CardsPassport from "../../components/molecules/CardsPassport";
import { graphql } from "gatsby";
import { buildPassportCardsData } from "../../components/utils/landingPages";
import { sendViewItemListEvent } from "../../components/utils/dataLayer/events";
import * as styles from "./fill-now.styles.module.scss";
import useSendDatalayer from "../../hooks/useSendDatalayer";

const HomeFillNowTemplate = ({ data }) => {
  const { t } = useTranslation();

  const parsedCardsPassportData = useMemo(() => {
    const cards = cardsPassportData("default");
    return buildPassportCardsData(cards, data.allContentfulFormType.nodes);
  }, [data.allContentfulFormType.nodes]);

  useSendDatalayer(sendViewItemListEvent, ["DS-5504", "DS-64", "DS-11", "DS-82"]);

  return (
    <Layout>
      <Wrapper marginTop>
        <Title
          hero
          tag="h1"
          content={t("Quick and Easy Passport Application Forms")}
        />
        <CardsPassport
          cards={parsedCardsPassportData}
          className={styles.cards}
          isCompact
        />
      </Wrapper>

      <OurBenefits data={ourBenefitsData} />
      <Wrapper>
        <Steps
          data={stepsData}
          allowTranslations={true}
        />
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulFormType(filter: { show: { eq: true }, node_locale: { eq: $language } }) {
      nodes {
        slug
      }
    }
  }
`;

export default HomeFillNowTemplate;
