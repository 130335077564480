import React from "react";
import classNames from "classnames";
import "./wrapper.scss";

const Wrapper = (props) => {
  const classnames = classNames(`wrapper`, {
    // size
    [props.className]: props.className,
    [props.classNames]: props.classNames,
    "wrapper--collapse": props.collapse,
    "wrapper--flex": props.flex,
    "wrapper--flexDesktop": props.flexDesktop,
    "wrapper--small": props.small,
    "wrapper--large": props.large,
    "wrapper--compact": props.compact,
    "wrapper--center": props.center,
    "wrapper--marginTop": props.marginTop,
    "wrapper--paddingTop": props.paddingTop,
    "wrapper--twoCols": props.twoCols,
    "wrapper--isCollapsedMobile": props.isCollapsedMobile,
    "wrapper--rowReverse": props.rowReverse,
  });
  return (
    <>
      <div
        id={props.id}
        key={props.id}
        className={classnames}>
        {props.children}
      </div>
    </>
  );
};

export default Wrapper;
