import { useMemo } from "react";

const useStyles = (template) => {
  const styles = useMemo(
    () => require(`../OurBenefits.${template || "default"}/styles.module.scss`),
    [template]
  );

  return {
    styles,
  };
};

export default useStyles;
