import React, { useState, useEffect } from "react";
import Check from "../../molecules/Check";
import { header, addon__body, addon__body__price, addon__footer } from "./addonsfields.module.scss";
import { sendRemoveFromCartEvent } from "../../utils/dataLayer/events";
import { useTranslation } from "gatsby-plugin-react-i18next";

const AddonsField = ({ label, hideDiscountedAddon, pdfName, ...props }) => {
  const { t } = useTranslation();
  const [addons, setAddons] = useState([]);

  const handleOnClick = (identifier, ev) => {
    const isChecked = +ev.target.value === 0 ? "1" : "0";

    const index = addons.findIndex((item) => item.identifier === identifier);

    props.addons[index] = {
      ...props.addons[index],
      value: isChecked,
    };

    setAddons(props.addons);
    addOnlySelectedAddonsInForm(props.addons);

    // send dataLayer remove from cart event
    if (isChecked === "0") {
      sendRemoveFromCartEvent(props.addons[index]);
    }
  };

  const addOnlySelectedAddonsInForm = (addons) => {
    props.value = addons.filter((item) => item);
    props.onChange({ target: props });
  };

  useEffect(() => {
    if (Array.isArray(props.value)) {
      props.value.forEach((addon) => {
        const index = props.addons.findIndex((item) => item.identifier === addon.identifier);
        props.addons[index] = addon;
      });

      setAddons(props.addons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addons]);

  let sortedAddons = addons.sort((p1, p2) => {
    return p1.order > p2.order ? 1 : p1.order < p2.order ? -1 : 0;
  });

  return addons.length > 0 ? (
    <>
      <h2 className={header}> {t(label)} </h2>

      {sortedAddons.map((addon) => {
        if (addon.identifier === "discount") return false;
        return (
          <div key={addon.identifier}>
            <div className={addon__body}>
              <Check
                label={t(addon.title)}
                name={addon.name}
                onChange={handleOnClick.bind(this, addon.identifier)}
                value={addon.value}
                {...addon}
              />
              <strong className={addon__body__price}>{addon.price}</strong>
            </div>

            <p className={addon__footer}>{t(addon.description)}</p>
          </div>
        );
      })}
    </>
  ) : null;
};

export default AddonsField;
