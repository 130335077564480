import { DataStore } from "./DataStore";
import { cookiesConfig } from "./cookiesConfig";

export class EncodedCookieDataStore extends DataStore {
  constructor({ cookieName, encoderService, decoderService }) {
    super({ data: cookieName });
    this.encoderService = encoderService;
    this.decoderService = decoderService;
  }

  read(key) {
    return this._getCookie()[key];
  }

  write({ key, data }) {
    if (typeof document !== "undefined") {
      const cookie = this._getCookie();

      cookie[key] = data;

      const decodedCookieValue = JSON.stringify(cookie);
      const encodedCookieValue = this.encoderService.encode(decodedCookieValue);

      const cookieName = this._data;
      const config = cookiesConfig.find((config) => config.keys.includes(cookieName));
      let expires = "";

      if (config) {
        const timeToexpire = new Date(Date.now() + config.expires).toUTCString();
        expires = `expires=${timeToexpire};`;
      }

      document.cookie = `${this._data}=${encodedCookieValue};${expires}path=/`;
    }
  }

  _getCookie() {
    if (typeof document !== "undefined") {
      const cookieName = this._data;
      const value = "; " + document.cookie;
      const parts = value.split("; " + cookieName + "=");
      if (parts.length === 2) {
        const encodedCookieValue = parts.pop().split(";").shift();
        const decodedCookieValue = this.decoderService.decode(encodedCookieValue);
        return JSON.parse(decodedCookieValue);
      }
    }
    return {};
  }
}
