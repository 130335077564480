import useSWR from "swr";

let fetcher = (url) => fetch(url).then((res) => res.text());

const svgToFetch = [
  "footer_name_company_1",
  "footer_vat",
  "footer_direction",
  "company_name",
  "company_vat",
  "address_1",
  "registry_city",
];

const useGetSiteSvgs = (typography, company, companies, companiesWords, avoidSvgFetchForSites) => {
  const svgObject = {};

  if (avoidSvgFetchForSites && companies) {
    for (const company of companies) {
      for (const svg of svgToFetch) {
        const dynamicKey = `${svg}_${companiesWords[company]?.company_name.replace(/ /g, "_")}`;
        svgObject[dynamicKey] = companiesWords[company][svg];
      }
    }
  }

  fetcher = !avoidSvgFetchForSites ? fetcher : false;

  //Company svgs
  const { data: footer_name_company_1, isLoading: isLoadingFooterNameCompany } = useSWR(
    company ? `/assets/company/${typography}/${company}/footer_name_company_1.svg` : null,
    fetcher
  );

  const { data: footer_vat, isLoading: isLoadingFooterVat } = useSWR(
    company ? `/assets/company/${typography}/${company}/footer_vat.svg` : null,
    fetcher
  );

  const { data: footer_direction, isLoading: isLoadingFooterDirection } = useSWR(
    company ? `/assets/company/${typography}/${company}/footer_direction.svg` : null,
    fetcher
  );

  const { data: company_name, isLoading: isLoadingCompanyName } = useSWR(
    company ? `/assets/company/${typography}/${company}/company_name.svg` : null,
    fetcher
  );

  const { data: company_vat, isLoading: isLoadingCompanyVat } = useSWR(
    company ? `/assets/company/${typography}/${company}/company_vat.svg` : null,
    fetcher
  );

  const { data: address_1, isLoading: isLoadingAddress1 } = useSWR(
    company ? `/assets/company/${typography}/${company}/address_1.svg` : null,
    fetcher
  );

  const { data: registry_city, isLoading: isLoadingRegistryCity } = useSWR(
    company ? `/assets/company/${typography}/${company}/registry_city.svg` : null,
    fetcher
  );

  const {
    data: company_name_Worldnet_Documents_SL,
    isLoading: isLoadingCompanyName_Worldnet_Documents_SL,
  } = useSWR(
    company ? `/assets/company/${typography}/Worldnet-Documents-SL/company_name.svg` : null,
    fetcher
  );

  const {
    data: company_vat_Worldnet_Documents_SL,
    isLoading: isLoadingCompanyVat_Worldnet_Documents_SL,
  } = useSWR(
    company ? `/assets/company/${typography}/Worldnet-Documents-SL/company_vat.svg` : null,
    fetcher
  );

  const {
    data: company_name_Tangula_Expert_SL,
    isLoading: isLoadingCompanyName_Tangula_Expert_SL,
  } = useSWR(
    company ? `/assets/company/${typography}/Tangula-Expert-SL/company_name.svg` : null,
    fetcher
  );

  const { data: company_vat_Tangula_Expert_SL, isLoading: isLoadingCompanyVat_Tangula_Expert_SL } =
    useSWR(
      company ? `/assets/company/${typography}/Tangula-Expert-SL/company_vat.svg` : null,
      fetcher
    );

  const { data: company_name_Scene_Strokes_SL, isLoading: isLoadingCompanyName_Scene_Strokes_SL } =
    useSWR(
      company ? `/assets/company/${typography}/Scene-Strokes-SL/company_name.svg` : null,
      fetcher
    );

  const { data: company_vat_Scene_Strokes_SL, isLoading: isLoadingCompanyVat_Scene_Strokes_SL } =
    useSWR(
      company ? `/assets/company/${typography}/Scene-Strokes-SL/company_vat.svg` : null,
      fetcher
    );

  const {
    data: company_name_Saovine_Business_SL,
    isLoading: isLoadingCompanyName_Saovine_Business_SL,
  } = useSWR(
    company ? `/assets/company/${typography}/Saovine-Business-SL/company_name.svg` : null,
    fetcher
  );

  const {
    data: company_vat_Saovine_Business_SL,
    isLoading: isLoadingCompanyVat_Saovine_Business_SL,
  } = useSWR(
    company ? `/assets/company/${typography}/Saovine-Business-SL/company_vat.svg` : null,
    fetcher
  );

  const { data: company_name_Mursin_5000_SL, isLoading: isLoadingCompanyName_Mursin_5000_SL } =
    useSWR(
      company ? `/assets/company/${typography}/Mursin-5000-SL/company_name.svg` : null,
      fetcher
    );

  const { data: company_vat_Mursin_5000_SL, isLoading: isLoadingCompanyVat_Mursin_5000_SL } =
    useSWR(
      company ? `/assets/company/${typography}/Mursin-5000-SL/company_vat.svg` : null,
      fetcher
    );

  const { data: company_name_Form_Genius_LLC, isLoading: isLoadingCompanyName_Form_Genius_LLC } =
    useSWR(
      company ? `/assets/company/${typography}/Form-Genius-LLC/company_name.svg` : null,
      fetcher
    );

  const { data: company_vat_Form_Genius_LLC, isLoading: isLoadingCompanyVat_Form_Genius_LLC } =
    useSWR(
      company ? `/assets/company/${typography}/Form-Genius-LLC/company_vat.svg` : null,
      fetcher
    );

  const {
    data: company_name_Menturis_Solution_SL,
    isLoading: isLoadingCompanyName_Menturis_Solution_SL,
  } = useSWR(
    company ? `/assets/company/${typography}/Menturis-Solution-SL/company_name.svg` : null,
    fetcher
  );

  const {
    data: company_vat_Menturis_Solution_SL,
    isLoading: isLoadingCompanyVat_Menturis_Solution_SL,
  } = useSWR(
    company ? `/assets/company/${typography}/Menturis-Solution-SL/company_vat.svg` : null,
    fetcher
  );

  const { data: company_name_Nipi_Trade_SL, isLoading: isLoadingCompanyName_Nipi_Trade_SL } =
    useSWR(
      company ? `/assets/company/${typography}/Nipi-Trade-SL/company_name.svg` : null,
      fetcher
    );

  const { data: company_vat_Nipi_Trade_SL, isLoading: isLoadingCompanyVat_Nipi_Trade_SL } = useSWR(
    company ? `/assets/company/${typography}/Nipi-Trade-SL/company_vat.svg` : null,
    fetcher
  );

  const {
    data: company_name_Macro_Trade_Op_Services_LLC,
    isLoading: isLoadingCompanyName_Macro_Trade_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Macro-Trade-Op-Services-LLC/company_name.svg` : null,
    fetcher
  );

  const {
    data: company_vat_Macro_Trade_Op_Services_LLC,
    isLoading: isLoadingCompanyVat_Macro_Trade_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Macro-Trade-Op-Services-LLC/company_vat.svg` : null,
    fetcher
  );

  const {
    data: company_name_Holding_Gc_Op_Services_LLC,
    isLoading: isLoadingCompanyName_Holding_Gc_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Holding-Gc-Op-Services-LLC/company_name.svg` : null,
    fetcher
  );

  const {
    data: company_vat_Holding_Gc_Op_Services_LLC,
    isLoading: isLoadingCompanyVat_Holding_Gc_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Holding-Gc-Op-Services-LLC/company_vat.svg` : null,
    fetcher
  );

  const {
    data: company_name_Premium_Op_Services_LLC,
    isLoading: isLoadingCompanyName_Premium_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Premium-Op-Services-LLC/company_name.svg` : null,
    fetcher
  );

  const {
    data: company_vat_Premium_Op_Services_LLC,
    isLoading: isLoadingCompanyVat_Premium_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Premium-Op-Services-LLC/company_vat.svg` : null,
    fetcher
  );

  const {
    data: company_name_Inter_Op_Services_LLC,
    isLoading: isLoadingCompanyName_Inter_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Inter-Op-Services-LLC/company_name.svg` : null,
    fetcher
  );

  const {
    data: company_vat_Inter_Op_Services_LLC,
    isLoading: isLoadingCompanyVat_Inter_Op_Services_LLC,
  } = useSWR(
    company ? `/assets/company/${typography}/Inter-Op-Services-LLC/company_vat.svg` : null,
    fetcher
  );

  const isLoading =
    isLoadingFooterNameCompany ||
    isLoadingFooterVat ||
    isLoadingFooterDirection ||
    isLoadingCompanyName ||
    isLoadingCompanyVat ||
    isLoadingAddress1 ||
    isLoadingRegistryCity ||
    isLoadingCompanyName_Worldnet_Documents_SL ||
    isLoadingCompanyVat_Worldnet_Documents_SL ||
    isLoadingCompanyName_Tangula_Expert_SL ||
    isLoadingCompanyVat_Tangula_Expert_SL ||
    isLoadingCompanyName_Scene_Strokes_SL ||
    isLoadingCompanyVat_Scene_Strokes_SL ||
    isLoadingCompanyName_Saovine_Business_SL ||
    isLoadingCompanyVat_Saovine_Business_SL ||
    isLoadingCompanyName_Mursin_5000_SL ||
    isLoadingCompanyVat_Mursin_5000_SL ||
    isLoadingCompanyName_Form_Genius_LLC ||
    isLoadingCompanyVat_Form_Genius_LLC ||
    isLoadingCompanyName_Menturis_Solution_SL ||
    isLoadingCompanyVat_Menturis_Solution_SL ||
    isLoadingCompanyName_Nipi_Trade_SL ||
    isLoadingCompanyVat_Nipi_Trade_SL ||
    isLoadingCompanyName_Macro_Trade_Op_Services_LLC ||
    isLoadingCompanyVat_Macro_Trade_Op_Services_LLC ||
    isLoadingCompanyName_Holding_Gc_Op_Services_LLC ||
    isLoadingCompanyVat_Holding_Gc_Op_Services_LLC ||
    isLoadingCompanyName_Premium_Op_Services_LLC ||
    isLoadingCompanyVat_Premium_Op_Services_LLC ||
    isLoadingCompanyName_Inter_Op_Services_LLC ||
    isLoadingCompanyVat_Inter_Op_Services_LLC;

  const dataFromSiteSvgorText = !avoidSvgFetchForSites
    ? {
        footer_name_company_1,
        footer_vat,
        footer_direction,
        company_name,
        company_vat,
        address_1,
        registry_city,
        company_name_Worldnet_Documents_SL,
        company_vat_Worldnet_Documents_SL,
        company_name_Tangula_Expert_SL,
        company_vat_Tangula_Expert_SL,
        company_name_Scene_Strokes_SL,
        company_vat_Scene_Strokes_SL,
        company_name_Saovine_Business_SL,
        company_vat_Saovine_Business_SL,
        company_name_Mursin_5000_SL,
        company_vat_Mursin_5000_SL,
        company_name_Form_Genius_LLC,
        company_vat_Form_Genius_LLC,
        company_name_Menturis_Solution_SL,
        company_vat_Menturis_Solution_SL,
        company_name_Nipi_Trade_SL,
        company_vat_Nipi_Trade_SL,
        company_name_Macro_Trade_Op_Services_LLC,
        company_vat_Macro_Trade_Op_Services_LLC,
        company_name_Holding_Gc_Op_Services_LLC,
        company_vat_Holding_Gc_Op_Services_LLC,
        company_name_Premium_Op_Services_LLC,
        company_vat_Premium_Op_Services_LLC,
        company_name_Inter_Op_Services_LLC,
        company_vat_Inter_Op_Services_LLC,
      }
    : {
        ...svgObject,
      };

  return {
    data: {
      ...dataFromSiteSvgorText,
    },
    isLoading,
  };
};

export default useGetSiteSvgs;
