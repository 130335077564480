import React from "react";
import { dots__one, dots__two, dots__three } from "./dotsLoader.module.scss";

const DotsLoader = () => (
  <span className="dots">
    <span className={dots__one}>.</span>
    <span className={dots__two}>.</span>
    <span className={dots__three}>.</span>
  </span>
);

export default DotsLoader;
