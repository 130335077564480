import { windowIsExist } from "../../../components/utils/index";

export const getApiPrefix = () => {
  let apiPrefix = process.env.API_PREFIX || "";

  if (windowIsExist && process.env.GATSBY_ACTIVE_ENV === "prod") {
    const domain = window.location.host.replace("www.", "");
    apiPrefix = `https://api.${domain}/api`;
  } else if (windowIsExist && process.env.GATSBY_ACTIVE_ENV === "local") {
    apiPrefix = `https://local-api.usa-passport.org/api`;
  }

  return apiPrefix;
};
