import React, { useMemo, useCallback } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import classnames from "classnames";
import useGetStepNames from "./hooks/useGetStepNames";
import "./nav.scss";

const Nav = ({
  stepNames = [],
  customStepNames = [],
  classNames,
  notNavigable = true,
  currentStep,
  navigateTo,
  desktopOnly,
}) => {
  const { t } = useTranslation();
  const { concatenatedStepNames } = useGetStepNames({ stepNames, customStepNames });

  const isNotNavigable = useMemo(() => {
    if (typeof notNavigable === undefined) {
      return typeof navigateTo !== "function";
    }
    return notNavigable;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notNavigable]);

  const handleNavigateTo = useCallback(
    (stepIndex) => {
      const isReviewStep = concatenatedStepNames[stepIndex - 1] === "Review Application";

      if (!isNotNavigable && !isReviewStep) {
        navigateTo(stepIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigateTo, isNotNavigable]
  );

  const navClassname = classnames(`nav`, {
    "nav--notNavigable": isNotNavigable,
    "nav--desktopOnly": desktopOnly,
  });

  const dots = useMemo(() => {
    let data = [];

    concatenatedStepNames.forEach((name, index) => {
      const indexToShow = index + 1;

      const isActive = currentStep + 1 === indexToShow;
      const isCompleted = currentStep + 1 > indexToShow;

      const stepClassname = classnames(`nav__step`, {
        "nav__step--active": isActive,
        "nav__step--completed": isCompleted,
      });

      data.push(
        <div
          className={stepClassname}
          onClick={handleNavigateTo.bind(this, indexToShow)}
          key={`nav__step--${indexToShow}`}
          onKeyDown={handleNavigateTo.bind(this, indexToShow)}
          role="button"
          tabIndex={0}
          aria-label="Steps navigation">
          <span className={`nav__dot`}>{`${isCompleted ? "" : indexToShow}`}</span>
          <span
            className={`nav__info small`}
            dangerouslySetInnerHTML={{ __html: t(name) }}></span>
        </div>
      );
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, concatenatedStepNames, classNames, handleNavigateTo]);

  return <div className={navClassname}>{dots}</div>;
};

Nav.propTypes = {
  stepNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  customStepNames: PropTypes.arrayOf(PropTypes.string),
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  navigateTo: PropTypes.func,
  notNavigable: PropTypes.bool,
};

export default Nav;
