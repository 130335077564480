import { toCammelCase } from "../components/utils";

const usePrepareAmplitudeData = (currentStep, formStep, formType, source) => {
  const formStepName = toCammelCase(formStep);
  const eventName = `view_form_step_${formStepName}`;

  let eventOptions = {
    form: formType,
  };

  if (currentStep === 0) {
    eventOptions = {
      source: source,
      ...eventOptions,
    };
  }

  return {
    eventName,
    eventOptions,
  };
};

export default usePrepareAmplitudeData;
