import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import Nav from "../../atoms/Nav";
import StepWizardContext from "../../../context/StepWizardContext";
import FormContext from "../../../context/FormContext";

const NavStepForm = ({ stepNames, notNavigable }) => {
  const { navigateTo, currentStep, checkStepsCompleted } = useContext(StepWizardContext);
  const { formValidator } = useContext(FormContext);

  const handleNavigateTo = useCallback(
    (index) => {
      const isValid = checkStepsCompleted(index, formValidator);

      if (isValid) {
        navigateTo(index - 1);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValidator]
  );

  return (
    <Nav
      stepNames={stepNames}
      customStepNames={["Review Application"]}
      notNavigable={notNavigable}
      currentStep={currentStep}
      navigateTo={handleNavigateTo}
    />
  );
};

NavStepForm.propTypes = {
  stepNames: PropTypes.array.isRequired,
  notNavigable: PropTypes.bool,
};

export default NavStepForm;
