import React, { useMemo } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import Alert from "../../atoms/Alert";

const SimpleMessage = ({ className, content }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: content }}></div>
);

const CustomAlert = ({ className, content, showIcon }) => (
  <Alert
    className={className}
    type="warning"
    showLeftIcon={showIcon}
    content={content}
  />
);

const TypeMessage = {
  error: SimpleMessage,
  helper: SimpleMessage,
  disclaimerBeforeField: CustomAlert,
  disclaimerAfterField: CustomAlert,
  disclaimerAlert: CustomAlert,
};

const MessageComponent = ({ content, type, showIcon }) => {
  const className = classNames({
    "e-text-error": type === "error",
    "e-text-helper": type === "helper",
    "formField-disclaimer formField-disclaimer--before-field": type === "disclaimerBeforeField",
    "formField-disclaimer formField-disclaimer--after-field": type === "disclaimerAfterField",
    "formField-disclaimer formField-disclaimer--alert": type === "disclaimerAlert",
  });

  const Message = useMemo(() => TypeMessage[type], [type]);

  return typeof content === "string" && content.length > 0 ? (
    <Message
      className={className}
      content={content}
      showIcon={showIcon}
    />
  ) : null;
};

MessageComponent.propTypes = {
  type: propTypes.oneOf([
    "error",
    "helper",
    "disclaimerBeforeField",
    "disclaimerAfterField",
    "disclaimerAlert",
  ]),
  content: propTypes.oneOfType([propTypes.string]),
};

export default MessageComponent;
