import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../Layout/Wrapper/wrapper";
import StepWizard from "../../molecules/StepWizard";
import Form from "../Form";
import NavStepForm from "./NavStepForm";
import { ContactSummary } from "../ContactSummary";
import FormSummary from "../FormSummary";
import(`./styles.scss`);

const FormSteps = ({
  structure,
  stepNames,
  isFormToEdit,
  formName,
  formType,
  source,
  orderCookie,
  formDetails,
  form,
}) => {
  const [setAddons] = useState(
    form.addons && Array.isArray(form.addons.value) ? form.addons.value : [],
    [form.addons]
  );

  return (
    <StepWizard steps={structure.steps}>
      <Wrapper twoCols>
        <NavStepForm
          stepNames={stepNames}
          notNavigable={isFormToEdit}
        />
        <Form
          addons={formDetails.addons}
          formName={formName}
          formType={formType}
          formDescription={formDetails.pdf.description}
          formPrice={formDetails.price}
          source={source}
          isFormToEdit={isFormToEdit}
          orderCookie={orderCookie}
          structure={structure}
          updateAddons={setAddons}
        />
        <div className="formStep__summary">
          <FormSummary 
            pdf={formDetails.pdf}
          />
          <ContactSummary form />
        </div>
      </Wrapper>
    </StepWizard>
  );
};

FormSteps.propTypes = {
  structure: PropTypes.object.isRequired,
  stepNames: PropTypes.array.isRequired,
  formName: PropTypes.string.isRequired,
  orderCookie: PropTypes.object.isRequired,
  formDetails: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isFormToEdit: PropTypes.bool,
};

export default FormSteps;
