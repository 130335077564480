export class LoggerService {
  /* eslint-disable no-console */
  static debug(...args) {
    console.log(...args);
  }

  static log(...args) {
    console.log(...args);
  }

  static warn(...args) {
    console.warn(...args);
  }

  static error(...args) {
    console.error(...args);
  }
  /* eslint-enable no-console */
}
