import { useState, useMemo, useCallback } from "react";
import { GetApplicationNameHandler } from "../services/api/GetApplicationName/GetApplicationNameHandler";
import { getCookie, setCookies } from "../components/utils/cookies";
import { getParamInUrl, getUrlParams, windowIsExist } from "../components/utils";
import { navigate } from "gatsby";
import { buildAddons, calculateTotal } from "../components/utils/addons";
import { GetOrderHandler } from "../services/api/GetOrder/GetOrderHandler";
import { GetSiteInfoHandler } from "../services/api/GetSiteInfo/GetSiteInfoHandler";

const useGetApplicationSimple = () => {
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState();

  const redirectTo = () => {
    if (windowIsExist) {
      navigate("/home");
    }
  };

  const applicationId = useMemo(() => {
    const { order, pdf, tag } = getUrlParams();
    const orderCookie = getCookie("address_token");

    if (order && pdf) {
      setCookies("address_token", {
        ...orderCookie,
        order: order,
        applicationId: order,
        pdfId: pdf,
      });
    }

    if (tag) {
      setCookies("tag_param", { tag: tag });
    }

    const param_id = getParamInUrl("id");
    const param_order = getParamInUrl("order");

    if (
      typeof param_id === "undefined" &&
      typeof param_order === "undefined" &&
      typeof orderCookie.order === "undefined"
    ) {
      redirectTo();
    }

    return param_id || param_order || orderCookie.order;
  }, []);

  const getApplicationName = () => {
    return new Promise(async (resolve, reject) => {
      const applicationNameHandler = new GetApplicationNameHandler({});
      let data = {
        reference: applicationId,
      };
      try {
        const response = await applicationNameHandler.customAction(data);

        return resolve(response);
      } catch (error) {
        redirectTo();
        throw reject(error);
      }
    });
  };

  const getApplicationPrice = () => {
    return new Promise(async (resolve, reject) => {
      const applicationPaymentDetailsHandler = new GetSiteInfoHandler({});

      try {
        const { webPrice } = await applicationPaymentDetailsHandler.customAction({});

        resolve(webPrice);
      } catch (error) {
        redirectTo();
        throw reject(error);
      }
    });
  };

  const getFormAddons = () => {
    return new Promise(async (resolve, reject) => {
      const orderHandler = new GetOrderHandler({});

      const handleBuildAddons = (addons) => {
        const buildedAddons = buildAddons(addons);
        return buildedAddons;
      };

      try {
        const response = await orderHandler.customAction({
          applicationId: applicationId,
        });

        if (response.form.addons && Array.isArray(response.form.addons)) {
          response.form.addons = handleBuildAddons(response.form.addons);
        } else {
          response.form.addons = [];
        }

        return resolve(response.form.addons);
      } catch (error) {
        redirectTo();
        throw reject(error);
      }
    });
  };

  const getApplicationData = async () => {
    try {
      const response = await getApplicationName();
      const addons = await getFormAddons();
      const price = await getApplicationPrice();

      setApplication({
        ...application,
        applicationId,
        applicationName: response.application.formName,
        applicationNumber: response.application.formDescription,
        applicationReference: response.application.reference,
        addons: addons,
        applicationPrice: price,
        totalPrice: calculateTotal(price, addons),
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  return {
    applicationId,
    application,
    loading,
    setApplication,
    setLoading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getApplicationData: useCallback(getApplicationData, [application, applicationId]),
  };
};

export default useGetApplicationSimple;
