import React, { useEffect, useRef } from "react";
import PropsTypes from "prop-types";
import Modal from "../../atoms/Modal";
import classNames from "classnames";
import * as styles from "./popup.module.scss";

const PopupComponent = ({ children, className, onClickOutside, onKeypressEsc, onLoad }) => {
  const conteinerRef = useRef(null);
  const classnames = {
    modal: classNames(styles.modal),
    popup: classNames(styles.popup, {
      [className]: className,
    }),
  };

  const handleClickOutside = (event) => {
    if (
      typeof onClickOutside === "function" &&
      conteinerRef.current &&
      !conteinerRef.current.contains(event.target)
    ) {
      onClickOutside();
    }
  };

  const handleEsc = (event) => {
    if (typeof onKeypressEsc === "function" && event.keyCode === 27) {
      onKeypressEsc();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keyup", handleEsc);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keyup", handleEsc);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof onLoad === "function") {
      onLoad();
    }
  }, [onLoad]);

  return (
    <Modal className={classnames.modal}>
      <div
        ref={conteinerRef}
        className={classnames.popup}>
        {children}
      </div>
    </Modal>
  );
};

PopupComponent.propsTypes = {
  children: PropsTypes.element.isRequired,
  className: PropsTypes.string,
  onClickOutside: PropsTypes.func,
  onKeypressEsc: PropsTypes.func,
};

export default PopupComponent;
