import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Title } from "../../atoms/Title/title";

import "./styles.scss";

const List = ({ title, copy, list, icon, button, siteName }) => {
  const { t } = useTranslation();

  return (
    <div className="List">
      {title && (
        <Title
          medium
          tag="h2"
          content={t(title)}
        />
      )}
      {copy && (
        <Title
          tag="h4"
          content={t(copy)}
        />
      )}
      <ul className="List__container">
        {list.map((item, index) => (
          <li
            className="List__item"
            key={`list-item-${index}`}>
            {icon && icon}
            <span className="List__label">{t(item, { domain: siteName })}</span>
          </li>
        ))}
      </ul>
      {button && button}
    </div>
  );
};

export default List;
