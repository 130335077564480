import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";
import { Subtitle } from "../../components/atoms/Subtitle/subtitle";
import Button from "../../components/atoms/Button";
import pageNotFound from "../../images/logos/illust_404-passport.svg";
import "./page404.scss";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();

  useEffect(() => {
    if (language === "es") {
      // Get the current pathname
      const currentPath = window.location.pathname;

      // Define the expected 404 path
      const expected404Path = `/${language}/404.html`;

      // Check if the current path is not the expected 404 path and does not contain the double locale issue
      if (currentPath !== expected404Path && !currentPath.endsWith("/404.html")) {
        window.location.replace(expected404Path);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      hideLanguageSelector={true}>
      <Wrapper>
        <div className="page404">
          <img
            className="u-marginBottom"
            src={pageNotFound}
            alt="logo"
          />
          <Title
            hero
            tag="h1"
            content={t("ERROR 404")}
          />
          <Subtitle
            big
            content={t("Sorry, the page you are looking for is not available")}
          />
          <Button
            link="/home"
            tertiary
            label={t("Go to homepage")}
          />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
