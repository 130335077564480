import { EditApplicationHandler } from "../../../../services/api/EditApplication/EditOrderHandler";
import { CreateOrderHandler } from "../../../../services/api/CreateOrder/CreateOrderHandler";
import { clearFormDataInStorage } from "../../../utils/formDataInStorage";
import { getUtmsCookies } from "../../../utils/SEM/UTM/checkUtmParams";
import { buildFormToSubmit } from "../../../utils/form";
import { setCookies } from "../../../utils/cookies";
import { navigate } from "gatsby";
import { getLanguageIndex } from "../../../utils";

export const editApplication = async (
  form,
  orderCookie,
  structure,
  stepIndex,
  isEditingSection,
  language
) => {
  const editApplication = new EditApplicationHandler({});
  const languageSimple = getLanguageIndex(language);

  try {
    await editApplication.customAction({
      applicationId: orderCookie.applicationId,
      ...buildFormToSubmit(structure.steps, form),
      language: languageSimple,
    });
    const editedStepName = structure.steps[stepIndex].name;
    const pdfName = form.pdfId.name;

    isEditingSection
      ? (window.location = `/review-${pdfName}#${editedStepName}`)
      : (window.location = `/review-${pdfName}`);
  } catch (error) {
    throw error;
  }
};

export const createApplication = async (
  form,
  formType,
  formDescription,
  formPrice,
  structure,
  language
) => {
  const languageSimple = getLanguageIndex(language);

  try {
    const orderHandler = new CreateOrderHandler({});

    const response = await orderHandler.customAction({
      ...getUtmsCookies(),
      ...buildFormToSubmit(structure.steps, form),
      language: languageSimple,
    });

    clearFormDataInStorage();

    setCookies("address_token", {
      application: {
        formName: formType.toUpperCase(),
        formDescription: formDescription,
      },
      regularPrice: {
        name: form.pdfId.name,
        amount: formPrice,
      },
      applicationId: response.applicationId,
      paymentSite: response.paymentSite,
      pdfId: form.pdfId.value,
      order: response.applicationId,
    });

    const pagePath = `review-${form.pdfId.name}`;

    navigate(`/${pagePath}`);
  } catch (error) {
    throw error;
  }
};
