import React from "react";
import Collapsible from "react-collapsible";
import RichText from "../../organisms/RichText/richText";
import "./styles.scss";

const FaqItem = ({ title, content, compact, sitedata }) => {
  return (
    <Collapsible
      trigger={title}
      className={`${compact ? "faq-item faq-item--compact" : "faq-item"}`}
      openedClassName={`${compact ? "faq-item faq-item--compact" : "faq-item"}`}
      triggerClassName="faq-item__title"
      triggerOpenedClassName="faq-item__title"
      contentInnerClassName="faq-item__content"
      transitionTime={200}
      triggerTagName="div">
      <RichText
        content={content}
        siteData={sitedata}
      />
    </Collapsible>
  );
};

export default FaqItem;
