import React from "react";
import Button from "../../atoms/Button";
import Alert from "../../atoms/Alert";
import refreshIcon from "../../../images/svg/refresh.svg";
import { navigate } from "gatsby";

const ReloadPaymentMethod = () => {
  const reloadHandler = () => {
    navigate("/payment");
  };

  return (
    <div className="Pay__gateway-error">
      <Alert
        type="warning"
        content={`We're sorry, an error has ocurred. Please press 'reload' to proceed.`}
      />
      <Button
        onClick={reloadHandler}
        primary
        dataQa="payment-gateways-error"
        dataAnl="mainButton"
        label="Reload payment method"
        icon={refreshIcon}
        iconLeft
      />
    </div>
  );
};

export default ReloadPaymentMethod;
