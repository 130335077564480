import React, { useEffect } from "react";
import Button from "../../../atoms/Button";
import AlertPopup from "../../../molecules/AlertPopup";
import dataLayer from "../../../utils/dataLayer";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const CLOSE_DATALAYER_KEY = "closePopupPassportOver1";
const REDIRECT_DATALAYER_KEY = "redirectToD11FromPopupPassportOver1";
const SHOWUP_DATALAYER_KEY = "showUpPopupPassportOver1";

const PassportOver1Alert = ({ onClose }) => {
  const { t } = useTranslation();
  const handleOnClose = () => {
    dataLayer.push(CLOSE_DATALAYER_KEY);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnRedirect = () => {
    dataLayer.push(REDIRECT_DATALAYER_KEY);
  };

  useEffect(() => {
    dataLayer.push(SHOWUP_DATALAYER_KEY);
  }, []);

  return (
    <AlertPopup
      title={t(
        "Hey, we noticed your passport expired more than a year ago. You don’t meet the correct requirements."
      )}
      alertMessage={t(
        "However, if you have entered the wrong information, correct your mistake and continue with your application."
      )}
      onClose={handleOnClose}
      content={
        <>
          <div className="u-marginBottom">
            <Trans components={{ b: <b /> }}>
              {t(
                "<b>Remember:</b> To change your name, your passport should be undamaged, have a few blank pages, is about to expire or has expired within the past 5 years, or should be issued over 1 year ago."
              )}
            </Trans>
          </div>
          <Button
            className="u-marginBottom"
            label={t("Go to DS-82 and renew")}
            primary
            link="/passport-renewal"
            full
          />
          <div>
            {t(
              "If you do not meet the requirements listed above, you probably need to apply for a new passport."
            )}
          </div>
        </>
      }
      redirect={{
        to: "/new-passport",
        label: t("Get new passport with DS-11"),
        onClick: handleOnRedirect,
      }}
    />
  );
};

export default PassportOver1Alert;
