import React, { useMemo } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import FormField from "../../molecules/FormField";
import classnames from "classnames";
import "./check.scss";

export const Check = ({
  type,
  name,
  className,
  checkTitle,
  label,
  link,
  tooltip,
  errorMessage,
  validate,
  onChange,
  value,
  ...props
}) => {
  const { t } = useTranslation();
  const dataQA = `${type}-${name}`;
  const classNames = classnames(`check`, {
    [props.class]: typeof props.class === "string" && props.class.length > 0,
    [className]: typeof className === "string" && className.length > 0,
  });

  const checkLabel = t(label) || link;

  const buildedLabel = useMemo(() => {
    if (checkTitle) {
      return t(checkTitle);
    } else {
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTitle]);

  return (
    <FormField
      name={name}
      label={buildedLabel}
      className={classNames}
      tooltip={t(tooltip)}
      errorMessage={t(errorMessage)}
      validate={validate}>
      <input
        data-testid="check-input"
        className="check__input"
        type="checkbox"
        name={name}
        id={name}
        onChange={onChange}
        value={value === "1" ? value : "0"}
        checked={value === "1"}
        data-qa={dataQA}
      />

      {checkLabel && (
        <label htmlFor={name}>
          <span dangerouslySetInnerHTML={{ __html: t(checkLabel) }} />
        </label>
      )}
    </FormField>
  );
};

export default Check;
