import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SiteDataContext from "../../../context/SiteDataContext";
import { Title } from "../../atoms/Title/title";
import RichText from "../RichText/richText";
import Button from "../../atoms/Button";
import "./styles.scss";

export const StepBoxSingle = ({ title, content }) => {
  const siteData = useContext(SiteDataContext);

  return (
    <div className="stepbox stepbox--single">
      <Title
        className="stepbox--title"
        content={title}
      />
      <RichText
        content={content}
        siteData={siteData}
      />
    </div>
  );
};

export const StepBoxGrid = ({ title, blocks }) => {
  const siteData = useContext(SiteDataContext);

  return (
    <div className="stepbox">
      <Title
        className="stepbox--title"
        content={title}
      />
      <div className="stepbox--grid">
        {blocks.map((card, index) => {
          return (
            <div
              key={`Step-box-${index}`}
              className="stepbox--grid--item">
              <Title
                className="stepbox--grid--title"
                regular
                content={card.title}
              />
              <RichText
                content={card.content}
                siteData={siteData}
              />
              {card.button && (
                <div className="stepbox--grid--link">
                  <Button
                    primaryAlt
                    full
                    label={card.button}
                    link={`../${card.linkToPage.slug}`}
                    setTarget="_blank"
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const StepBoxColumns = ({ title, content, note, agencyButton, linkButtonAgency }) => {
  const siteData = useContext(SiteDataContext);
  const { t } = useTranslation();

  return (
    <div className="stepbox stepbox--columns">
      <div className="stepbox--head">
        <Title
          className="stepbox--title"
          content={title}
        />
      </div>
      <div className="stepbox--body">
        <div className="stepbox--columns--wrapper">
          <div className="stepbox--column">
            <RichText
              content={content}
              siteData={siteData}
            />
            <div className="stepbox--grid--link">
              {agencyButton && (
                <Button
                  tag="a"
                  withMarker
                  secondary
                  setTarget="_blank"
                  label={t(`Locate a Facility or Agency Near You`)}
                  link={linkButtonAgency}
                />
              )}
            </div>
          </div>
          <div className="stepbox--column stepbox--note">
            <div className="stepbox--note--label">{t("Note:")}</div>
            <RichText
              content={note}
              siteData={siteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
