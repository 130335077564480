import React, { Component } from "react";
import { navigate } from "gatsby";
import { PaymentWithCardHandler } from "../../../services/api/PaymentWithCard/PaymentWithCardHandler";
import Radio from "../../molecules/Radio";
import { Title } from "../../atoms/Title/title";
import { getPSP } from "./PSPs";
import CardsPsp from "../../molecules/CardsPsp";
import ReloadPaymentMethod from "./ReloadPaymentMethod";
import SecurityFooter from "./SecurityFooter";
import PaymentSubmittingLoader from "./PaymentSubmittingLoader";
import { buildPayItemForm, loadIframeUrl } from "./utilities";
import { getUtmsCookies } from "../../utils/SEM/UTM/checkUtmParams";
import { setCookies } from "../../utils/cookies";
import { scrollBehaviorTo, getParamInUrl } from "../../utils";
import "url-search-params-polyfill";
import Alert from "../../atoms/Alert";
import(`./styles.scss`);

class PayForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applicationId: this.props.application.applicationId,
      orderReference: this.props.application.applicationReference,
      loading: false,
      isSubmitting: false,
      formButtonDisable: false,
      errorPayment: false,
      gatewaysStructure: this.props.application.gatewaysStructure,
    };
  }

  closeCrossHandler = () => {
    this.setState({ errorPayment: null });
  };

  payOrder = ({ paymentData, state = {} }) => {
    return new Promise((resolve, reject) => {
      this.setState({
        formButtonDisable: true,
        isSubmitting: true,
        loading: true,
        ...state,
      });

      setCookies("address_token", { gateway: paymentData.gatewayIdentifier });

      const data = {
        ...getUtmsCookies(),
        ...paymentData,
        applicationId: this.state.applicationId,
      };

      const orderHandler = new PaymentWithCardHandler({});
      orderHandler
        .customAction(data)
        .then((res) => {
          this.setState({ isSubmitting: false });
          if (res.body.status === "OK") {
            setTimeout(() => {
              if (res.body.is3D) {
                navigate(res.body.verificationUrl);
              } else {
                this.loadIframeUrl("/verify-payment");
              }

              resolve(res);
            }, 100);
          } else {
            this.setState({
              errorPayment: true,
              loading: false,
              formButtonDisable: false,
              isSubmitting: false,
            });
            throw reject(res);
          }
        })
        .catch((error) => {
          this.setState({
            errorPayment: true,
            loading: false,
            formButtonDisable: false,
            isSubmitting: false,
          });
          throw reject(error);
        });
    });
  };

  updateRadio = (currentItem) => {
    const gatewaysStructure = this.state.gatewaysStructure.map((item) => {
      let isMastercard = item.methods[0].includes("mastercard");
      let method = isMastercard ? "mastercard" : item.methods[0][0];
      if (currentItem === item.items[0].value && (item.value === "" || item.value === null)) {
        item.value = method;
        item.items[0].checked = true;
        return item;
      } else {
        item.value = "";
        item.items[0].checked = false;
        return item;
      }
    });

    this.setState({
      gatewaysStructure,
    });

    if (window.innerWidth > 790) {
      scrollBehaviorTo(window.innerHeight / 4);
    } else {
      scrollBehaviorTo(window.innerHeight - window.innerHeight / 2);
    }
  };

  loadIframeUrl = (iframeUrl, transactionId) => {
    setCookies("address_token", { transactionId: transactionId });

    loadIframeUrl(iframeUrl, () => this.setState({ loading: false, isSubmitting: false }));
  };

  handleErrorInUrlParams = () => {
    const hasError = getParamInUrl("error");

    this.setState({
      errorPayment: hasError,
    });
  };

  componentDidMount() {
    if (this.state.applicationId) {
      this.setState({ loading: false });
    } else if (typeof this.state.orderReference === undefined) {
      navigate("/home");
    }

    this.handleErrorInUrlParams();
  }

  render() {
    const payItemForm = buildPayItemForm(this.state.gatewaysStructure);
    return (
      <div className="Pay">
        {this.state.loading && <PaymentSubmittingLoader isSubmitting={this.state.isSubmitting} />}

        {this.state.errorPayment && (
          <Alert
            type="error"
            content={this.props.alertContent}
            onClose={this.closeCrossHandler}
            className="Pay__alert"
            showLeftIcon
            closeIcon
          />
        )}

        {this.state.gatewaysStructure.map((item, index) => {
          const gateway = item.name;
          const fieldValue = item.items[0].value;
          const fieldChecked = item.items[0].checked;
          const { Psp, getProps } = getPSP(item.name);

          const pspProps = getProps({
            gateway,
            fieldChecked,
            paymentGatewayDetails: this.props.application.paymentGatewayDetails[gateway],
            application: this.props.application,
            buttonDisabled: this.state.formButtonDisable,
            isSubmitting: this.state.isSubmitting,
            setState: this.setState.bind(this),
            payOrder: this.payOrder.bind(this),
            loadIframeUrl: this.loadIframeUrl.bind(this),
          });

          return (
            <div
              className={payItemForm.form.class}
              key={`PayForm-${index}`}>
              <div className={payItemForm.header.class}>
                <div className={payItemForm.header.title.class}>
                  {payItemForm.header.title.showRadioButton ? (
                    <Radio
                      class="radio--payment"
                      onChange={this.updateRadio.bind(this, fieldValue)}
                      validate
                      required
                      {...item}
                    />
                  ) : (
                    <Title content={this.props.titleContent} />
                  )}

                  <CardsPsp cards={item.icons[0]} />
                </div>
              </div>

              {pspProps.show && (
                <div
                  id={`js-iframe-${pspProps.gateway}`}
                  className={payItemForm.body.class}>
                  <Psp {...pspProps} />
                </div>
              )}

              {fieldChecked && <SecurityFooter className={payItemForm.footer.class} />}
            </div>
          );
        })}

        {this.props.application.emptyGatways && <ReloadPaymentMethod />}
      </div>
    );
  }
}

export default PayForm;
