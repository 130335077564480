import { CreateOrderAction } from "./CreateOrderAction";
import { AjaxHandler } from "@npm_leadtech/jsr-lib-http/dist/esm/index";
import { LoggerService } from "../../logger/LoggerService";
import { ErrorHandle } from "../../errors/ErrorHandle";
import { CreateOrderReducer } from "./CreateOrderReducer";
import { defaultHeaders } from "../../../config/headers";
import { getApiPrefix } from "../utils";

export class CreateOrderHandler extends AjaxHandler {
  constructor(options) {
    options = options || {};
    super({
      apiPrefix: getApiPrefix(),
      action: CreateOrderAction,
      reducer: options.reducer || CreateOrderReducer,
      loggerService: LoggerService,
      errorHandler: options.errorHandle || ErrorHandle.default,
      httpParams: {
        authorization: process.env.HTTP_SECRET_KEY,
        headers: {
          ...defaultHeaders(),
        },
      },
    });
  }
}
