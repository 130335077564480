import { useMemo } from "react";
import { Link } from "gatsby";
import usePrepareClasses from "./usePrepareClasses";

const usePrepareProps = ({ link, tag, rootLink, buttonSubmit, ...props }) => {
  const Tag = useMemo(() => (link ? (tag ? tag : Link) : "button"), [link, tag]);

  const buttonLink = useMemo(() => {
    if (link) {
      return rootLink ? `/${link}` : `${link}`;
    } else {
      return undefined;
    }
  }, [link, rootLink]);

  const buttonType = useMemo(() => {
    if (!link) {
      return buttonSubmit ? "submit" : "button";
    } else {
      return undefined;
    }
  }, [buttonSubmit, link]);

  const { classnames } = usePrepareClasses(props);

  return {
    classnames,
    Tag,
    buttonLink,
    buttonType,
  };
};

export default usePrepareProps;
