import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { TypeSelector } from "../../../services/componentSwitcher/TypeSelector";
import { TypeSelectorValidate } from "../../../services/componentSwitcher/TypeSelectorValidate";
import { refHandler } from "../../../services/componentSwitcher/ComponentSwitcher";
import { groupFromField } from "../../../services/componentSwitcher/getGroup";
import FormComponents from "../FormComponents";
import { PostContactFormHandler } from "../../../services/api/PostContactForm/PostContactFormHandler";
import Wrapper from "../../Layout/Wrapper/wrapper";
import Button from "../../atoms/Button";
import check from "../../../images/icons/done_24pxgreen.svg";
import { scrollBehaviorTo, scrollY } from "../../utils";
import "./contactForm.scss";
import sanitizeHTML from "../../utils/sanitizeHtml";
import { getCookie } from "../../../components/utils/cookies";

const ContactForm = (props) => {
  const { t } = useTranslation();
  const [form, setForm] = useState(props.json.fields);
  const [structure] = useState(props.json.structure);
  const [formState, setFormState] = useState("start");

  const handleChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    const tempForm = { ...form };
    const field = tempForm[name];

    field.value = TypeSelector(field.component, value, target.checked);
    field.validate = true;

    setForm(tempForm);
  };

  const fieldValidator = (e) => {
    const tempForm = { ...form };
    const structureForm = { ...structure };
    let validated = true;
    const field = e.target.name;

    if (
      refHandler(groupFromField(field, structureForm), tempForm) &&
      refHandler(tempForm[field], tempForm)
    ) {
      if (tempForm[field].required || tempForm[field].type === "text") {
        if (!TypeSelectorValidate(tempForm[field], tempForm[field].value)) {
          tempForm[field].validate = false;
          if (validated) {
            validated = false;
          }
        } else {
          tempForm[field].validField = true;
          tempForm[field].validate = true;
        }
      }
      if (tempForm[field].value === "0" && tempForm[field].type === "checkbox") {
        if (validated) {
          validated = false;
        }
        tempForm[field].validField = false;
        tempForm[field].validate = false;
      }
      if (tempForm[field].name === "email") {
        const rgxEmail = /^(?=.{1,33}$)\w+([-+._']\w+)*@\w+([-._]\w+)*\.\w+([-.]\w+)*$/;
        let fieldToValidate = tempForm[field];
        let isValid = rgxEmail.test(fieldToValidate.value);

        tempForm[field].validField = isValid;
        tempForm[field].validate = isValid;
      }
    }
    setForm(tempForm);

    return validated;
  };

  const validateForm = () => {
    const tempForm = { ...form };
    let validated = true;
    let refErrorElement = "";

    Object.keys(tempForm).forEach((field) => {
      const eventMock = {
        target: {
          name: field,
          value: tempForm[field].value,
        },
      };

      if (!fieldValidator(eventMock)) {
        validated = false;
        refErrorElement = field;
      }
    });

    if (refErrorElement !== "") scrollToRef(refErrorElement);

    setForm(tempForm);

    return validated;
  };

  const scrollToRef = (ref) => {
    scrollBehaviorTo(document.getElementById(ref).getBoundingClientRect().top + scrollY() - 110);
  };

  const onSubmit = () => {
    if (!validateForm()) {
      return false;
    }

    let data = Object.values(form).reduce((obj, field) => {
      if (
        field.value !== "" &&
        refHandler(field, form) &&
        refHandler(groupFromField(field.name, structure), form)
      ) {
        obj[field.name] = field.value;
      }
      return obj;
    }, {});

    const contactFormHandler = new PostContactFormHandler({});

    contactFormHandler
      .customAction(data)
      .then((res) => {
        setFormState("successForm");
        window.scroll(0, 0);
      })
      .catch((error) => {
        let formError = { ...form };
        formError.config.error = true;
        setForm({
          loading: false,
          ...formError,
        });
      });
  };

  const startForm = () => {
    setFormState("start");
    const tempForm = { ...form };

    Object.keys(tempForm).forEach((field) => {
      tempForm[field].value = "";
      tempForm[field].validField = false;
    });
  };

  const contactInfoSvgs = props.contactShowInfo;
  const siteInfo = getCookie("site_info");
  const showSpecialNMIInfo =
    siteInfo.showSpecialNMIInfo !== null ? siteInfo.showSpecialNMIInfo : false;

  const start = (
    <div className="contact__wrapper">
      <form
        onSubmit={onSubmit}
        className="doc">
        <div className="doc-container">
          {structure.steps.map((step) => (
            <div
              className="doc-step"
              key={step.name}>
              {step.groups.map((group) => (
                <div key={group.name}>
                  {refHandler(group, form) && (
                    <>
                      {showSpecialNMIInfo && (
                        <div className="contact__info">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML(contactInfoSvgs.footer_name_company_1),
                            }}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML(contactInfoSvgs.footer_direction),
                            }}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML("+1 (786) 2091728"),
                            }}
                          />
                        </div>
                      )}
                      <p>
                        {t("Use the form below to submit a support request. A customer service agent will be in touch with you as soon as posible. If submitted after office hours, we will respond you on the next business day.")}
                      </p>
                      <div className="doc-group m-contact-doc u-flex u-flex--wrap u-flex--justify-between">
                        {group.fields.map((item, index) => {
                          const field = form[item];
                          const FieldComponent = FormComponents[field.component];
                          return (
                            <React.Fragment key={`doc-group-${index}`}>
                              {refHandler(field, form) && (
                                <FieldComponent
                                  onBlur={fieldValidator}
                                  onChange={handleChange}
                                  {...field}
                                  key={`${field.name.toString()}-${index}`}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
          <div className="contact__submit">
            <Button
              primary
              data-qa="submit-form"
              label={t("Send Message")}
              onClick={onSubmit}
            />
          </div>
        </div>
      </form>
      <div className="contact__email e-text-helper">{t("Email address: info@")}{siteInfo.domain}.</div>
    </div>
  );

  const successForm = (
    <Wrapper>
      <div className="contact__success">
        <img
          src={check}
          alt={t("Your message have been sent")}
        />
        <p className="successMessage">{t("Your message has been sent")}</p>
        <Button
          primary
          data-qa="submit-form"
          label={t("Send new message")}
          onClick={startForm}
        />
      </div>
    </Wrapper>
  );

  const FormPhase = {
    start: start,
    successForm: successForm,
  };

  return <div>{FormPhase[formState]}</div>;
};

export default ContactForm;