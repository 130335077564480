import { GetFormDetailsReducer } from "./GetFormDetailsReducer";
import { AjaxHandler } from "@npm_leadtech/jsr-lib-http/dist/esm/index";
import { LoggerService } from "../../logger/LoggerService";
import { ErrorHandle } from "../../errors/ErrorHandle";
import { GetFormDetailsAction } from "./GetFormDetailsAction";
import { defaultHeaders } from "../../../config/headers";
import { getApiPrefix } from "../utils";

export class GetFormDetailsHandler extends AjaxHandler {
  /**
   *
   * @param options {Object}
   * @param options.reducer {Class}
   */
  constructor(options) {
    options = options || {};
    super({
      apiPrefix: getApiPrefix(),
      action: GetFormDetailsAction,
      reducer: options.reducer || GetFormDetailsReducer,
      loggerService: LoggerService,
      errorHandler: options.errorHandle || ErrorHandle.default,
      httpParams: {
        authorization: process.env.HTTP_SECRET_KEY,
        headers: {
          ...defaultHeaders(),
        },
      },
    });
  }
}
