import React from "react";
import classNames from "classnames";
import "./styles.scss";

export const Subtitle = (props) => {
  const classnames = classNames("e-subtitle", {
    // size
    "e-subtitle-hero": props.hero,
    "e-subtitle-big": props.big,
    "e-subtitle__strong": props.strong,
    "e-subtitle__thin": props.thin,
    "e-subtitle__center": props.center,
  });
  return (
    <div
      data-testid="subtitle"
      className={classnames}
      data-qa={props.dataQa}>
      {props.content}
    </div>
  );
};
