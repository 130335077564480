import classNames from "classnames";
import { useMemo } from "react";

const usePrepareClasses = ({ template, className, ...props }) => {
  const styles = useMemo(
    () => require(`../Steps.${template || "default"}/styles.module.scss`),
    [template]
  );

  const classnames = classNames(`steps__list ${styles.steps__list}`, {
    [styles.steps__listSecondary]: props.secondary,
    [className]: className,
  });

  return {
    classnames,
  };
};

export default usePrepareClasses;
