import React, { useMemo, useContext } from "react";
import SiteDataContext from "../../../context/SiteDataContext";
import RichText from "../../../components/organisms/RichText/richText";
import { stepsHowItWorks } from "../../../data/how-it-works";

const usePrepareHomeData = (data) => {
  const homeData = data.contentfulHome;
  const stepsData = data.allContentfulSteps;
  const siteData = useContext(SiteDataContext);

  const formData = useMemo(
    () => ({
      ...data.allContentfulFormType,
      nodes: data.allContentfulFormType.nodes.sort((item, prevItem) => {
        // TODO: Sort array cards by defined attributes
        if (typeof item.orderByTemplate === "object") {
          const template = "default";
          return item.orderByTemplate[template] - prevItem.orderByTemplate[template];
        } else {
          return item.order - prevItem.order;
        }
      }),
    }),
    [data]
  );

  const jumbotronTitle = useMemo(() => {
    if (homeData.jumbotronTitleSvg) {
      return (
        <RichText
          content={homeData.jumbotronTitleSvg}
          siteData={siteData}
        />
      );
    } else {
      return homeData.jumbotronTitle;
    }
  }, [homeData, siteData]);

  return {
    homeData,
    formData,
    stepsData,
    jumbotronTitle,
    stepsHowItWorks,
  };
};

export default usePrepareHomeData;
