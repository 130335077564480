import React, { useEffect, useState } from "react";

const CommerceGatePSP = ({ paymentGatewayDetails, application }) => {
  const [scriptSrc, setScriptSrc] = useState(null);

  useEffect(() => {
    const { applicationEmail, applicationPrice, applicationId } = application;

    const paymentEmail = applicationEmail
      ? `email=${applicationEmail}&username=${applicationEmail}&`
      : "";

    if (paymentGatewayDetails) {
      const paymentPreoffer = paymentGatewayDetails.preoffer
        ? "preoffer=" + paymentGatewayDetails.preoffer + "&"
        : "";
      const paymentPackid = paymentGatewayDetails.packid
        ? "packid=" + paymentGatewayDetails.packid + "&"
        : "";

      const encodedTargetAddress = encodeURIComponent(process.env.TARGET_ADDRESS);
      const encodedOpt1 = encodeURIComponent(paymentGatewayDetails.opt1);

      setScriptSrc(
        `https://checkout.cgpaytech.com/?${paymentEmail}${paymentPreoffer}${paymentPackid}&lang=${paymentGatewayDetails.lang}&currency=${paymentGatewayDetails.currency}&amount=${applicationPrice.amount}&cid=${paymentGatewayDetails.cid}&wid=${paymentGatewayDetails.wid}&token=${paymentGatewayDetails.token}&externalId=${paymentGatewayDetails.externalId}&successUrl=${encodedTargetAddress}%2Fverify-payment%2F&failedUrl=${encodedTargetAddress}%2Fpayment%2F%3Fid%3D${applicationId}%26error%3Dtrue&op1=${encodedOpt1}`
      );
    }
  }, [paymentGatewayDetails, application]);

  return scriptSrc ? (
    <div className="m-blockForm">
      <div className="Pay__iframe-wrapper">
        <iframe
          className="Pay__iframe"
          src={scriptSrc}
          title="Payment"></iframe>
      </div>
    </div>
  ) : null;
};

export default CommerceGatePSP;
