export const groupFromField = (field, formStructure, form) => {
  let groupObject = {};
  let tempGroupObject = {};
  let stopIterating = false;
  formStructure.steps.forEach((step) => {
    step.groups.forEach((group) => {
      if (!stopIterating) {
        if (group.reference) {
          group.fields.forEach((currentField) => {
            if (
              currentField === field &&
              form[group.reference.name].value === group.reference.value
            ) {
              stopIterating = true;
              groupObject = group;
            } else if (currentField === field) {
              tempGroupObject = group;
            }
          });
        } else {
          group.fields.forEach((currentField) => {
            if (currentField === field) groupObject = group;
          });
        }
      }
    });
  });

  if (Object.entries(groupObject).length !== 0 && groupObject.constructor === Object) {
    return groupObject;
  } else {
    return tempGroupObject;
  }
};
