import React, { useState, useEffect, useRef } from "react";
import { sendAddToCartEvent, sendBeginCheckoutEvent } from "../../utils/dataLayer/events";
import { windowIsExist } from "../../utils/index";
import Alert from "../../atoms/Alert";
import classNames from "classnames";
import Loading from "../../atoms/Loading";
import { calculateTotal } from "../../utils/addons";
import stickyFallback from "./useStickyFallback";
import { isIEBrowser } from "../../utils";
import { getCookie } from "../../utils/cookies";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "./styles.scss";

const OrderSummary = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);

  let sortedAddons = props.addons.sort((p1, p2) => {
    return p1.order > p2.order ? 1 : p1.order < p2.order ? -1 : 0;
  });

  const activeAddons = sortedAddons.filter((addon) => addon.value === "1");

  // if applicationTotalPrice is true use the total from api (payment page)
  // else calculate the total from price and addons
  const result = props.applicationTotalPrice
    ? props.applicationTotalPrice
    : calculateTotal(props.price, activeAddons);
  const [total, setTotal] = useState(result);
  const previousTotal = useRef(total);

  const siteInfo = getCookie("site_info");
  const showSpecialNMIInfo =
    siteInfo.showSpecialNMIInfo !== null ? siteInfo.showSpecialNMIInfo : false;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const classnames = classNames(
    `orderSummary u-box u-box--small ${props.classNames} ${!isOpen ? "orderSummary--closed" : ""}`,
    {
      "orderSummary--form": props.form,
      "orderSummary--thankYou": props.thankYou,
      "orderSummary--payment": props.payment,
    }
  );

  const { domain } = getCookie("site_info");

  useEffect(() => {
    if (isIEBrowser()) {
      stickyFallback(props.fixed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    previousTotal.current = total;
    setTotal(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.price, props.addons]);

  // start sending DataLayer events sendAddToCartEvent on the first render
  const isPaymentPage = windowIsExist && window.location.pathname.startsWith("/payment");
  const isReviewPage = windowIsExist && window.location.pathname.startsWith("/review");

  useEffect(() => {
    if (!props.pdf.name || props.price === 0 || total === 0.0 || isPaymentPage) return;
    if (!isReviewPage) {
      sendAddToCartEvent(props.pdf.name, activeAddons, props.price, total.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // send DataLayer events sendBeginCheckoutEvent on isReviewPage
  useEffect(() => {
    if (!props.pdf.name || props.price === 0 || total === 0.0 || isPaymentPage) return;
    if (isReviewPage) {
      sendBeginCheckoutEvent(props.pdf.name, activeAddons, props.price, total.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pdf.name, props.price, total]);

  // re-send DataLayer events sendAddToCartEvent on item added to cart
  useEffect(() => {
    if (total > previousTotal.current && !isReviewPage) {
      sendAddToCartEvent(props.pdf.name, activeAddons, props.price, total.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return (
    <div
      data-testid="orderSummaryContainer"
      id="orderSummaryContainer"
      className={classnames}>
      <div
        onClick={toggleDropdown}
        onKeyDown={toggleDropdown}
        role="button"
        tabIndex={0}
        className={`orderSummary__header`}>
        <div className="orderSummary__title">{props.title ? props.title : t("Order Summary")}</div>
        <span className={`orderSummary__arrow`}></span>
      </div>
      {Object.keys(props.pdf).length !== 0 ? (
        <>
          <div className={`orderSummary__body`}>
            {props.applicationReference && (
              <div
                data-qa="application-reference"
                className={`orderSummary__row`}>
                <div className={`orderSummary__detail`}>
                  {t("Application Reference Code")}: {props.applicationReference}
                </div>
              </div>
            )}
            <div
              data-qa="pdf-details"
              className={`orderSummary__row`}>
              <div className={`orderSummary__detail`}>
                <div>{t(`${props.pdf.description}`)}</div>
                <div>{t(`${props.pdf.name} Form`)}</div>
              </div>

              <div className={`orderSummary__price`}>${props.price.toFixed(2)}</div>
            </div>
            {activeAddons.map((activeAddon) => (
              <div
                key={activeAddon.identifier}
                data-qa={`addon-${activeAddon.identifier}`}
                className={`orderSummary__row`}>
                <div className={`orderSummary__detail`}>{t(activeAddon.title)}</div>
                <div className={`orderSummary__price`}>{activeAddon.price}</div>
              </div>
            ))}
          </div>
          <div
            data-qa="order-total"
            className={`orderSummary__total`}>
            <span className={`orderSummary__total-detail`}>{t("Total amount:")} </span>
            <span> ${total.toFixed(2)}</span>
          </div>
          <div className="orderSummary__footer">
            <Alert
              type="warning"
              content={t(
                `<b>Important</b>: This is a one-time payment and it does not include any applicable government filing fees.`
              )}
              showLeftIcon
              compact
            />
          </div>
          {showSpecialNMIInfo && (
            <div className="small text-secondary orderSummary__billing">
              {t("The billing descriptor that will appear on your bank statement is www.")}
              {domain}.
            </div>
          )}
        </>
      ) : (
        <Loading small />
      )}
    </div>
  );
};

export default OrderSummary;
