import React, { useMemo, useContext } from "react";
import SiteDataContext from "../../../../context/SiteDataContext";
import RichText from "../../../organisms/RichText/richText";
import { useStaticQuery, graphql } from "gatsby";

const initialValues = {
  title: "Our Benefits",
  subtitle: `Receive a neatly prepared passport form and personalized filling instructions by using our online form and
    filling software`,
};

const simpleIcons = {
  oneDay: require(`../../../../images/icons/hours.svg`).default,
  simpleForm: require(`../../../../images/icons/simpleform.svg`).default,
  errorChecking: require(`../../../../images/icons/errorchecking.svg`).default,
  easyStep: require(`../../../../images/icons/stepbystep.svg`).default,
};

const simpleForm = (siteTheme) =>
  require(`../../../../../static/svg/themes/${siteTheme}/simpleform.svg`).default;
const errorChecking = (siteTheme) =>
  require(`../../../../../static/svg/themes/${siteTheme}/errorchecking.svg`).default;
const easyStep = (siteTheme) =>
  require(`../../../../../static/svg/themes/${siteTheme}/stepbystep.svg`).default;
const oneDay = (siteTheme) =>
  require(`../../../../../static/svg/themes/${siteTheme}/hours.svg`).default;

const usePrepareProps = ({
  benefitTitle,
  benefitSubtitle,
  benefit1,
  benefit2,
  benefit3,
  benefit4,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          web_design
        }
      }
    }
  `);

  const siteTheme = data.site.siteMetadata.web_design;

  const benefits = {
    benefit1: {
      icon: simpleForm(siteTheme),
      simpleIcon: simpleIcons.simpleForm,
      value: benefit1,
      alt: benefit1,
      id: "ourBenefits_simpleForm",
    },
    benefit2: {
      icon: errorChecking(siteTheme),
      simpleIcon: simpleIcons.errorChecking,
      value: benefit2,
      alt: benefit2,
      id: "ourBenefits_errorChecking",
    },
    benefit3: {
      icon: easyStep(siteTheme),
      simpleIcon: simpleIcons.easyStep,
      value: benefit3,
      alt: benefit3,
      id: "ourBenefits_easyStep",
    },
    benefit4: {
      icon: oneDay(siteTheme),
      simpleIcon: simpleIcons.oneDay,
      value: benefit4,
      alt: benefit4,
      id: "ourBenefits_oneDay",
    },
  };

  const title = useMemo(() => benefitTitle || initialValues.title, [benefitTitle]);

  const siteData = useContext(SiteDataContext);

  const subtitle = useMemo(
    () =>
      benefitSubtitle ? (
        <RichText
          content={benefitSubtitle.json}
          siteData={siteData}
        />
      ) : (
        initialValues.subtitle
      ),
    [benefitSubtitle, siteData]
  );

  return {
    title,
    subtitle,
    benefits,
  };
};

export default usePrepareProps;
