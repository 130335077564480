import React from "react";
import "./styles.scss";

const CardsPsp = ({ cards }) => {
  return (
    <div className="cardsPsp">
      {cards.map((icon, index) => {
        return (
          <div
            className={`cardsPsp__item cardsPsp__item--${icon}`}
            key={`CardPsp-${index}`}></div>
        );
      })}
    </div>
  );
};

export default CardsPsp;
