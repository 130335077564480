import React from "react";
import PropTypes from "prop-types";
import Message from "./Message";
import Tooltip from "../../Form/Tooltip/tooltip";
import classNames from "classnames";
import "./formField.scss";

const FormFieldComponent = ({ id, name, label, subLabel, children, ...props }) => {
  const className = classNames(`${props.className}`, {
    "is-invalid": !props.validate,
    "is-valid": props.validField,
  });

  return (
    <div
      data-qa={props.dataQa}
      data-testid={props.validate ? name : "is-invalid"}
      className={className}>
      {label && (
        <label
          htmlFor={!props.tooltip ? id : undefined}
          className={`inputLabel inputLabel-${name}`}>
          {label}
          {props.tooltip && (
            <Tooltip
              position="left"
              effect="solid"
              event="click"
              tip={name}
              dataFor={`m-tooltip__${name}`}
              content={props.tooltip}
            />
          )}
        </label>
      )}

      {subLabel && <p className="form-subTitle small">{subLabel}</p>}

      <Message
        type="disclaimerBeforeField"
        content={props.disclaimerBeforeField}
      />

      {children}

      <Message
        type="error"
        content={props.errorMessage}
      />
      <Message
        type="error"
        content={props.errorMessageExtraFirst}
      />
      <Message
        type="error"
        content={props.errorMessageExtraLast}
      />

      <Message
        type="helper"
        content={props.helperMessage}
      />

      <Message
        type="disclaimerAfterField"
        content={props.disclaimerAfterField}
      />

      <Message
        type="disclaimerAlert"
        content={props.disclaimerAlert}
        showIcon
      />
    </div>
  );
};

FormFieldComponent.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  errorMessageExtraFirst: PropTypes.string,
  errorMessageExtraLast: PropTypes.string,
  helperMessage: PropTypes.string,
  tooltip: PropTypes.any,
  validate: PropTypes.bool,
  validField: PropTypes.bool,
  disclaimerBeforeField: PropTypes.string,
  disclaimerAfterField: PropTypes.string,
  disclaimerAlert: PropTypes.string,
  dataQa: PropTypes.string,
};

export default FormFieldComponent;
