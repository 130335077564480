export const cardsPassportData = () => {
  let cards = [
    {
      id: "fbe3d439-9806-5b6f-96d3-3e0d48332492",
      show: true,
      name: "DS-11",
      headerTitle: "<span style='white-space: nowrap;'>Nuevo</span>",
      formButtonText: "Completar en línea",
      slug: "new",
      formImage: {
        id: "7ab7d93c-a65a-5a10-be6b-a53944a7605e",
        url: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requisitos:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Comience ahora si lo siguiente es aplicable: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Está solicitando en nombre de ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "un menor de 16 años.",
                          marks: [
                            {
                              type: "bold",
                            },
                          ],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Es su primer ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport_es",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "regular",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: " de los Estados Unidos. ",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Su ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport_es",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "regular",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: " anterior fue emitido hace más de 15 años.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Si es mayor de 16 años y tenía 15 años o menos cuando se le emitió su último documento.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 1,
      orderByTemplate: {
        default: 1,
        template_1: 2,
      },
    },
    {
      id: "6bc785f3-7b63-506f-aa47-45191ac91a44",
      show: true,
      name: "DS-82",
      headerTitle: "Renovación",
      formButtonText: "Completar en línea",
      slug: "renewal",
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: false,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requisitos:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Comience ahora si su documento anterior:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Está en su posesión y no está dañado.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Fue emitido cuando tenía 16 años o más.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Le quedan pocas páginas en blanco.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Caduca pronto o caducó en los últimos 5 años.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Fue expedido hace más de un año y desde entonces ha cambiado legalmente su nombre.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      alertIcon: "certified-check.svg",
      alertText: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "paragraph",
              data: {},
              content: [
                {
                  nodeType: "text",
                  value: "Más de ",
                  marks: [],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: "140,000 ",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: "disclaimer_passport_application_1_es_regular",
                  marks: [
                    {
                      type: "code",
                    },
                    {
                      type: "regular",
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "text",
                  value: "s tramitados.",
                  marks: [],
                  data: {},
                },
              ],
            },
          ],
        },
      },
      order: 2,
      orderByTemplate: {
        default: 2,
        template_1: 1,
      },
    },
    {
      id: "fc8e81b7-09c9-5cb0-bcd3-da26d380677c",
      name: "DS-64",
      headerTitle: "<span>Perdidas / Robadas</span>",
      formButtonText: "Completar en línea",
      slug: "lost-stolen",
      show: true,
      formImage: {
        id: "e8a3adb2-ccec-51d8-8274-72c3ae9e1b27",
        url: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/21652dae23bb1b17323d3f48d526548a/DS-64.png?w=95&h=122&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requisitos:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Comience ahora si lo siguiente es aplicable: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Está solicitando en nombre de un menor de 16 años.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Es su primer ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport_es",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "regular",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: " de los Estados Unidos. ",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Su ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport_es",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "regular",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: " anterior fue emitido hace más de 15 años. ",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Si es mayor de 16 años y tenía 15 años o menos cuando se le emitió su último documento. ",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 3,
      orderByTemplate: {
        default: 3,
        template_1: 3,
      },
    },
    {
      id: "e83c32e8-9e8d-4286-bdd4-c3bf72902000",
      show: true,
      name: "DS-5504",
      headerTitle: "Cambios y correcciones",
      formButtonText: "Solicitar ahora",
      slug: "passport-corrections",
      newLabel: true,
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-2",
              content: [
                {
                  nodeType: "text",
                  value: "Requisitos:",
                  marks: [
                    {
                      type: "bold",
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "Complete su solicitud si lo siguiente es aplicable:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Su ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport_es",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "Regular",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value:
                            " tiene un error de impresión que ocurrió en el plazo de un año desde su fecha de emisión.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Su ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport_es",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "Regular",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: " está en buenas condiciones.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Tiene documentación para respaldar el cambio o corrección que está solicitando.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Tiene un “ ",
                          marks: [],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: "formType_passport_es_capitalize",
                          marks: [
                            {
                              type: "code",
                            },
                            {
                              type: "regular",
                            },
                          ],
                          data: {},
                        },
                        {
                          nodeType: "text",
                          value: " de Validez Limitada”.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 5,
      orderByTemplate: {
        default: 5,
        template_1: 5,
      },
    },
  ];

  // TODO: Sort array cards by defined attributes
  return cards.sort((item, prevItem) => {
    const template = "default";

    if (typeof item.orderByTemplate === "object") {
      return item.orderByTemplate[template] - prevItem.orderByTemplate[template];
    } else {
      return item.order - prevItem.order;
    }
  });
};

export const ourBenefitsData = {
  benefitTitle: "Nuestros beneficios",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value:
                "Reciba un documento cuidadosamente preparado e instrucciones personalizadas con la ayuda de nuestro cuestionario ",
            },
            {
              nodeType: "text",
              value: "benefits_online_es",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: " y ",
            },
            {
              nodeType: "text",
              value: "benefits_tools_es",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: " ",
            },
            {
              nodeType: "text",
              value: "benefits_online_es",
              marks: [
                {
                  type: "code",
                },
                {
                  type: "italic",
                },
              ],
              data: {},
            },
            {
              data: {},
              marks: [],
              nodeType: "text",
              value: ".",
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: "Formulario rápido de solicitud en línea",
  benefit2: "Comprobación exhaustiva de errores",
  benefit3: "Instrucciones sencillas paso a paso",
  benefit4: "Atención al cliente 24 horas al día, 7 días a la semana",
};

export const stepsData = {
  edges: [
    {
      node: {
        id: "13de9fd1-8c3c-5f84-8410-afe95ccf68db",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "Step 1",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "Complete su ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "benefits_form_es",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value:
                      " de forma rápida y sencilla, evitando costosos errores gracias a nuestras ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "benefits_tools_es",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: " ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "benefits_online_es",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: ".",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 1,
      },
    },
    {
      node: {
        id: "0eaa22da-408c-5cdc-921e-f5c2f683607a",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "Step 2",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "Todas las tasas ",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "covid_goverment_es",
                    marks: [
                      {
                        type: "code",
                      },
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: " se pagan por separado",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 2,
      },
    },
    {
      node: {
        id: "738902d5-db5a-5d8c-a61f-c7d2ab35dcc5",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: "hyperlink",
                    content: [
                      {
                        nodeType: "text",
                        value: "Visite el sitio oficial",
                        marks: [],
                        data: {},
                      },
                    ],
                    data: {
                      uri: "https://travel.state.gov/content/passports/en/passports.html",
                    },
                  },
                  {
                    nodeType: "text",
                    value: "",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 3,
      },
    },
  ],
};
