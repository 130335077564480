import React from 'react';
import Button from '../../components/atoms/Button';
import Modal from '../../components/atoms/Modal';
import classNames from 'classnames';
import { Title } from '../../components/atoms/Title/title';
import * as styles from "../../components/molecules/Popup/popup.module.scss";
import './styles.scss';


const LandingCookies = ({ pageContext }) => {
  const { name } = pageContext;
  
  const classnames = {
    modal: classNames(styles.modal),
    modalBlurDark: classNames(styles.modalBlurDark),
    popup: classNames(styles.popup),
  };
  
  const handleOnClick = (origin) => {
    console.log('origin', origin)
    if (origin === 'start') {
      // bueno
      console.log('navigate bueno')
      // do not use navigate here
      window.location.href = '/home';
    } else {
      // malo
      console.log('navigate malo')
      // do not use navigate here
      window.location.href = '/base-path';
    }
  };

  return (
    <div className={`landing-cookies landing-cookies--${name}`}>
      <div id="__gatsby_modal" className={`${classnames.modal} ${classnames.modalBlurDark} ${classnames.popup}`}/>
      <Modal>
        <div
          className={`landing-cookies__popup ${classnames.popup}`}>
            <Title
              tag="h1"
              regular
              className="landing-cookies__title"
              content="Do You Accept Cookies?"
            />
            <p>Cookies are used to store non-personally identifiable information, and our website requires them to function.</p>
            <Button
              primary
              small
              className="landing-cookies__button"
              data-qa="accept-cookies"
              label="I Accept"
              onClick={() => handleOnClick(name)}
            />
            <Button
              tertiary
              small
              className="landing-cookies__button"
              data-qa="close-cookies"
              label="Close"
              onClick={() => handleOnClick(name)}
            />
        </div>
      </Modal>
    </div>
  );
};

export default LandingCookies;
