import { PostContactFormAction } from "./PostContactFormAction";
import { AjaxHandler } from "@npm_leadtech/jsr-lib-http/dist/esm/index";
import { LoggerService } from "../../logger/LoggerService";
import { ErrorHandle } from "../../errors/ErrorHandle";
import { PostContactFormReducer } from "./PostContactFormReducer";
import { defaultHeaders } from "../../../config/headers";
import { getApiPrefix } from "../utils";

export class PostContactFormHandler extends AjaxHandler {
  /**
   *
   * @param options {Object}
   * @param options.reducer {Class}
   */
  constructor(options) {
    options = options || {};
    super({
      apiPrefix: getApiPrefix(),
      action: PostContactFormAction,
      reducer: options.reducer || PostContactFormReducer,
      loggerService: LoggerService,
      errorHandler: options.errorHandle || ErrorHandle.default,
      httpParams: {
        authorization: process.env.HTTP_SECRET_KEY,
        headers: {
          ...defaultHeaders(),
        },
      },
    });
  }
}
