import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./loading.scss";

const Loading = (props) => {
  const loadingClassNames = classnames("loading", {
    "loading--full": props.full,
  });

  const spinnerClassNames = classnames("loading__ring", {
    "loading__ring--small": props.small,
    "loading__ring--dark": props.dark,
  });

  return (
    <div
      data-testid="loading"
      className={loadingClassNames}>
      <div>
        <div
          data-testid="loading-spinner"
          className={spinnerClassNames}
        />
        {props.message && (
          <div
            data-testid="loading-message"
            className="loading__message">
            {props.message}
          </div>
        )}
      </div>
    </div>
  );
};

Loading.propTypes = {
  small: PropTypes.bool,
  full: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Loading;
