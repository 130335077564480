import React from "react";
import PropTypes from "prop-types";
import Loading from "../../atoms/Loading";
import useHandleMountedForm from "./hooks/useHandleMountedForm";

const AuthForm = ({ formValidator, children }) => {
  const { mountStatus } = useHandleMountedForm(formValidator);

  return mountStatus !== "mounted" ? <Loading full /> : children;
};

AuthForm.propTypes = {
  formValidator: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AuthForm;
