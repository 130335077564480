import { useMemo } from "react";
import classNames from "classnames";
import "../styles.scss";

const usePrepareClasses = ({ template, ...props }) => {
  const styles = useMemo(
    () => require(`../Button.${template || "default"}/styles.module.scss`),
    [template]
  );

  const classnames = classNames(`button`, {
    [styles.button]: styles.button,
    [styles.buttonPrimary]: props.primary,
    [styles.buttonSecondary]: props.secondary,
    [styles.buttonTertiary]: props.tertiary,
    [styles.buttonPrimaryAlt]: props.primaryAlt,
    [styles.buttonSecondaryAlt]: props.secondaryAlt,
    [props.className]: props.className,
    "button--disabled": props.disabled,
    "button--full": props.full,
    "button--icon-left": props.iconLeft,
    "button--hasIcon": props.icon || props.svgSrc,
    "button--compact": props.compact,
    "button--small": props.small,
    "button--short": props.short,
    "button--long": props.long,
    "button--justifyBetween": props.justifyBetween,
  });

  return {
    classnames,
  };
};

export default usePrepareClasses;
