import { windowIsExist } from ".";
import { getCookie } from "./cookies";

const STORAGE_KEY = "__FORM_DATA";
const FORM_PROPERTIES_DISALLOWED = ["pdfId"];
const FIELD_PROPERTIES_ALLOWED = ["value", "validate", "validField"];

export const getFormDataInStorage = () => {
  const form = windowIsExist ? localStorage.getItem(STORAGE_KEY) : null;

  if (form) {
    return JSON.parse(form);
  } else {
    return null;
  }
};

export const setFormDataInStorage = (form) => {
  if (windowIsExist) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(buildFormDataToSaveInStorage(form)));
  }

  return getFormDataInStorage();
};

const buildFormDataToSaveInStorage = (form) => {
  let newForm = {};

  if (typeof form === "object") {
    for (const form_key in form) {
      if (FORM_PROPERTIES_DISALLOWED.includes(form_key)) continue;

      const field = form[form_key];

      if (!newForm[form_key]) {
        newForm[form_key] = {};
      }

      for (const field_key in field) {
        if (FIELD_PROPERTIES_ALLOWED.includes(field_key)) {
          newForm[form_key][field_key] = field[field_key];
        }
      }
    }
  }

  return newForm;
};

export const buildFormWithStorageData = (currentForm) => {
  const formInStorage = getFormDataInStorage();

  const { pdfId } = getCookie("address_token");

  const existOrder = pdfId !== currentForm.pdfId.value;

  if (existOrder && formInStorage) {
    let newForm = {};

    for (let form_key in currentForm) {
      newForm[form_key] = currentForm[form_key];

      if (FORM_PROPERTIES_DISALLOWED.includes(form_key)) continue;

      const fieldInStorage = formInStorage[form_key];

      if (fieldInStorage && fieldInStorage.value !== "") {
        FIELD_PROPERTIES_ALLOWED.forEach((key_allowed) => {
          newForm[form_key][key_allowed] = fieldInStorage[key_allowed];
        });
      }
    }

    return newForm;
  } else {
    return currentForm;
  }
};

export const clearFormDataInStorage = () => {
  if (windowIsExist) {
    localStorage.removeItem(STORAGE_KEY);
  }
};
