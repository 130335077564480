import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactSVG } from "react-svg";
import { Link } from "gatsby";
import "./styles.scss";

const Links = ({ LinksArray, FooterLink }) => {
  const { t } = useTranslation();

  return (
    <div className="Links">
      {LinksArray.map((link, index) => (
        <Link
          to={link.url}
          className="Links__item"
          key={`link-array-${index}`}>
          <span className="Links__label">{t(link.label)}</span>
          <span className="Links__text">{t(link.text)}</span>
          <ReactSVG
            src="/svg/arrow_right_24px.svg"
            className="Links__icon"
          />
        </Link>
      ))}

      {FooterLink && (
        <Link
          href={FooterLink.url}
          className="Links__footerLink">
          {t(FooterLink.text)}
        </Link>
      )}
    </div>
  );
};

export default Links;
