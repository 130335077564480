import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import { Title } from "../../atoms/Title/title";
import { Subtitle } from "../../atoms/Subtitle/subtitle";
import pageNotFound from "../../../images/logos/illust_404-passport.svg"; // Replace with your image path
import Button from "../../atoms/Button";
import Wrapper from "../../Layout/Wrapper/wrapper";

const ErrorFallback = () => {
  const { t } = useTranslation();

  const handleReload = (ev) => {
    ev.preventDefault();
    window.location.reload();
  };

  return (
    <Wrapper
      center
      compact>
      <img
        className="u-marginBottom"
        src={pageNotFound}
        alt="Something went wrong on the site."
      />
      <Title
        hero
        tag="h1"
        content={t("Something went wrong.")}
      />
      <Subtitle
        big
        content={
          <span>
            {t("Sorry, an error occurred while loading the site. Please try again or")}{" "}
            <Link to="/contact">{t("contact us")}</Link>.
          </span>
        }
      />
      <Button
        tertiary
        label={t("Try again")}
        onClick={handleReload}
      />
    </Wrapper>
  );
};

export default ErrorFallback;
