import React from "react";
import { ReactSVG } from "react-svg";
import usePrepareProps from "./hooks/usePrepareProps";

const Button = ({
  tag,
  buttonSubmit,
  className,
  link,
  rootLink,
  setTarget,
  buttonId,
  download,
  disabled,
  icon,
  svgSrc,
  iconPosition,
  label,
  template,
  ...props
}) => {
  const { classnames, Tag, buttonLink, buttonType } = usePrepareProps({
    ...props,
    tag,
    className,
    disabled,
    icon,
    svgSrc,
    link,
    rootLink,
    buttonSubmit,
    template,
  });

  return (
    <Tag
      to={buttonLink}
      state={{ source: props.source }}
      type={buttonType}
      target={setTarget}
      id={buttonId}
      download={download ? true : false}
      className={classnames}
      disabled={disabled}
      data-anl={props.dataAnl}
      data-qa={props.dataQa}
      data-testid={props.testId}
      onClick={typeof props.onClick === "function" ? (ev) => props.onClick(ev) : undefined}>
      <span className="button__text">{label}</span>

      {icon && (
        <img
          src={icon}
          className={`button__icon`}
          alt={`${label}-img-icon`}
        />
      )}
      {svgSrc && (
        <ReactSVG
          src={svgSrc}
          className={`button__icon`}
          title={`${label}-svg-icon`}
        />
      )}
    </Tag>
  );
};

export default Button;
