import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import comodo from "../../../images/payment/comodo_secure.png";
import ssl from "../../../images/payment/ssl.png";
import "./paymentCertificates.scss";

const PaymentCertificates = () => {
  const { t } = useTranslation();

  return (
    <div className={`paymentCertificates__wrapper`}>
      <div className={`paymentCertificates__header`}>{t("Our security, your safety")}</div>
      <div className={`paymentCertificates__item`}>
        <img
          className={`paymentCertificates__item-logo`}
          src={comodo}
          alt="payment certificates"
        />
        <p className={`paymentCertificates__item-text`}>
          {t("Your information is defended via secure data protection to keep it private and confidential.")}
        </p>
      </div>
      <div className={`paymentCertificates__item`}>
        <img
          className={`paymentCertificates__item-logo`}
          src={ssl}
          alt="payment certificates"
        />
        <p className={`paymentCertificates__item-text`}>
          {t("This site is secured by SSL. Your credit card information is fully protected by an encryption system.")}
        </p>
      </div>
    </div>
  );
}

export default PaymentCertificates;