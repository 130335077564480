import React, { createContext } from "react";
import useForm from "../hooks/useForm";

const FormContext = createContext({});

export const FormContextProvider = ({ form, structure, children }) => {
  const formData = useForm({ form, structure });

  return <FormContext.Provider value={formData}>{children}</FormContext.Provider>;
};

export default FormContext;
