const renew = {
  id: "6bc785f3-7b63-506f-aa47-45191ac91a44",
  show: true,
  name: "DS-82",
  headerTitle: "Passport Renewal DS-82",
  formButtonText: "Renew your Passport Now",
  formButtonTextb: "Start Form DS-82",
  metaDescription:
    "Form DS-82 is vital if your passport is expiring and needs replacing. Complete a passport renewal form safely and securely online now before you travel.",
  slug: "/passport-renewal",
  governmentPdf: {
    file: {
      url: "//assets.ctfassets.net/otm3aqjb1k5c/7EUZ0bvA7hSahZymNDXuwF/2bccffeb5156cac2a1dbe66c6313096a/ds82_pdf.pdf",
    },
  },
  formImage: {
    id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
    fluid: {
      src: "/images/form-landing-DS-82.png",
    },
  },
  type: false,
  description: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "A DS-82 form is <b>essential to renew your passport. </b>Apply now if your document will expire soon or has already expired using our secure online tools to complete your new passport application in minutes!",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  requeriments: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "DS-82 Requirements:",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "Form DS-82 can be completed by anyone who already has a passport that is about to expire or has expired within the last 5 years. This includes <b>the following situations </b>where your passport:",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Is undamaged and in your possession.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Was issued to you when you were 16 or older.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Has few or no blank pages left.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Expires in the next 6 months",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Expired within the last 5 years.",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Was issued 1 or more years ago and your name has legally changed",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationOne: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "When to Renew your Passport (Form DS-82)",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Form DS-82 can only be used if you already have a passport that is about to or has recently expired. You will need <b>a different kind of form if: </b>",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "A passport is your <b>key to international travel. </b>Whether you are planning a vacation or an important journey abroad for business it is essential that you have the right documentation for your trip before you depart.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "If your current passport is <b>close to expiry or has recently expired, </b>you will need to fill in Form DS-82.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  informationTwo: {
    raw: {
      nodeType: "document",
      data: {},
      content: [
        {
          nodeType: "heading-2",
          content: [
            {
              nodeType: "text",
              value: "Need an Alternative Application Form?",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value: "Form DS-82 can only be used if you already have a passport that is about to or has recently expired. You will need ",
              marks: [],
              data: {},
            },
            {
              nodeType: "text",
              value: "a different kind of form if: ",
              marks: [
                {
                  type: "bold",
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "unordered-list",
          content: [
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You’re applying for your <b>first passport</b>",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "You’re applying on the behalf of a person <b>under 16 years old</b>",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "It’s been <b>5 years</b> since your last passport expired",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "list-item",
              content: [
                {
                  nodeType: "paragraph",
                  content: [
                    {
                      nodeType: "text",
                      value: "Your current passport has been <b>lost or stolen</b>",
                      marks: [],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: "paragraph",
          content: [
            {
              nodeType: "text",
              value:
                "You can also <b>find and fill in these documents </b>here on our website. Click below to view the different kinds of passport forms available.",
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
  },
  order: 2,
  orderByTemplate: {
    default: 2,
    template_1: 1,
  },
};

export default renew;

export const ourBenefitsData = {
  benefitTitle: "Our Benefits",
  benefitSubtitle: {
    raw: {
      content: [
        {
          content: [
            {
              data: {},
              marks: [],
              nodeType: "text",
              value:
                "Receive a neatly prepared passport form and personalized filling instructions by using our online form and filling software",
            },
          ],
          data: {},
          nodeType: "paragraph",
        },
      ],
      data: {},
      nodeType: "document",
    },
  },
  benefit1: "Simple, intuitive application form",
  benefit2: "Comprehensive error checking",
  benefit3: "Easy step-by-step instructions",
  benefit4: "24/7 customer support",
};

export const stepsData = {
  edges: [
    {
      node: {
        id: "13de9fd1-8c3c-5f84-8410-afe95ccf68db",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "01",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Fill in the Form",
                    marks: [
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `Follow our instructions and expertly enter your data on your chosen passport form.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 1,
      },
    },
    {
      node: {
        id: "0eaa22da-408c-5cdc-921e-f5c2f683607a",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "02",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Print and send",
                    marks: [
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value: `After completion, simply print the file and submit it to your local passport office.`,
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 2,
      },
    },
    {
      node: {
        id: "738902d5-db5a-5d8c-a61f-c7d2ab35dcc5",
        stepSvg: {
          raw: {
            nodeType: "document",
            data: {},
            content: [
              {
                nodeType: "heading-2",
                content: [
                  {
                    nodeType: "text",
                    value: "03",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: "text",
                    value: "Get Your Passport",
                    marks: [
                      {
                        type: "italic",
                      },
                    ],
                    data: {},
                  },
                ],
                data: {},
              },
              {
                nodeType: "paragraph",
                content: [
                  {
                    nodeType: "text",
                    value:
                      "When your new passport is issued it will be mailed to your home address.",
                    marks: [],
                    data: {},
                  },
                ],
                data: {},
              },
            ],
          },
        },
        order: 3,
      },
    },
  ],
};
