const get = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

/**
 * This function register events in GTM, for more information https://developers.google.com/tag-manager/devguide?hl=es
 * @param {String} event name of the event that you want register in GTM
 * @param {Object} data
 * @param {Object} data.ecommerce
 *
 */

const push = (event, { ecommerce } = {}) => {
  let dataLayer = get();

  let body = {
    event,
  };

  if (typeof ecommerce === "object" && Object.keys(ecommerce).length) {
    body.ecommerce = ecommerce;
  }

  dataLayer.push(body);
};

const dataLayer = {
  get,
  push,
};

export default dataLayer;
