import React from "react";
import PropTypes from "prop-types";
import ErrorFallback from "./ErrorFallback"; // Import the fallback UI component

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state to render fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error details to an error reporting service
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback />; // Render fallback UI
    }

    return this.props.children; // Render children if no error
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired, // Accept any renderable React children
};

export default ErrorBoundary;
