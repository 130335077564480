import { useState, useMemo, useCallback } from "react";
import { formatToUSDate, formatToShortDate } from "../components/utils/dates";
import { getUsedFields } from "../components/utils/form";
import {
  loadMarriedField,
  loadPassportCardStatus,
  loadPassportStatusField,
} from "../components/utils/formDataFromBD";
import { setFormDataInStorage } from "../components/utils/formDataInStorage";
import { validationStringLength, fieldValidator } from "../components/utils/formValidation";
import useMediaQuery from "./useMediaQuery";

const FIELD_ACTIONS_WHEN_CHANGING = {
  passportStatus: loadPassportStatusField,
  passportCardStatus: loadPassportCardStatus,
  married: loadMarriedField,
  dateTrip: ({ value, form }) => {
    if (value) {
      form.dateReturn.minElegibleDate = value;
    } else {
      form.dateReturn.minElegibleDate = "today";
    }
    return form;
  },
  dateReturn: ({ value, form }) => {
    form.dateTrip.maxElegibleDate = value;
    return form;
  },
};

const loadContactForm = (emergencyContactFileds, form) => {
  const newForm = { ...form };
  let makeFieldsRequired = emergencyContactFileds.some(
    (fieldKey) => newForm[fieldKey].value !== null && newForm[fieldKey].value !== ""
  );

  emergencyContactFileds.forEach((filedKey) => {
    if (filedKey !== "emergencyAddress2" && newForm[filedKey].required !== makeFieldsRequired) {
      newForm[filedKey].validate = true;
      newForm[filedKey].required = makeFieldsRequired;
    }
  });

  return newForm;
};

const useForm = ({ form: lastForm, structure }) => {
  const [form, setForm] = useState(lastForm);
  const isMobile = useMediaQuery("mobile");

  const fieldsAction = useMemo(() => {
    let fieldsAction = { ...FIELD_ACTIONS_WHEN_CHANGING };

    const emergencyContactStep = structure.steps.find((step) => step.name === "emergency-contact");
    const emergencyContactFileds = emergencyContactStep.allFields;
    emergencyContactStep.allFields.forEach((key) => {
      fieldsAction[key] = ({ form }) => loadContactForm(emergencyContactFileds, form);
    });

    return fieldsAction;
  }, [structure]);

  const getFieldValue = (type, value, checked) => {
    switch (type) {
      case "Date":
        if (isMobile) {
          return value ? formatToShortDate(value) : "";
        } else {
          return value ? formatToUSDate(value) : "";
        }
      case "Check":
        return checked ? "1" : "0";
      default:
        return value;
    }
  };

  const formHasValueDependences = useMemo(() => {
    const hasFieldsInFormValueDependences =
      Object.keys(form).filter((field) => form[field].hasOwnProperty("defaultValueAs")).length > 0;
    return hasFieldsInFormValueDependences;
  }, [form]);

  const setValuesDependences = useCallback(
    (stepFieldsName) => {
      let stepFormFields = {};
      stepFieldsName.forEach((stepField) => {
        stepFormFields = {
          ...stepFormFields,
          ...Object.fromEntries(Object.entries(form).filter(([key]) => stepField.includes(key))),
        };
      });

      const stepFieldsHasDefaultValues =
        Object.keys(stepFormFields).filter((field) =>
          stepFormFields[field].hasOwnProperty("defaultValueAs")
        ).length > 0;

      if (stepFieldsHasDefaultValues) {
        let tempForm = { ...form };

        for (const field in tempForm) {
          if (tempForm[field].hasOwnProperty("defaultValueAs")) {
            const fieldName = tempForm[field].name;
            const fieldValueReference = tempForm[field].defaultValueAs;
            const value = tempForm[fieldValueReference].value;

            tempForm = buildFormOnChange(fieldName, value, {
              ...tempForm,
              [fieldName]: {
                ...tempForm[fieldName],
                value: value,
              },
            });
          }
        }

        setFormDataInStorage(tempForm);
        setForm(tempForm);
        return tempForm;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const buildFormOnChange = useCallback(
    (fieldName, value, form) => {
      let newForm = { ...form };
      const fieldAction = fieldsAction[fieldName];

      if (typeof fieldAction === "function") {
        newForm = {
          ...newForm,
          ...fieldAction({ value, form }),
        };
      }

      const FIELD_TYPE_TO_VERIFY_WHEN_CHANGED = ["date", "select", "radio"];
      if (FIELD_TYPE_TO_VERIFY_WHEN_CHANGED.includes(newForm[fieldName].type)) {
        newForm[fieldName] = validationStringLength({ field: newForm[fieldName] }).field;
      }

      return newForm;
    },
    [fieldsAction]
  );

  const onChangeField = useCallback(
    (event) => {
      const target = event.target;
      const value = target.value;

      let tempForm = buildFormOnChange(target.name, value, {
        ...form,
        [target.name]: {
          ...form[target.name],
          value: getFieldValue(form[target.name].component, value, target.checked),
        },
      });

      setFormDataInStorage(tempForm);
      setForm(tempForm);

      return tempForm;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const addItemForm = useCallback(
    (itemName, itemValue) => {
      let tempForm = buildFormOnChange(itemName, itemValue, {
        ...form,
        [itemName]: {
          ...itemValue,
        },
      });

      setFormDataInStorage(tempForm);
      setForm(tempForm);

      return tempForm;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const handleFieldValidator = useCallback(
    (fieldName, formToValidate) => {
      const { isValid, form: formValidated } = fieldValidator(fieldName, formToValidate || form);

      setForm(formValidated);
      return isValid;
    },
    [form]
  );

  const formValidator = useCallback(
    (stepIndex) => {
      let isValid = true;
      let invalidField = null;
      let invalidForm = { ...form };

      const step = structure.steps[stepIndex];

      if (typeof step !== "undefined") {
        const fields = getUsedFields(step, invalidForm);

        fields.forEach((fieldName) => {
          const disabledFields = ["pdfId", "line", "addAdditionalPhoneNumber"];

          if (!disabledFields.includes(fieldName)) {
            const field = form[fieldName];
            const fildValidated = fieldValidator(field.name, invalidForm);
            invalidForm = { ...fildValidated.form };

            if (isValid && !fildValidated.isValid) {
              isValid = fildValidated.isValid;
              invalidField = field;
            }
          }
        });
      }

      if (!isValid) {
        setForm(invalidForm);
      }

      return { isValid, invalidField };
    },
    [form, structure]
  );

  return {
    form,
    onChangeField,
    formHasValueDependences,
    setValuesDependences,
    fieldValidator: handleFieldValidator,
    formValidator,
    addItemForm,
  };
};

export default useForm;
