import React from "react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Layout } from "../components/Layout/layout";
import FormSteps from "../components/organisms/FormSteps";
import Loading from "../components/atoms/Loading";
import usePrepareFormData from "../hooks/usePrepareFormData";
import ApplicationContext from "../context/ApplicationContext";
import { FormContextProvider } from "../context/FormContext";
import { getLanguageIndex } from "../components/utils";

const FormTemplate = ({ pageContext, location }) => {
  const { loading, ...applicationData } = usePrepareFormData(pageContext.json);
  const source = location?.state?.source;
  const { language } = useI18next();
  const languageSimple = getLanguageIndex(language);

  return (
    <Layout
      headerTitle={
        pageContext.title[languageSimple] ? pageContext.title[languageSimple] : undefined
      }
      hideMenuButton={false}
      headerBigMaxWidth
      footerBigMaxWidth
      disclaimerBigMaxWidth>
      <ApplicationContext.Provider value={applicationData}>
        {loading ? (
          <Loading full />
        ) : (
          <FormContextProvider
            form={applicationData.form}
            structure={applicationData.structure}>
            <FormSteps
              form={applicationData.form}
              structure={applicationData.structure}
              formDetails={applicationData.formDetails}
              orderCookie={applicationData.orderCookie}
              stepNames={applicationData.stepNames}
              formName={applicationData.formName}
              formType={pageContext.name.toLowerCase()}
              source={source}
              isFormToEdit={applicationData.isFormToEdit}
            />
          </FormContextProvider>
        )}
      </ApplicationContext.Provider>
    </Layout>
  );
};

export default FormTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
